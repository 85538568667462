import { cva, VariantProps } from 'class-variance-authority';
import { useFormikContext } from 'formik';
import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type SubmitButtonProps = {
  children?: React.ReactNode;
  className?: string;
  enabled?: boolean;
  withFormikContext?: boolean;
  alt?: boolean;
  loading?: boolean;
};

export type ButtonProps = VariantProps<typeof buttonStyles> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const buttonStyles = cva(
  [
    'disabled:opacity-50',
    'transition-colors',
    'duration-200',
    'hover:duration-500',
  ],
  {
    variants: {
      intent: {
        primary: ['bg-brand', 'text-white', 'hover:bg-brand-dark'],
        secondary: ['border-brand', 'text-brand', 'border', 'bg-white'],
        delete: ['bg-delete', 'text-white'],
        text: ['text-brand hover:underline'],
      },
      size: {
        block: [
          'mb-5',
          'w-full',
          'p-4',
          'rounded-lg',
          'font-semibold',
          'last:mb-0',
        ],
        small: ['py-2', 'text-sm', 'font-medium'],
      },
      round: {
        true: ['rounded-full', 'px-8 py-2', 'font-normal', 'text-sm', 'shadow'],
        false: ['uppercase'],
      },
      loading: {
        true: [],
      },
    },
    compoundVariants: [
      {
        intent: 'primary',
        size: 'block',
        className: ['shadow-[0px_4px_5px_rgba(0,0,0,0.16)]'],
      },
      {
        intent: 'text',
        size: 'small',
        round: false,
        className: ['normal-case', 'px-0'],
      },
      {
        intent: 'primary',
        size: 'small',
        round: false,
        className: ['px-5'],
      },
      { size: 'small', round: false, className: ['rounded-md'] },
    ],
    defaultVariants: {
      intent: 'primary',
      size: 'block',
    },
  }
);

export const Button = ({
  intent,
  size,
  round = false,
  disabled,
  loading,
  children,
  className,
  ...props
}: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      {...props}
      className={buttonStyles({
        className,
        intent,
        size,
        round,
      })}
      // style={alt ? undefined : { boxShadow: '0px 4px 5px #00000029' }}
      disabled={Boolean(disabled || loading)}
    >
      {loading ? t('loading') : children ?? t('submit')}
    </button>
  );
};

const WithFormikContext = ({
  children,
  alt,
  className,
  enabled = true,
  loading = false,
}: SubmitButtonProps) => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <Button
      type='button'
      intent={alt ? 'secondary' : 'primary'}
      className={className}
      onClick={submitForm}
      disabled={!enabled || isSubmitting}
      children={children}
      loading={loading}
    />
  );
};

export const SubmitButton = ({
  children,
  className,
  alt,
  enabled = true,
  withFormikContext = true,
  loading = false,
}: SubmitButtonProps) => {
  if (withFormikContext) {
    return (
      <WithFormikContext
        enabled={enabled}
        children={children}
        loading={loading}
      />
    );
  }

  return (
    <Button
      intent={alt ? 'secondary' : 'primary'}
      className={className}
      type='submit'
      disabled={!enabled}
      children={children}
      loading={loading}
    />
  );
};
