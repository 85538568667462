import { faCheck, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Loading } from '~/components/ui/Loading';
import { Tooltip } from '~/components/ui/Tooltip';
import { JobStatus } from '~/generated/graphql';
import { parseJson } from '~/helpers';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import AssetsIcon from '~/layouts/nav/icons/Assets';
import AttributesIcon from '~/layouts/nav/icons/Attributes';
import PurchasesIcon from '~/layouts/nav/icons/Purchases';
import RestockIcon from '~/layouts/nav/icons/Restock';
import StandardIcon from '~/layouts/nav/icons/Standard';
import StockTransferIcon from '~/layouts/nav/icons/StockTransfer';
import StocktakeIcon from '~/layouts/nav/icons/Stocktake';
import { Job, Task } from '..';
import styles from '../JobView.module.css';

export type TaskType = 'Restock' | 'Stocktake' | 'Asset' | 'Standard';

export type ToggleTaskStatusFn = (args: {
  id: string;
  checked: boolean;
}) => void;

const { Complete, Incomplete } = JobStatus;

function getLinkText(job: Job | undefined, task: Task) {
  return task.type === 'Custom'
    ? task.name
    : task.name
    ? task.name
    : task.type === 'Item' || task.type === 'MoveStock'
    ? job?.name.startsWith('Supply Stock')
      ? 'Pick Stock'
      : 'Add Stock'
    : 'Stocktake';
}

// Check for when the RTF was opened but no description was entered
function hasContent(content: string) {
  const parsedContent = parseJson(content);
  const blocks = parsedContent?.blocks;
  if (Array.isArray(blocks)) {
    if (blocks.length > 1) return true;
    if (Boolean(blocks[0].text)) return true;
  }
  return false;
}

const cx = classNames.bind(styles);

type TaskListItemProps = {
  job: Job;
  task: Task;
  onToggle: ToggleTaskStatusFn;
  isDisabled?: boolean;
  taskToggleLoading: string;
};

export const TaskListItem = ({
  job,
  task,
  onToggle,
  isDisabled = false,
  taskToggleLoading,
}: TaskListItemProps) => {
  const { isMobile } = useBreakpoint();
  const required = task.required;

  if (isDisabled) {
    return (
      <div className='mb-[9px] flex items-center gap-[9px]'>
        <TaskCheckbox
          task={task}
          jobStatus={job.status}
          onToggle={onToggle}
          disabled
          taskToggleLoading={taskToggleLoading}
        />
        <li
          className={cx(
            'flex grow items-center rounded border border-grey-5 text-grey-50 transition-all'
          )}
        >
          <span className='px-3 py-2.5'>{getLinkText(job, task)}</span>
        </li>
      </div>
    );
  }

  return (
    <div className='mb-[9px] flex w-full items-center  gap-[9px]'>
      <TaskCheckbox
        task={task}
        jobStatus={job.status}
        onToggle={onToggle}
        taskToggleLoading={taskToggleLoading}
      />
      <li
        className={cx(
          'flex-1 rounded border  text-grey-50 shadow transition-all hover:shadow-md',
          `${required ? 'border-brand' : 'border-grey-10'}`
        )}
      >
        <Link
          to={
            task.type === 'Attribute' && isMobile
              ? `/jobs/${job.id}/tasks/${task.id}/attribute-audit`
              : `tasks/${task.id}`
          }
          className='relative flex w-full items-center justify-between px-2.5 py-[9.5px]'
        >
          {getLinkText(job, task)}
          <div className='ml-auto flex w-5 items-center justify-center text-grey-40'>
            <TaskIcon taskType={task.type} taskDescription={task.description} />
          </div>

          {required ? (
            <div className='absolute -right-2 -top-2.5 flex h-4 w-4 items-center justify-center rounded-full bg-brand'>
              <span className='text-[9px] font-bold text-white'>M</span>
            </div>
          ) : null}
        </Link>
      </li>
    </div>
  );
};

type TaskIconProps = {
  taskType: string;
  taskDescription?: string;
};

export function TaskIcon({ taskType, taskDescription }: TaskIconProps) {
  // display different icon on RHS of clickable task card depending on task type
  switch (taskType) {
    // case 'Standard':
    //   if (
    //     !taskDescription ||
    //     taskDescription === 'null' ||
    //     !hasContent(taskDescription)
    //   ) {
    //     return null;
    //   }
    case 'Standard':
      return <StandardIcon className='h-[25px] w-[25px]' />;
    case 'Attribute':
      return <AttributesIcon className='h-[25px] w-[25px]' />;
    case 'Purchase':
      return <PurchasesIcon className='h-[25px] w-[25px]' />;
    case 'Stocktake':
      return <StocktakeIcon className='h-[25px] w-[25px]' />;
    case 'Restock':
      return <RestockIcon className='h-[25px] w-[25px]' />;
    case 'Asset':
      return <AssetsIcon className='h-[25px] w-[25px]' />;
    case 'Transfer Stock':
      return <StockTransferIcon className='h-[25px] w-[25px]' />;
    default:
      return <StandardIcon className='h-[25px] w-[25px]' />;
  }
}

type TaskCheckboxProps = {
  task: Task;
  jobStatus: JobStatus;
  onToggle: ToggleTaskStatusFn;
  disabled?: boolean;
  taskToggleLoading: string;
};

/**
 * Display a clickable checkbox for easy completion of tasks with no content, and a disabled checkbox with status of task (incomplete, completeted and defered) for all other tasks
 */
function TaskCheckbox({
  task,
  jobStatus,
  onToggle,
  taskToggleLoading,
  ...props
}: TaskCheckboxProps) {
  const navigate = useNavigate();
  const { status } = task;
  const isToggleLoading = taskToggleLoading === task.id;

  const disabled = props.disabled || task.type !== 'Standard';
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  function handleClick() {
    if (jobStatus === JobStatus.Complete || isToggleLoading) return;

    if (disabled) {
      navigate(`tasks/${task.id}`);
      return;
    }

    onToggle({
      id: task.id,
      checked: task.status !== Complete && task.status !== Incomplete,
    });
  }

  // Only display Checkbox when job is in progress or completed
  if (![JobStatus.Complete, JobStatus.InProgress].includes(jobStatus)) {
    return null;
  }

  return (
    <div>
      <Tooltip
        isVisible={
          task.status !== JobStatus.Complete && isTooltipVisible && disabled
        }
        position='-top-7 -right-64'
      >
        You need to open this task to complete it
      </Tooltip>

      <div
        className={classNames(
          'relative flex h-[26px] w-[26px] items-center justify-center rounded border border-grey-30 text-white',
          { 'border-success bg-success': status === JobStatus.Complete },
          {
            'border-orangeAlert bg-orangeAlert':
              status === JobStatus.Incomplete,
          },
          {
            'border-red-600 bg-red-600': status === JobStatus.Cancelled,
          },
          {
            'border-opacity-20 bg-opacity-60':
              jobStatus === JobStatus.InProgress && disabled,
          },
          {
            'border-none bg-transparent': isToggleLoading,
          }
        )}
        onClick={handleClick}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        {/* Display user notes notification only when the task hasn't been reset.  */}
        {task.notes != null &&
          task.status !== JobStatus.Created &&
          !isToggleLoading && (
            <div className='absolute -right-1.5 -top-1.5 h-3 w-3 rounded-full bg-blue'></div>
          )}
        {isToggleLoading ? (
          <Loading spinner />
        ) : (
          <CheckboxIcon status={task.status} />
        )}
      </div>
    </div>
  );
}

function CheckboxIcon(props: { status: JobStatus }) {
  switch (props.status) {
    case JobStatus.Complete:
      return <FontAwesomeIcon icon={faCheck} />;
    case JobStatus.Incomplete || JobStatus.Cancelled:
      return <FontAwesomeIcon icon={faExclamation} />;
    default:
      return null;
  }
}
