import { Dialog as BaseDialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  show: boolean;
  title: string;
  onClose: () => void;
  danger?: boolean;
  isLoading?: boolean;
};

export const Dialog = ({
  children,
  show,
  title,
  onClose,
  danger,
  isLoading = false,
}: Props) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <BaseDialog
        as='div'
        className='fixed inset-0 z-[100] overflow-hidden'
        // Uncomment below to make clicking on the background close the modal
        // onClose={onClose}
        onClose={() => {}}
      >
        <div className='flex items-center justify-center px-1 text-center h-screen'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <BaseDialog.Overlay className='fixed inset-0 bg-mask' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block align-middle h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              className='my-8 inline-block max-h-[calc(100vh-4rem)] w-[21rem] transform overflow-hidden rounded-lg bg-white transition-all'
              style={{ boxShadow: '0px 3px 6px #00000029' }}
            >
              <BaseDialog.Title
                as='h3'
                className={`${
                  danger ? 'bg-delete' : 'bg-brand'
                } rounded-t-lg px-5 py-3 text-center text-lg font-semibold text-white`}
              >
                <button
                  className={classNames(
                    'x absolute ml-5 mt-3 font-medium transition-all duration-300 hover:opacity-80',
                    {
                      'cursor-default disabled:opacity-50': isLoading,
                    }
                  )}
                  onClick={onClose}
                  disabled={isLoading}
                ></button>
                {/* <span
                  className='x absolute mt-3 ml-5 font-medium transition-all duration-300 hover:opacity-80'
                  onClick={onClose}
                /> */}
                {title}
              </BaseDialog.Title>
              {children}
              {/* <div className='m-8'>
                <p className=' text-copy-alt text-center text-sm font-normal'>
                  Would you like the assignee to be notified of this job via
                  email?
                </p>
              </div>
              <div className='m-4 text-right'>
                <button
                  className='text-future-blue text-xs uppercase'
                  onClick={onClose}
                >
                  Save Only
                </button>
                <button
                  className='bg-brand ml-8 rounded-md p-2 text-xs uppercase text-white shadow'
                  onClick={onClose}
                >
                  Save &amp; Send
                </button>
              </div> */}
            </div>
          </Transition.Child>
        </div>
      </BaseDialog>
    </Transition>
  );
};
