import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { getFileAsObjectUrl, openDb } from '~/components/upload/db';
import { ContentBlock, ContentState, EditorBlock, EditorState } from 'draft-js';
import { Map } from 'immutable';
import { useEffect, useState } from 'react';

type Props<BlockProps = undefined> = {
  block: ContentBlock;
  blockProps: BlockProps;
  contentState: ContentState;
};

export function createBlockRenderer(
  editorState: EditorState,
  onChange: (editorState: EditorState) => void,
  readOnly?: boolean
) {
  return function customBlockRenderer(contentBlock: ContentBlock) {
    const type = contentBlock.getType();

    if (type === 'atomic') {
      return {
        component: Media,
        editable: false,
      };
    }

    if (type === 'checklist-item') {
      return {
        component: ChecklistItem,
        props: { editorState, onChange, readOnly },
      };
    }

    return null;
  };
}

type ChecklistProps = Props<{
  editorState: EditorState;
  onChange: (editorState: EditorState) => void;
  readOnly?: boolean;
}>;

function ChecklistItem(props: ChecklistProps) {
  const { block, contentState } = props;
  const { editorState, onChange, readOnly } = props.blockProps;
  const checked = props.block.getData().get('checked') === true;
  // Is the "readOnly" input checkbox checked?
  const [inputChecked, setInputChecked] = useState(checked);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const blockKey = block.getKey();
    const newBlock = contentState
      .getBlockForKey(blockKey)
      .set('data', Map({ checked: !checked }));
    const newBlockMap = contentState
      .getBlockMap()
      // @ts-expect-error FIXME
      .merge({ [blockKey]: newBlock });

    const newEditorState = EditorState.push(
      editorState,
      // @ts-expect-error FIXME
      contentState.merge({ blockMap: newBlockMap }),
      // See https://draftjs.org/docs/api-reference-editor-change-type/
      'change-block-data'
    );

    onChange(newEditorState);
  }

  return (
    <div className='flex items-start'>
      <div className='pr-2'>
        {readOnly ? (
          <input
            className='js-custom-task-checklist-item'
            type='checkbox'
            name={block.getKey()}
            onChange={(e) => setInputChecked(e.target.checked)}
            defaultChecked={checked}
          />
        ) : (
          <button
            className={classNames('h-4 w-4 border border-slate-400 text-xs', {
              'bg-slate-100': checked,
            })}
            type='button'
            onClick={handleClick}
          >
            {checked ? <FontAwesomeIcon icon={faCheck} /> : ' '}
          </button>
        )}
      </div>

      <div className={checked || inputChecked ? '' : ''}>
        <EditorBlock {...props} />
      </div>
    </div>
  );
}

function Media(props: Props) {
  const { block, contentState } = props;
  const type = contentState.getEntity(block.getEntityAt(0)).getType();
  const data = contentState.getEntity(block.getEntityAt(0)).getData();
  const [src, setSrc] = useState('');

  useEffect(() => {
    openDb()
      .then((db) => getFileAsObjectUrl(db, data.id))
      .then((src) => {
        setSrc(src);
      })
      .catch(() => {
        setSrc(data.baseUrl + data.id);
      });
    // eslint-disable-next-line
  }, []);

  if (type !== 'IMAGE' || !src) return null;

  return (
    <figure>
      <img src={src} alt='uploaded file' />
    </figure>
  );
}
