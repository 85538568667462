import { format, parse, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { enAU } from 'date-fns/locale';

export const LOCALIZED_FORMAT = 'PP p';
export const LONG_FORMAT = 'dd/MM/yyyy hh:mm a';

export function formatDate(
  date: string | null | undefined,
  pattern = LOCALIZED_FORMAT
) {
  if (date) {
    return format(parseISO(date), pattern, { locale: enAU });
  }
  return '';
}

export function formatDateInTimeZone(
  date: string | null | undefined,
  timeZone: string,
  pattern = LOCALIZED_FORMAT
) {
  if (!date) {
    return '';
  }
  return formatInTimeZone(new Date(date), timeZone, pattern, { locale: enAU });
}

/**
 * Helper uses the localized date format but will attempt to remove the year if
 * it is the current year. Useful for cases where space is at a premium
 */
export function localizedShortDate(date: string) {
  return formatDate(date).replace(`, ${format(new Date(), 'y')}`, '');
}

export function formatTime(time: string | null | undefined) {
  if (time) {
    return format(parse(time, 'HH:mm', new Date()), 'h:mm a');
  }
  return '';
}
