import classNames from 'classnames';
import { useField } from 'formik';
import { forwardRef, HTMLProps, ReactNode, useMemo } from 'react';
import { useBreakpoint } from '~/hooks/useBreakpoint';

const IDENTITY = { nextVal: 1 };

export type Props = {
  label: string;
  flow?: 'condensed';
  align?: 'right';
  error?: string | false;
  required?: boolean;
  placeholder?: string;
  filter?: boolean;
  filtered?: boolean;
  /**
   * Select the input text/value when input is focused
   */
  selectOnFocus?: boolean;
  icon?: ReactNode;
} & HTMLProps<HTMLInputElement>;
type Ref = HTMLInputElement;

export const Input = forwardRef<Ref, Props>(
  (
    {
      label,
      align,
      error,
      placeholder,
      selectOnFocus = false,
      icon,
      filter,
      filtered,
      type = 'text',
      ...props
    },
    ref
  ) => {
    const uniqueId = useMemo(
      () => props.id ?? `Input-${IDENTITY.nextVal++}`,
      [props.id]
    );
    const { isMobile } = useBreakpoint();

    return (
      <div
        className={classNames(
          { 'mb-5 flex flex-col gap-1': props.flow !== 'condensed' && !filter },
          'relative',
          { error: !!error },
          props.className
        )}
        {...props}
      >
        {label && (
          <label
            className={classNames(
              { 'block pr-3': props.flow === 'condensed' },
              filter ? 'invisible absolute' : 'ml-1 text-sm',
              'font-medium text-grey-50',
              'overflow-hidden whitespace-nowrap'
            )}
            htmlFor={uniqueId}
          >
            {label}
            {props.required && <span className='pl-0.5 text-brand'>*</span>}
          </label>
        )}
        <div className='relative flex flex-1'>
          <input
            ref={ref}
            type={type}
            {...props}
            id={uniqueId}
            placeholder={placeholder ?? ''}
            style={{ fontSize: 16 }}
            className={classNames(
              filter
                ? 'w-full cursor-pointer whitespace-nowrap rounded-full border-grey-10 py-[3px] text-sm leading-tight placeholder:text-center placeholder:text-grey-50 lg:py-1 lg:text-base'
                : error
                ? 'mt-0.5 w-full rounded border-red-600 py-2.5 focus-visible:border-red-600'
                : 'mt-0.5 w-full rounded border-grey-20 py-2.5 focus-visible:border-grey-40',
              filtered &&
                'border-grey-20 bg-grey-5 text-center font-semibold text-grey-50',
              'input border px-3 focus-visible:outline-none',
              { 'text-right': align === 'right' },
              { 'py-2': props.flow === 'condensed' },
              {
                'border-transparent bg-transparent py-0':
                  props.disabled && props.flow === 'condensed',
              },
              {
                'block w-full min-w-0 appearance-none whitespace-nowrap bg-white 2xl:min-w-[160px]':
                  type === 'date',
              },
              { 'flex-1': isMobile && type === 'date' },
              icon && 'pr-[45px]'
            )}
          />
          <div
            className='absolute right-2 top-0 h-full text-gray-400'
            aria-hidden='true'
          >
            <div className='flex h-full items-center'>{icon}</div>
          </div>
        </div>
        {error && (
          <div className='ErrorMessage'>
            {typeof error === 'string' ? error : JSON.stringify(error)}
          </div>
        )}
      </div>
    );
  }
);

type TextFieldProps = Props & {
  /** @todo figure out how to type this correctly, or have extend the forwardRef props instead of Props */
  ref?: any;
  name: string;
  label: string;
  align?: 'right';
};

export const TextField = ({ name = '', ...props }: TextFieldProps) => {
  const [field, meta] = useField<string>(name);

  return <Input {...field} {...props} error={meta.touched && meta.error} />;
};
