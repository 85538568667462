import { SVGProps } from 'react'

const SvgPlaceholderImage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22' {...props}>
    <g fillRule='evenodd'>
      <path d='M1 21v-3.383l5.991-6.847 4.929 5.915 4.033-3.025L21 18.707V21H1zM21 1v16.293l-4.953-4.953-3.967 2.975L7.009 9.23 1 16.189V1h20zm0-1H1a1 1 0 00-1 1v20a1 1 0 001 1h20a1 1 0 001-1V1a1 1 0 00-1-1z' />
      <path d='M15 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2m0 5a3 3 0 100-6 3 3 0 000 6' />
    </g>
  </svg>
)

export default SvgPlaceholderImage

