import { ContentState } from 'draft-js';

type LinkProps = {
  contentState: ContentState;
  entityKey: string;
  children: React.ReactNode;
};

export const Link = ({ contentState, entityKey, children }: LinkProps) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      className='text-future-blue underline'
      href={url}
      target='_blank'
      rel='noreferrer'
    >
      {children}
    </a>
  );
};

export const PastedLink = (props: { decoratedText: string }) => {
  return (
    <a
      className='text-future-blue underline'
      href={props.decoratedText}
      target='_blank'
      rel='noreferrer'
    >
      {props.decoratedText}
    </a>
  );
};
