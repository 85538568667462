import { useTranslation } from 'react-i18next';
import { JobStatus, Status } from '~/generated/graphql';

type Props = {
  prefix?: string;
  value: Status | JobStatus;
};

export const StatusBadge = ({ prefix, value }: Props) => {
  const { t } = useTranslation('job');

  return (
    <div className='mb-5 flex items-stretch justify-center'>
      <div
        className={`badge ${(() => {
          switch (value) {
            case Status.Active:
              return 'badge-active';
            case Status.Inactive:
              return 'badge-inactive';
            case JobStatus.Created:
              return 'badge-created';
            case JobStatus.Complete:
              return 'badge-complete';
            case JobStatus.Incomplete:
            case JobStatus.Paused:
              return 'badge-paused';
            case JobStatus.Offered:
              return 'badge-offered';
            case JobStatus.InProgress:
              return 'badge-inprogress';
            case JobStatus.Accepted:
              return 'badge-accepted';
            case JobStatus.Declined:
              return 'badge-declined';
            case JobStatus.Cancelled:
              return 'badge-cancelled';
          }
        })()} badge flex items-center gap-[6px] px-[13px] py-[2px] text-sm font-semibold leading-[20.4px] shadow transition-all md:font-semibold`}
      >
        {prefix && <p>{t(prefix)}</p>}
        <p>{t(value)}</p>
      </div>
    </div>
  );
};
