import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfirmActionDialog } from '../ConfirmActionDialog';

type ChangeRequestProps = {
  jobId: string;
  onSubmit: (args: { input: { jobId: string; body: string } }) => void;
};

export function RequestChange({ jobId, onSubmit }: ChangeRequestProps) {
  const { t } = useTranslation('job');
  const [isOpen, setIsOpen] = useState(false);

  async function handleChangeRequest(body?: string) {
    if (!body) return;
    await onSubmit({ input: { jobId, body } });
    setIsOpen(false);
    toast.success('Your change request has been sent !');
  }

  return (
    <>
      <ConfirmActionDialog
        title='Request Changes'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text='What would you like to change?'
        onClick={handleChangeRequest}
        confirmButton='Request Changes'
        placeholder='Add Message... *'
        required={true}
      />
      <button
        type='button'
        onClick={() => {
          setIsOpen(true);
        }}
        className='pb-1 font-medium text-brand underline'
      >
        {t('requestChange')}
      </button>
    </>
  );
}
