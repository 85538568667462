import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/form/SubmitButton';
import { ConfirmActionDialog } from '../ConfirmActionDialog';

type AcceptProps = {
  jobId: string;
  jobName: string;
  onAccept: (args: { id: string; comment?: string }) => void;
};

export function Accept({ jobId, jobName, onAccept }: AcceptProps) {
  const { t } = useTranslation('job');
  const [isOpen, setIsOpen] = useState(false);

  function handleAccept(comment?: string) {
    onAccept({ id: jobId, comment });
  }

  const text = (
    <>
      You are about to accept <span className='font-semibold'>{jobName}</span>
    </>
  );

  return (
    <>
      <ConfirmActionDialog
        title='Accept Job'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text={text}
        onClick={handleAccept}
        confirmButton='Accept'
      />
      <Button
        type='button'
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t('accept')}
      </Button>
    </>
  );
}
