import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  spinner?: boolean;
};

export function Loading({ spinner }: Props) {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => setMounted(true), []);

  if (spinner) {
    return (
      <svg
        className='stroke-brand fill-brand h-10 w-10 transition-opacity delay-200 duration-75'
        style={{ opacity: mounted ? 1 : 0 }}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <style>
          {
            '.spinner_7NYg{animation:spinner_0KQs 1.2s cubic-bezier(0.52,.6,.25,.99) infinite}@keyframes spinner_0KQs{0%{r:0;opacity:1}100%{r:11px;opacity:0}}'
          }
        </style>
        <circle className='spinner_7NYg' cx='12' cy='12' r='0' />
      </svg>
    );
  }

  return <div className='w-full pl-1'>{t('loading')}</div>;
}
