import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/form/SubmitButton';
import { ConfirmActionDialog } from '../ConfirmActionDialog';

type DeclineProps = {
  jobId: string;
  jobName: string;
  onDecline: (args: { id: string; comment?: string }) => void;
};

export function Decline({ jobId, jobName, onDecline }: DeclineProps) {
  const { t } = useTranslation('job');
  const [isOpen, setIsOpen] = useState(false);

  async function handleDecline(comment?: string) {
    await onDecline({ id: jobId, comment });
  }

  const text = (
    <>
      You are about to decline <span className='font-semibold'>{jobName}</span>
    </>
  );

  return (
    <>
      <ConfirmActionDialog
        title='Decline Job'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text={text}
        onClick={handleDecline}
        confirmButton='Decline'
      />
      <Button
        type='button'
        onClick={() => {
          setIsOpen(true);
        }}
        intent='secondary'
      >
        {t('decline')}
      </Button>
    </>
  );
}
