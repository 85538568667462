import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { JobStatus, Status } from '~/generated/graphql';

type Props = {
  prefix?: string;
  value: Status | JobStatus;
  rounded?: boolean;
  small?: boolean;
};

function badgeClass(value: string) {
  switch (value) {
    case Status.Active:
      return 'badge-active';
    case Status.Inactive:
    case JobStatus.Incomplete:
      return 'badge-inactive';
    case JobStatus.Created:
      return 'badge-created';
    case JobStatus.Complete:
      return 'badge-complete';
    case JobStatus.Paused:
      return 'badge-paused';
    case JobStatus.Offered:
      return 'badge-offered';
    case JobStatus.InProgress:
      return 'badge-inprogress';
    case JobStatus.Accepted:
      return 'badge-accepted';
    case JobStatus.Declined:
      return 'badge-declined';
    case JobStatus.Cancelled:
      return 'badge-cancelled';
  }
}

export const JobStatusBadge = ({ prefix, value, small }: Props) => {
  const { t } = useTranslation('job');
  return (
    <div className='flex items-stretch justify-center text-center text-xs '>
      <div
        className={classNames([
          badgeClass(value),
          'h-6 w-6 items-center justify-center rounded-full py-1 font-bold leading-none shadow transition-all',
          !small && 'md:hidden',
        ])}
      >
        {t(value).substring(0, 1)}
      </div>
      <div
        className={classNames([
          badgeClass(value),
          'hidden h-7 w-[110px] items-center justify-center rounded-2xl text-xs font-medium shadow transition-all md:text-sm md:font-semibold',
          !small && 'md:flex',
        ])}
      >
        {prefix && t(prefix) + ' '}
        {t(value)}
      </div>
    </div>
  );
};
