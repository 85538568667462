import LightGallery from 'lightgallery/react';
import { Fragment, memo } from 'react';

// import styles
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lightgallery.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

type Props = {
  className?: string;
  attachments: {
    id: string;
    kind: string;
    thumb: string;
    url: string;
    originalFilename: string;
  }[];
};

function canDownload(id: string) {
  return !id.includes('cloudfront');
}

/**
 * For compatibility with the old simple attachment format
 */
function extractImageId(id: string) {
  if (id.startsWith('https://imagedelivery.net/')) {
    const match = id.match(/^https:\/\/imagedelivery\.net\/[^/]+\/([^/]+)\//);
    return match && match[1] ? match[1] : id;
  }
  return id;
}

export function Gallery({ className, attachments }: Props) {
  // XXX Why is this still here? Something is probably broken!
  // FIXME
  // const uppy = useAppContext().uppy.get('image');
  // const withLocalPreviews = useMemo(() => {
  //   const localFiles = uppy.getFiles();
  //   return [...attachments].map((attachment) => {
  //     const localFile = localFiles.find(
  //       (file) =>
  //         file.meta.id &&
  //         typeof file.meta.id === 'string' &&
  //         attachment.id.includes(file.meta.id)
  //     );
  //     if (localFile) {
  //       return {
  //         ...attachment,
  //         thumb: localFile.preview,
  //         url: localFile.preview,
  //       };
  //     }
  //     return attachment;
  //   });
  // }, [attachments]);

  return (
    <div className={className || 'mb-2'}>
      <LightGallery
        elementClassNames='grid w-full grid-cols-2 gap-2 xl:grid-cols-4'
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        licenseKey={import.meta.env.VITE_LIGHTGALLERY_LICENSE_KEY}
      >
        {attachments.map((attachment) => (
          <Fragment key={attachment.id}>
            {attachment.kind === 'image' && (
              <a
                href={attachment.url}
                className='block overflow-hidden bg-grey-5 pt-[100%]'
                data-id={attachment.id}
                data-download-url={
                  canDownload(attachment.id)
                    ? `/api/download/image/${extractImageId(attachment.id)}`
                    : 'false'
                }
              >
                <img
                  className='-mt-[100%] w-full'
                  alt={attachment.originalFilename}
                  src={attachment.thumb}
                  onError={(event) => {
                    console.log('failed to display img', attachment.id);
                    event.currentTarget.closest('a')?.classList.add('hidden');
                  }}
                />
              </a>
            )}
          </Fragment>
        ))}
      </LightGallery>
    </div>
  );
}

export const MemoGallery = memo(Gallery);
