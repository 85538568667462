import { Option } from '~/types';

/** Create a single option */
export function toOption(value: string, label?: string) {
  return { value, label: label ?? value };
}

export { updateInitialValues } from './updateInitialValues';

export function mapLocationToUrl(location: { type: string; id: [string] }) {
  switch (location.type) {
    case 'Asset':
      return `assets/${location.id}`;
    case 'Fsp':
      return `fsps/${location.id}`;
    case 'Site':
      return `sites/${location.id}`;
    default:
      throw new Error('Invalid location');
  }
}

export function formatJobNumber(jobId: number | string) {
  const id = typeof jobId === 'string' ? jobId : jobId.toString();
  return `J${id.padStart(4, '0')}`;
}

export function stringEqual(a: string, b: string, caseInsensitive = true) {
  return caseInsensitive
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a.localeCompare(b) === 0;
}

export const getStringParams = (str: string) => {
  let queryString = str;
  let keyValPairs = [];
  const params: any = {};
  queryString = queryString.replace(/.*?\?/, '');

  if (queryString.length) {
    keyValPairs = queryString.split('&');
    for (const pairNum in keyValPairs) {
      const key = keyValPairs[pairNum].split('=')[0];
      if (!key.length) continue;
      if (typeof params[key] === 'undefined') params[key] = [];
      params[key] = keyValPairs[pairNum].split('=')[1];
    }
  }
  return params;
};

export const filterMultiProp = (array: any, queryStrings: any) => {
  return array.filter((item: any) => {
    for (const key in queryStrings) {
      if (item[key] === undefined || item[key] !== queryStrings[key])
        return false;
    }
    return true;
  });
};

export const mapValToLabel = (options: Option[]) => {
  return options.map((option: Option) => ({ ...option, value: option.label }));
};

export const getPreviousURL = (location: any) => {
  const pathName = location.pathname.split('/');
  return pathName.length < 6 ? '/' + pathName[1] + '/' + pathName[2] : '';
};

export const parseJson = (json: string | null | undefined) => {
  if (json == null) {
    return null;
  }
  try {
    return JSON.parse(json);
  } catch (err) {
    return null;
  }
};

// util
const _throw = (message?: string) => {
  throw new Error(message);
};
export const getOrThrow = <T>(_: T, message?: string) => _ ?? _throw(message);
