import i18n from 'i18next';

import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const DEBUG = import.meta.env.VITE_I18NEXT_DEBUG === 'true';

// Uncomment this to add language selection based on the current subdomain
// const lng = (() => {
//   const match = window.location.hostname.match(
//     /^([a-z0-9]+)\.(gfactor\.com)$/i
//   );
//   return match && match[1] === 'noosa' ? 'en-x-property' : 'en';
// })();

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: DEBUG,
    lng: 'en-x-property',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
