type Attribute = {
  name: string;
  value: string[];
};

type Grouped<T extends { name: string }> = Record<
  string,
  (T & {
    /** Original task name, if grouped. Otherwise this is not set */
    _name?: string;
  })[]
>;

export function groupCategories(attributes: Attribute[]) {
  const grouped = attributes.reduce(
    (acc: Record<string, { name: string; value: string[] }[]>, attribute) => {
      const { name, value } = attribute;
      const i = name.indexOf('\\');

      // TODO filter before grouping to decouple from attributes
      if (value.length === 0) {
        return acc;
      }

      if (i > -1) {
        const group = name.substring(0, i).trim();
        acc[group] = [
          ...(acc[group] ?? []),
          { name: name.substring(i + 1).trim(), value },
        ];
        return acc;
      }

      acc[name] = [...(acc[name] ?? []), { name, value }];
      return acc;
    },
    {}
  );

  return grouped;
}

/**
 * @todo combine groupCategories (attributes) and groupTasks for reuse
 * May also be able to use for spaces in future
 */
export function groupTasks<T extends { name: string }>(tasks: T[] | undefined) {
  if (!tasks) return {};

  const grouped = tasks.reduce<Grouped<T>>((acc, task) => {
    const { name } = task;
    const i = name.indexOf('\\');

    if (i > -1) {
      const group = name.substring(0, i).trim();
      acc[group] = [
        ...(acc[group] ?? []),
        { ...task, name: name.substring(i + 1).trim(), _name: name },
      ];
      return acc;
    }

    acc[name] = [...(acc[name] ?? []), { ...task, name }];
    return acc;
  }, {});

  return grouped;
}
