import { graphql } from '~/gql';

export const AttachmentFields = graphql(`
  fragment AttachmentFields on Attachment {
    id
    kind
    ext
    thumb
    url
    originalFilename
  }
`);
