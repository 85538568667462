import { Button, ButtonProps } from '~/components/form/SubmitButton';
import { Confirm } from '~/components/ui/Confirm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from './DateTime';

type Props = {
  open: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: (date: Date) => void;
};

export function ConfirmDateTime({ open, onCancel, onConfirm, title }: Props) {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const dateStr = date.toISOString();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleConfirm = () => {
    onConfirm(date);
  };

  const handleChange = (value: string | null) => {
    setDate(value ? new Date(value) : new Date());
  };

  return (
    <Confirm
      show={open}
      title={t(`confirm${title}`)}
      confirm={t('confirm')}
      cancel={t('cancel')}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    >
      <div className='flex gap-4 pl-2'>
        <DateTime
          timeZone={timeZone}
          label={t(title)}
          value={dateStr}
          onChange={handleChange}
        />
      </div>
    </Confirm>
  );
}

type ConfirmButtonProps = {
  title: string;
  onConfirm: Props['onConfirm'];
} & ButtonProps;

export function ConfirmButton({
  onConfirm,
  title,
  ...props
}: ConfirmButtonProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = (date: Date) => {
    setShowConfirmation(false);
    onConfirm(date);
  };

  return (
    <>
      <ConfirmDateTime
        open={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleConfirm}
        title={title}
      />
      <Button type='button' onClick={handleClick} {...props} />
    </>
  );
}
