import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromRaw,
  Editor as BaseEditor,
  EditorState,
  RawDraftContentState,
} from 'draft-js';
import { createBlockRenderer } from './util';
import { Link, PastedLink } from './utilLinks';

type Props = {
  content?: RawDraftContentState | string;
};

function parseContent(
  content: Props['content']
): RawDraftContentState | undefined {
  if (!content) return;
  if (typeof content !== 'string') return content;

  try {
    return JSON.parse(content);
  } catch (error) {
    return;
  }
}

function hasContent(content: RawDraftContentState | undefined) {
  const blocks = content?.blocks;
  if (Array.isArray(blocks)) {
    if (blocks.length > 1) return true;
    if (Boolean(blocks[0].text)) return true;
  }
  return false;
}

export const ReadOnlyEditor = ({ content }: Props) => {
  const initialState = parseContent(content);

  if (!hasContent(initialState)) {
    return null;
  }

  const LINK_REGEX = RegExp(/^(ftp|http|https):\/\/[^ "]+$/);

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
    {
      strategy: linkStrategy,
      component: PastedLink,
    },
  ]);

  const editorState = initialState
    ? EditorState.createWithContent(convertFromRaw(initialState), decorator)
    : EditorState.createEmpty();

  function findLinkEntities(
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    }, callback);
  }

  function linkStrategy(
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void
  ) {
    findWithRegex(LINK_REGEX, contentBlock, callback);
  }

  function findWithRegex(
    regex: RegExp,
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void
  ) {
    const text = contentBlock.getText();
    let matchArr, start;
    if ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  return (
    <BaseEditor
      editorState={editorState}
      blockRendererFn={createBlockRenderer(editorState, () => {}, true)}
      onChange={() => {}}
      readOnly
    />
  );
};
