import { formatInTimeZone } from 'date-fns-tz';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../form/TextField';

type Props = {
  timeZone: string;
  label: string;
  value: string | null;
  onChange: (value: string | null) => void;
};

type FormData = {
  date: string;
  time: string;
};

function emptyIfEpoch(date: string) {
  return date === '1970-01-01' ? '' : date;
}

function emptyIfMidnight(time: string) {
  return time === '00:00' ? '' : time;
}

function parseValue(timeZone: string, value: string | null) {
  if (!value) {
    return { date: '', time: '' };
  }

  //const date = roundToNearestMinutes(new Date(value), { nearestTo: 15 });
  const date = new Date(value);

  return {
    date: emptyIfEpoch(formatInTimeZone(date, timeZone, 'yyyy-MM-dd')),
    time: emptyIfMidnight(formatInTimeZone(date, timeZone, 'HH:mm')),
  };
}

function toDate({ date, time }: Partial<FormData>) {
  if (date === undefined || time === undefined) {
    return null;
  }

  if (
    (date === '' || date === '1970-01-01') &&
    (time === '' || time === '00:00')
  ) {
    return null;
  }

  return new Date(`${date || '1970-01-01'}T${time || '00:00'}`).toISOString();
}

export function DateTime({ timeZone, label, value, onChange }: Props) {
  const { control, register, watch, getValues } = useForm<FormData>({
    defaultValues: parseValue(timeZone, value),
  });

  // Once on mount, update the parent value if the local value is different (due to rounding to nearest 5 minute interval)
  useEffect(() => {
    const localValue = toDate(getValues());
    if (localValue && localValue !== value) {
      onChange(localValue);
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      onChange(toDate(value));
    });
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  return (
    <div className='flex w-full items-stretch gap-3 text-left text-base'>
      <Input {...register('date')} label={label} type='date' />
      <Controller
        control={control}
        name='time'
        render={({ field: { value, onChange } }) => (
          <div className='pt-[24px]'>
            <input
              type='time'
              className='input form-input mt-0.5 block w-full min-w-0 appearance-none whitespace-nowrap rounded border border-grey-20 bg-white px-3 py-2.5 focus:!border-grey-40'
              style={{ outline: 'none', boxShadow: 'none' }}
              value={value}
              onChange={onChange}
            />
          </div>
        )}
      />
    </div>
  );
}
