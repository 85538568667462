import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isVisible: boolean;
  position?: string;
};

export const Tooltip = ({ children, isVisible, position }: Props) =>
  isVisible ? (
    <div className='relative'>
      <p
        className={classNames(
          'absolute truncate rounded bg-[#2d3131] px-2 py-1 text-sm leading-4 text-[#eef1f1]',
          position ?? '-right-6 -top-6 '
        )}
      >
        {children}
      </p>
    </div>
  ) : null;
