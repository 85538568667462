import classNames from 'classnames';
import { TFunctionKeys, TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

type Props = {
  label: TFunctionKeys | TFunctionKeys[];
  options?: TOptions;
  value?: React.ReactNode;
  children?: React.ReactNode;

  /** Append some custom classNames to value div */
  className?: string;
};

export const ReadValue = ({
  label,
  options,
  value,
  children,
  className,
}: Props) => {
  const { t } = useTranslation();
  const val = children ?? value;

  // 0 is an acceptable value
  if (
    val == null ||
    val === '' ||
    val === false ||
    (Array.isArray(val) && val.length === 0)
  ) {
    return null;
  }

  return (
    <>
      <div className='text-medium mb-1 pt-1 text-xs text-grey-40'>
        {t(label, options)}
      </div>
      <div className={classNames('mb-3', className)}>{children ?? value}</div>
    </>
  );
};
