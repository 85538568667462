import { SVGProps } from 'react';

const SvgStandard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='25' height='25' fill='none' />
    <path
      d='M5.75 6C6.34766 6 6.875 6.52734 6.875 7.125C6.875 7.75781 6.34766 8.25 5.75 8.25C5.11719 8.25 4.625 7.75781 4.625 7.125C4.625 6.52734 5.11719 6 5.75 6ZM9.96875 6.28125H20.6562C21.1133 6.28125 21.5 6.66797 21.5 7.125C21.5 7.61719 21.1133 7.96875 20.6562 7.96875H9.96875C9.47656 7.96875 9.125 7.61719 9.125 7.125C9.125 6.66797 9.47656 6.28125 9.96875 6.28125ZM9.96875 11.9062H20.6562C21.1133 11.9062 21.5 12.293 21.5 12.75C21.5 13.2422 21.1133 13.5938 20.6562 13.5938H9.96875C9.47656 13.5938 9.125 13.2422 9.125 12.75C9.125 12.293 9.47656 11.9062 9.96875 11.9062ZM9.96875 17.5312H20.6562C21.1133 17.5312 21.5 17.918 21.5 18.375C21.5 18.8672 21.1133 19.2188 20.6562 19.2188H9.96875C9.47656 19.2188 9.125 18.8672 9.125 18.375C9.125 17.918 9.47656 17.5312 9.96875 17.5312ZM6.875 12.75C6.875 13.3828 6.34766 13.875 5.75 13.875C5.11719 13.875 4.625 13.3828 4.625 12.75C4.625 12.1523 5.11719 11.625 5.75 11.625C6.34766 11.625 6.875 12.1523 6.875 12.75ZM5.75 17.25C6.34766 17.25 6.875 17.7773 6.875 18.375C6.875 19.0078 6.34766 19.5 5.75 19.5C5.11719 19.5 4.625 19.0078 4.625 18.375C4.625 17.7773 5.11719 17.25 5.75 17.25Z'
      fill='currentColor'
    />
  </svg>
);

export default SvgStandard;
