import { RequestPolicy, useQuery } from 'urql';
import { graphql } from '~/gql';
import { ContactsQuery, Status } from '~/gql/graphql';

export type Contact = ContactsQuery['contacts'][number];

const ContactsDocument = graphql(`
  query Contacts($status: [Status!], $relationship: [ContactRelationship!]) {
    contacts(status: $status, relationship: $relationship) {
      id
      status
      type
      name
      email
      phone
      relationship
      image
      isCurrentUser
      isTeamMember
    }
  }
`);

export function useContacts({
  includeInactive = false,
  requestPolicy = 'cache-and-network',
}: {
  includeInactive?: boolean;
  requestPolicy?: RequestPolicy;
} = {}): Contact[] {
  const [result] = useQuery({
    query: ContactsDocument,
    variables: {
      status: includeInactive
        ? [Status.Active, Status.Inactive]
        : [Status.Active],
    },
    requestPolicy,
  });

  return result.data?.contacts ?? [];
}
