import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Dialog } from './Dialog';

type Props = {
  title?: string;
  children?: React.ReactNode;
  body?: string;
  /** Cancel button text */
  cancel?: string;
  /** Confirm button text */
  confirm?: string;
  onCancel: () => void;
  onConfirm: () => void;
  show?: boolean;
  danger?: boolean;
  isLoading?: boolean;
};

export const Confirm = ({
  title = 'Discard changes?',
  children,
  body = 'Are you sure you want to close this form and discard your changes?',
  cancel = 'Stay with form',
  confirm = 'Discard',
  show = true,
  onCancel,
  onConfirm,
  danger,
  isLoading = false,
}: Props) => {
  return (
    <Dialog
      show={show}
      title={title}
      onClose={onCancel}
      danger={danger}
      isLoading={isLoading}
    >
      <div className='mx-4 my-8 text-center text-sm font-normal text-copy-alt'>
        {children ?? body}
      </div>
      <div className='m-4 text-right'>
        <button
          className={classNames(
            'text-xs uppercase transition-all duration-300',
            isLoading && 'disabled:opacity-50'
          )}
          onClick={onCancel}
          disabled={isLoading}
        >
          {cancel}
        </button>
        <button
          className={classNames(
            'ml-8 rounded-md p-2 text-xs uppercase text-white shadow transition-all duration-300',
            danger ? 'bg-delete' : 'bg-brand',
            isLoading && 'disabled:opacity-50'
          )}
          onClick={onConfirm}
          disabled={isLoading}
        >
          {confirm}
        </button>
      </div>
    </Dialog>
  );
};

type ConfirmMessageProps = Omit<Props, 'children' | 'onConfirm'> & {
  onConfirm: (message: string) => void;
};

export function ConfirmMessage({
  body,
  onConfirm,
  ...props
}: ConfirmMessageProps) {
  const ref = useRef<HTMLTextAreaElement>(null);

  return (
    <Confirm {...props} onConfirm={() => onConfirm(ref.current?.value ?? '')}>
      {body}
      <textarea
        ref={ref}
        className='input mt-3 w-full resize-none rounded-md border border-grey-20 px-3 py-2.5 focus-visible:border-brand focus-visible:outline-none'
        placeholder='Add optional message...'
        autoFocus
      ></textarea>
    </Confirm>
  );
}

export function useConfirm({ when = true }: { when?: boolean } = {}) {
  const confirmRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const confirmRoot = document.createElement('div');
    document.body.appendChild(confirmRoot);
    confirmRef.current = confirmRoot;

    return () => {
      document.body.removeChild(confirmRoot);
    };
  }, []);

  const confirm = (fn: CallableFunction) => () => {
    if (!when) {
      return fn();
    }

    if (!confirmRef.current) {
      return;
    }

    render(
      <Confirm
        onCancel={() => {
          if (confirmRef.current) {
            unmountComponentAtNode(confirmRef.current);
          }
        }}
        onConfirm={() => {
          if (confirmRef.current) {
            unmountComponentAtNode(confirmRef.current);
          }
          fn();
        }}
      />,
      confirmRef.current
    );
  };

  return { confirm };
}
