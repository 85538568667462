import { groupBy } from 'lodash';
import { Fragment } from 'react';
import { parseJson } from '~/helpers';
import { formatDate } from '~/helpers/formatDate';
import { useContacts } from '~/routes/resources/contacts';
import { Files } from './FileLink';
import { Gallery } from './job/JobView/Feed/Gallery';
import { Linkify } from './job/JobView/Feed/Linkify';
import { Contact } from './job/JobView/JobReadView/Bubbles';

type Props = {
  attributes: {
    type?: string;
    category?: string | null;
    name: string;
    value: string[];
    attachments?:
      | {
          id: string;
          kind: string;
          ext: string;
          thumb: string;
          url: string;
          originalFilename: string;
        }[]
      | null;
  }[];
  contacts?: Contact[];
};

export function AttributeView({ attributes, contacts: contactsProp }: Props) {
  const contacts = contactsProp ?? useContacts();
  const nonEmptyAttributes = attributes.filter((a) =>
    a.type === 'attachment'
      ? a.attachments && a.attachments.length > 0
      : a.value.length > 0 && a.value[0] !== ''
  );
  const grouped = groupBy(nonEmptyAttributes, (item) => {
    // if (item.type === 'attachment') {
    //   return item.category ? `${item.category} \\ ${item.name}` : item.name;
    // }
    return item.category ?? 'General';
  });

  return (
    <>
      {Object.keys(grouped).map((key) => (
        <Fragment key={key}>
          <div className='pt-3 text-base font-semibold text-primary'>{key}</div>

          {grouped[key].map((attribute) => {
            const oldValue = parseJson(attribute.value[0]);
            const displayValue =
              attribute.type === 'contact' && Array.isArray(oldValue) ? (
                contacts
                  .filter(({ id }) => oldValue.includes(id))
                  .map(({ name }) => name)
                  .join('; ')
              ) : attribute.type === 'date' ? (
                formatDate(attribute.value[0], 'PP')
              ) : (
                <Linkify>{attribute.value.join('; ')}</Linkify>
              );

            return (
              <Fragment key={attribute.name}>
                {attribute.type === 'attachment' && attribute.attachments ? (
                  <div className='mb-3 indent-2 leading-5'>
                    <div className='font-[500]'>{attribute.name}:</div>
                    <Files attachments={attribute.attachments} />
                    <Gallery attachments={attribute.attachments} />
                  </div>
                ) : grouped[key].length === 1 &&
                  key === grouped[key][0].name ? (
                  <p className='leading-5'>{displayValue}</p>
                ) : (
                  <div className='flex items-start gap-2 py-1 leading-5'>
                    <div className="indent-2 font-[500] after:content-[':']">
                      {attribute.name}
                    </div>
                    <p className='whitespace-pre-line text-secondary'>
                      {displayValue}
                    </p>
                  </div>
                )}
              </Fragment>
            );
          })}
        </Fragment>
      ))}
    </>
  );
}
