import { SVGProps } from 'react';

const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 44' {...props}>
    {/* <defs>
      <style>{'.a{fill:none;}.b{fill:#fc9025;}'}</style>
    </defs> */}
    <path className='a' d='M0,0H44V44H0Z' fill='none' />
    <path
      className='b'
      d='M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15H11V11h2Zm0-8H11V7h2Z'
      transform='translate(10 10)'
      fill='currentColor'
    />
  </svg>
);

export default SvgInfo;
