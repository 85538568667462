import { cacheExchange } from '@urql/exchange-graphcache';
import type {
  Resolver as GraphCacheResolver,
  UpdateResolver as GraphCacheUpdateResolver,
  OptimisticMutationResolver as GraphCacheOptimisticMutationResolver,
} from '@urql/exchange-graphcache';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  CompositeKey: { input: any; output: any };
  DateTime: { input: any; output: any };
  EmailAddress: { input: any; output: any };
  JSON: { input: any; output: any };
  LocalDate: { input: any; output: any };
  LocalDateTime: { input: any; output: any };
  LocalTime: { input: any; output: any };
  LocationId: { input: any; output: any };
  Timestamp: { input: any; output: any };
};

export type AccountProfileInput = {
  industry?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Activity = {
  __typename?: 'Activity';
  /** Empty if the activity is not a message */
  comment?: Maybe<Comment>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** Empty if activity is not a job history entry (status change) */
  entry?: Maybe<JobHistory>;
  id: Scalars['ID']['output'];
  isFlagged: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  /** The job this activity is linked to */
  job?: Maybe<Job>;
  /** "status" or "message" */
  kind: Scalars['String']['output'];
  tags?: Maybe<Array<Tag>>;
  /** The task this activity is linked to if job activity */
  task?: Maybe<Task>;
};

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  edges: Array<ActivityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  totalUnreadCount: Scalars['Int']['output'];
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String']['output'];
  node: Activity;
};

export type AddCommentInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  body: Scalars['String']['input'];
  jobId: Scalars['ID']['input'];
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AddTagsToTaggableInput = {
  tagIds: Array<Scalars['ID']['input']>;
  taggableId: Scalars['ID']['input'];
};

export type Asset = Location & {
  __typename?: 'Asset';
  attachments: Array<Attachment>;
  attributes?: Maybe<Array<Attribute>>;
  /** The current deployment */
  deployment?: Maybe<Deployment>;
  deployments: Array<Deployment>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  jobs: Array<Job>;
  model?: Maybe<AssetModel>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  spaces: Array<Space>;
  status: Status;
  type: Scalars['String']['output'];
};

export type AssetInput = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  attributes?: InputMaybe<Array<AttributeInput>>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  /** Asset name or code */
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  status: Status;
  type: Scalars['String']['input'];
};

export type AssetModel = {
  __typename?: 'AssetModel';
  activeAssets?: Maybe<Scalars['Int']['output']>;
  brand: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  status: Status;
  type: Scalars['String']['output'];
};

export type AssetModelInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  brand: Scalars['String']['input'];
  images: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  status: Status;
  type: Scalars['String']['input'];
};

export type AssetTaskConfig = {
  __typename?: 'AssetTaskConfig';
  allow: Array<Scalars['String']['output']>;
  assetTypes: Array<Scalars['String']['output']>;
  selection: Scalars['String']['output'];
  spaces: Array<Scalars['String']['output']>;
};

export type AssetTaskInput = {
  /** Possible values: "create", "edit" */
  allow?: InputMaybe<Array<Scalars['String']['input']>>;
  assetTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  assets?: InputMaybe<Array<Scalars['ID']['input']>>;
  deploy?: InputMaybe<Scalars['String']['input']>;
  /** Allowed values: "specific", "all" */
  selection: Scalars['String']['input'];
  spaces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssignJobsInput = {
  assigneeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobIds: Array<Scalars['ID']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  ownerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<JobStatus>;
};

export type Attachment = {
  __typename?: 'Attachment';
  ext: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  thumb: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AttachmentInput = {
  id: Scalars['ID']['input'];
  kind: Scalars['String']['input'];
  originalFilename: Scalars['String']['input'];
};

export type Attribute = {
  __typename?: 'Attribute';
  attachments?: Maybe<Array<Attachment>>;
  category?: Maybe<Scalars['String']['output']>;
  condition?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  type: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type AttributeInput = {
  name: Scalars['String']['input'];
  value: Array<Scalars['String']['input']>;
};

export type AttributeSingle = {
  __typename?: 'AttributeSingle';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AttributeTask = {
  __typename?: 'AttributeTask';
  /** Attributes to include in the Attribute Task */
  attributes: Array<Scalars['String']['output']>;
  formValues?: Maybe<Array<Attribute>>;
  id: Scalars['ID']['output'];
};

export type AttributeTaskInput = {
  attributes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Automation = {
  __typename?: 'Automation';
  assignees?: Maybe<Array<Contact>>;
  conditions?: Maybe<Array<AutomationCondition>>;
  day: Scalars['Int']['output'];
  do: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobName: Scalars['String']['output'];
  matchAttribute?: Maybe<Array<Attribute>>;
  name: Scalars['String']['output'];
  on: Scalars['String']['output'];
  owners?: Maybe<Array<Contact>>;
  repeatUntilOffset?: Maybe<Scalars['Int']['output']>;
  sites: Array<Site>;
  status: Status;
  template: JobTemplate;
  time?: Maybe<Scalars['String']['output']>;
  whereType: AutomationWhereType;
};

export type AutomationCondition = {
  __typename?: 'AutomationCondition';
  nights: Scalars['Int']['output'];
  operator: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AutomationInput = {
  assignee?: InputMaybe<Array<Scalars['ID']['input']>>;
  day: Scalars['Int']['input'];
  jobName: Scalars['String']['input'];
  matchAttribute?: InputMaybe<Array<AttributeInput>>;
  name: Scalars['String']['input'];
  on: Scalars['String']['input'];
  owner?: InputMaybe<Array<Scalars['ID']['input']>>;
  runOnExistingBookings: Scalars['Boolean']['input'];
  sites: Array<Scalars['ID']['input']>;
  status: Status;
  template: Scalars['ID']['input'];
  time?: InputMaybe<Scalars['String']['input']>;
  whereType?: InputMaybe<AutomationWhereType>;
};

export enum AutomationWhereType {
  All = 'All',
  Match = 'Match',
  Select = 'Select',
}

export type Booking = {
  __typename?: 'Booking';
  end: Scalars['LocalDateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  start: Scalars['LocalDateTime']['output'];
};

export type CalendarSubscription = {
  __typename?: 'CalendarSubscription';
  id: Scalars['ID']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type Card = ISpaceSku & {
  __typename?: 'Card';
  attributes: Array<Attribute>;
  capacity?: Maybe<Scalars['Int']['output']>;
  dispatched?: Maybe<Scalars['Int']['output']>;
  fill?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  packed?: Maybe<Scalars['Int']['output']>;
  pick?: Maybe<Scalars['Int']['output']>;
  picked?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  putAway?: Maybe<Scalars['Int']['output']>;
  queuedPrice?: Maybe<Scalars['Int']['output']>;
  queuedType?: Maybe<QueuedType>;
  received?: Maybe<Scalars['Int']['output']>;
  reorderLevel?: Maybe<Scalars['Int']['output']>;
  reserved: Scalars['Int']['output'];
  sku: Sku;
  skuId: Scalars['ID']['output'];
  soh: Scalars['Int']['output'];
  space: Space;
  spaceId: Scalars['ID']['output'];
  status: Status;
  stockTransferId?: Maybe<Scalars['ID']['output']>;
  target?: Maybe<Scalars['Int']['output']>;
};

export type ChangeEmailInput = {
  email: Scalars['String']['input'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type ChecklistItem = {
  checked: Scalars['Boolean']['input'];
  key: Scalars['ID']['input'];
};

export type Comment = {
  __typename?: 'Comment';
  attachments?: Maybe<Array<Attachment>>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  isFlagged: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  tags?: Maybe<Array<Tag>>;
};

export type CompleteAttributeTaskInput = {
  attributes: Array<AttributeInput>;
  id: Scalars['ID']['input'];
};

export type CompleteItemTaskInput = {
  id: Scalars['ID']['input'];
  movements: Array<StockSelection>;
};

export type CompleteJobsInput = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  jobIds: Array<Scalars['ID']['input']>;
};

export type CompletePurchaseTaskInput = {
  adjustments: Array<StockSelection>;
  id: Scalars['ID']['input'];
};

export type CompleteTaskInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  checklist?: Array<ChecklistItem>;
  date: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  transfer?: InputMaybe<CompleteTransferTaskInput>;
};

export type CompleteTransferTaskInput = {
  selected: Array<StockSelection>;
};

export type Contact = Location & {
  __typename?: 'Contact';
  attributes?: Maybe<Array<Attribute>>;
  createdAt: Scalars['DateTime']['output'];
  deployment?: Maybe<Deployment>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  isCurrentUser: Scalars['Boolean']['output'];
  isTeamMember: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  organisation?: Maybe<Contact>;
  organisationId?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalAddress?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Array<ContactRelationship>>;
  spaces: Array<Space>;
  status: Status;
  streetAddress?: Maybe<Scalars['String']['output']>;
  type: ContactType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  images?: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalAddress?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Array<ContactRelationship>>;
  status: Status;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  type: ContactType;
};

export enum ContactRelationship {
  Customer = 'Customer',
  Other = 'Other',
  ServiceProvider = 'ServiceProvider',
  Staff = 'Staff',
  Supplier = 'Supplier',
}

export enum ContactType {
  Organisation = 'Organisation',
  Person = 'Person',
}

export type CreateJobInput = {
  assignee?: InputMaybe<Array<Scalars['ID']['input']>>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  /** A message to include with the notification */
  comment?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  enforceOrder: Scalars['Boolean']['input'];
  fromTemplate?: InputMaybe<Scalars['String']['input']>;
  guestyCleaningStatus?: InputMaybe<Scalars['String']['input']>;
  /** List of contacts to share the job with */
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  includePdf?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<Array<Scalars['ID']['input']>>;
  repeat?: InputMaybe<RepeatInput>;
  saveTemplate?: InputMaybe<Scalars['String']['input']>;
  scheduledEndDate?: InputMaybe<Scalars['LocalDate']['input']>;
  scheduledEndStrict: Scalars['String']['input'];
  scheduledEndTime?: InputMaybe<Scalars['LocalTime']['input']>;
  scheduledStartDate?: InputMaybe<Scalars['LocalDate']['input']>;
  scheduledStartStrict: Scalars['String']['input'];
  scheduledStartTime?: InputMaybe<Scalars['LocalTime']['input']>;
  scope: CreateJobScope;
  scopeIds: Array<Scalars['ID']['input']>;
  sendMeACopy?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<JobStatus>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  tasks: Array<TaskInput>;
  timeZone: Scalars['String']['input'];
};

export enum CreateJobScope {
  Asset = 'Asset',
  Site = 'Site',
}

export type CreateTagInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  colour?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type DeleteTagInput = {
  id: Scalars['ID']['input'];
};

export type Deployment = {
  __typename?: 'Deployment';
  asset: Asset;
  assetId: Scalars['ID']['output'];
  defaultSourceId?: Maybe<Scalars['ID']['output']>;
  displayLocation: Scalars['String']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  location: Location;
  owner?: Maybe<Scalars['String']['output']>;
  space: Space;
  spaceId: Scalars['ID']['output'];
  startedAt: Scalars['DateTime']['output'];
  state?: Maybe<Scalars['String']['output']>;
  status: Status;
};

export type DeploymentDisplayLocationArgs = {
  separator?: Scalars['String']['input'];
};

export type Elsewhere = Location & {
  __typename?: 'Elsewhere';
  deployment?: Maybe<Deployment>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  spaces: Array<Space>;
};

export type FileInput = {
  form: Scalars['String']['input'];
  lastModified: Scalars['Timestamp']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};

export type Filter = {
  field: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type Fsp = Location & {
  __typename?: 'Fsp';
  deployment?: Maybe<Deployment>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  spaces: Array<Space>;
};

export type HomheroArea = {
  __typename?: 'HomheroArea';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ISpaceSku = {
  attributes: Array<Attribute>;
  capacity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  queuedPrice?: Maybe<Scalars['Int']['output']>;
  queuedType?: Maybe<QueuedType>;
  reorderLevel?: Maybe<Scalars['Int']['output']>;
  reserved: Scalars['Int']['output'];
  sku: Sku;
  skuId: Scalars['ID']['output'];
  soh: Scalars['Int']['output'];
  space: Space;
  spaceId: Scalars['ID']['output'];
  status: Status;
  target?: Maybe<Scalars['Int']['output']>;
};

/** An invitation to join a team */
export type Invitation = {
  __typename?: 'Invitation';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Item = {
  __typename?: 'Item';
  activeSkus: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasVariants: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  skuPrefix?: Maybe<Scalars['String']['output']>;
  skus: Array<Sku>;
  status: Status;
  type: ItemType;
  variantTypes: Array<VariantType>;
};

export type ItemInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hasVariants: Scalars['Boolean']['input'];
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  status?: InputMaybe<Status>;
  type: ItemType;
  variants?: InputMaybe<Array<VariantInput>>;
};

export type ItemQty = {
  __typename?: 'ItemQty';
  id: Scalars['ID']['output'];
  qty: Scalars['Int']['output'];
};

export type ItemTask = {
  __typename?: 'ItemTask';
  cards: Array<Card>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lineItems: Array<StockTransferItem>;
  spaceSkus: Array<SpaceSku>;
  status: JobStatus;
  stockTransfer: StockTransfer;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ItemTaskInput = {
  dispatch?: InputMaybe<Array<StockSelection>>;
  fillTo: StockTransferRule;
  /** Of the form `Type:id` */
  locationId: Scalars['ID']['input'];
  processQueuedChanges?: InputMaybe<Scalars['Boolean']['input']>;
  receive?: InputMaybe<Array<StockSelection>>;
  reserveStock?: InputMaybe<Scalars['Boolean']['input']>;
  selectStock: SelectStockOption;
  supplyJob: Scalars['Boolean']['input'];
};

export enum ItemType {
  Consumable = 'Consumable',
  Part = 'Part',
  Product = 'Product',
}

export type Job = {
  __typename?: 'Job';
  _dateCreated: Scalars['String']['output'];
  _dateScheduled?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Job.assignees instead which correctly returns multiple assignees */
  assignee?: Maybe<Contact>;
  assignees?: Maybe<Array<Contact>>;
  attachments?: Maybe<Array<Attachment>>;
  attributes?: Maybe<Array<Attribute>>;
  booking?: Maybe<Booking>;
  /** @deprecated Front-end will compute this itself for offline support */
  canAcceptOffer: Scalars['Boolean']['output'];
  comments: Array<JobFeed>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Contact>;
  deploymentString?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['EmailAddress']['output']>;
  enforceOrder: Scalars['Boolean']['output'];
  guestyCleaningStatus?: Maybe<Scalars['String']['output']>;
  history: Array<JobHistory>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  include?: Maybe<Array<Contact>>;
  isScheduled: Scalars['Boolean']['output'];
  isSource: Scalars['Boolean']['output'];
  jobNumber: Scalars['String']['output'];
  jobTags?: Maybe<Array<Tag>>;
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  nextCheckIn?: Maybe<Booking>;
  nodeId: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  /** If the job location has a booking on the same date as this job, returns that booking, otherwise null */
  occupied?: Maybe<Booking>;
  /** @deprecated Account */
  owner: Organisation;
  owners?: Maybe<Array<Contact>>;
  permissions: Array<Scalars['String']['output']>;
  prevCheckOut?: Maybe<Booking>;
  scheduleEnd?: Maybe<Scalars['DateTime']['output']>;
  scheduleStart?: Maybe<Scalars['DateTime']['output']>;
  /** Scheduled end date localized */
  scheduledEndDate?: Maybe<Scalars['LocalDate']['output']>;
  scheduledEndStrict: Scalars['Boolean']['output'];
  /** Scheduled end time localized */
  scheduledEndTime?: Maybe<Scalars['LocalTime']['output']>;
  /** Scheduled start date localized */
  scheduledStartDate?: Maybe<Scalars['LocalDate']['output']>;
  scheduledStartStrict: Scalars['Boolean']['output'];
  /** Scheduled start time localized */
  scheduledStartTime?: Maybe<Scalars['LocalTime']['output']>;
  site?: Maybe<Site>;
  state?: Maybe<Scalars['String']['output']>;
  status: JobStatus;
  /** @deprecated Use jobTags instead */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  tasks: Array<Task>;
  timeZone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type JobCommentsArgs = {
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type JobCalendarEvent = {
  __typename?: 'JobCalendarEvent';
  attributes?: Maybe<Array<Attribute>>;
  cancelled: Scalars['Boolean']['output'];
  /** @deprecated Use start instead */
  date: Scalars['DateTime']['output'];
  end?: Maybe<Scalars['DateTime']['output']>;
  hidden: Scalars['Boolean']['output'];
  /** The name of an icon to use to help distinquish where the event came from */
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobs: Array<Job>;
  location?: Maybe<Location>;
  start: Scalars['DateTime']['output'];
  summary: Scalars['String']['output'];
  timeZone: Scalars['String']['output'];
  uid: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type JobFeed = Comment | JobHistory;

export type JobHistory = {
  __typename?: 'JobHistory';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  /** Date entered by user if applicable */
  inputDate?: Maybe<Scalars['DateTime']['output']>;
  isFlagged: Scalars['Boolean']['output'];
  jobId: Scalars['ID']['output'];
  reverted: Scalars['Boolean']['output'];
  status: JobStatus;
  task?: Maybe<Task>;
  /** If not null this refers to task history */
  taskId?: Maybe<Scalars['ID']['output']>;
};

export type JobList = Job | JobCalendarEvent;

export enum JobStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Created = 'Created',
  Declined = 'Declined',
  Deleted = 'Deleted',
  InProgress = 'InProgress',
  Incomplete = 'Incomplete',
  Offered = 'Offered',
  Paused = 'Paused',
}

export type JobTemplate = {
  __typename?: 'JobTemplate';
  attachments?: Maybe<Array<Attachment>>;
  guestyCleaningStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  includeAttributes?: Maybe<Array<Scalars['String']['output']>>;
  isStarter: Scalars['Boolean']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  status: Status;
  tags?: Maybe<Array<Tag>>;
  tasks: Array<Task>;
};

export type JobTemplateInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  guestyCleaningStatus?: InputMaybe<Scalars['String']['input']>;
  includeAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  tasks: Array<TaskInput>;
};

export type JobsConnection = {
  __typename?: 'JobsConnection';
  edges: Array<JobsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type JobsEdge = {
  __typename?: 'JobsEdge';
  cursor: Scalars['String']['output'];
  node: Job;
};

export type Location = {
  deployment?: Maybe<Deployment>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  spaces: Array<Space>;
};

export type LocationSelection = {
  id: Scalars['ID']['input'];
  spaces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum LocationType {
  Asset = 'Asset',
  Contact = 'Contact',
  Site = 'Site',
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptJob?: Maybe<Job>;
  addComment: Comment;
  addItem: Item;
  addTagsToActivity: Activity;
  addTagsToJob: Array<Job>;
  addTagsToTaggable: Taggable;
  adjustStock: StockAdjustment;
  assignJobs?: Maybe<Array<Job>>;
  cancelJob?: Maybe<Job>;
  cancelJobs?: Maybe<Array<Job>>;
  changeEmailAddress: Scalars['Int']['output'];
  changePassword: Scalars['Int']['output'];
  /** completeAttributeTask v2 */
  completeAttributeAuditTask: Task;
  /** @deprecated See completeAttributeAuditTask which is a newer version of this */
  completeAttributeTask: Scalars['Int']['output'];
  completeCustomTask: Task;
  completeItemTask: Task;
  completeJob?: Maybe<Job>;
  completeJobs?: Maybe<Array<Job>>;
  completePurchaseTask: Scalars['Int']['output'];
  createAsset: Asset;
  createAssetModel: AssetModel;
  createAutomation?: Maybe<Scalars['ID']['output']>;
  createContact?: Maybe<Contact>;
  createDeployment: Deployment;
  createJobTemplate: JobTemplate;
  createJobs: Array<Job>;
  createSite: Site;
  createSpace: Space;
  createTag: Tag;
  createTenant: Scalars['ID']['output'];
  /** @deprecated This is for the old S3 strategy. Use CloudFlare images uploader instead. */
  createUpload: Upload;
  declineJob?: Maybe<Job>;
  deleteJob: Scalars['Int']['output'];
  deleteJobTemplate: Scalars['Int']['output'];
  deleteTag: Scalars['Boolean']['output'];
  endTask?: Maybe<Task>;
  finishStocktake: Scalars['Int']['output'];
  flagMessage: Scalars['Boolean']['output'];
  generatePicks: Scalars['String']['output'];
  guestySetup?: Maybe<Scalars['String']['output']>;
  hideJobCalendarEvent: JobCalendarEvent;
  inviteUser: Invitation;
  login: Session;
  markAllAsRead: Scalars['Int']['output'];
  markAsRead: Scalars['Int']['output'];
  markAsReadV2: Activity;
  offerJob?: Maybe<Job>;
  onboard: Onboarding;
  otpGenerate: OtpGenerateResponse;
  otpVerify: OtpVerifyResponse;
  patchOrganisationProfile: Scalars['Int']['output'];
  pauseJob?: Maybe<Job>;
  pauseTask?: Maybe<Task>;
  quickAdd: SpaceSku;
  removeTagsFromActivity: Activity;
  removeTagsFromJob: Array<Job>;
  removeTagsFromTaggable: Taggable;
  removeUser: Scalars['Boolean']['output'];
  rescheduleJob: Job;
  /** null and undefined are very important for this mutation */
  rescheduleJobs: Array<Job>;
  resetTaskStatus?: Maybe<Task>;
  reslySetup?: Maybe<Scalars['String']['output']>;
  resumeJob?: Maybe<Job>;
  resumeTask?: Maybe<Task>;
  revertJobStatus?: Maybe<Job>;
  saveNotificationPreferences: Scalars['Int']['output'];
  sendVerificationEmail: Scalars['Boolean']['output'];
  setProfilePic: User;
  sortJob: Scalars['Boolean']['output'];
  startJob?: Maybe<Job>;
  startTask?: Maybe<Task>;
  updateAccountProfile: Scalars['Boolean']['output'];
  updateAsset: Asset;
  updateAssetModel: AssetModel;
  updateAutomation?: Maybe<Scalars['ID']['output']>;
  updateContact?: Maybe<Contact>;
  updateDeployment: Scalars['Int']['output'];
  updateItem: Item;
  updateJob: Job;
  updateJobTemplate: JobTemplate;
  updateJobTemplates?: Maybe<Array<Job>>;
  updatePersonalInfo: Scalars['Int']['output'];
  updateSite: Site;
  updateSpace: Space;
  updateSpaceSku: SpaceSku;
  updateTag: Tag;
};

export type MutationAcceptJobArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  notify?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationAddCommentArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  input?: InputMaybe<AddCommentInput>;
};

export type MutationAddItemArgs = {
  input: ItemInput;
};

export type MutationAddTagsToActivityArgs = {
  id: Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']>;
};

export type MutationAddTagsToJobArgs = {
  id: Array<Scalars['ID']['input']>;
  tagIds: Array<Scalars['ID']['input']>;
};

export type MutationAddTagsToTaggableArgs = {
  input: AddTagsToTaggableInput;
};

export type MutationAdjustStockArgs = {
  input: StockAdjustmentInput;
};

export type MutationAssignJobsArgs = {
  input: AssignJobsInput;
};

export type MutationCancelJobArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationCancelJobsArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

export type MutationChangeEmailAddressArgs = {
  input: ChangeEmailInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCompleteAttributeAuditTaskArgs = {
  input: CompleteAttributeTaskInput;
};

export type MutationCompleteAttributeTaskArgs = {
  input: CompleteAttributeTaskInput;
};

export type MutationCompleteCustomTaskArgs = {
  input: CompleteTaskInput;
};

export type MutationCompleteItemTaskArgs = {
  input: CompleteItemTaskInput;
};

export type MutationCompleteJobArgs = {
  endTime: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
};

export type MutationCompleteJobsArgs = {
  input: CompleteJobsInput;
};

export type MutationCompletePurchaseTaskArgs = {
  input: CompletePurchaseTaskInput;
};

export type MutationCreateAssetArgs = {
  input: AssetInput;
};

export type MutationCreateAssetModelArgs = {
  input: AssetModelInput;
};

export type MutationCreateAutomationArgs = {
  input: AutomationInput;
};

export type MutationCreateContactArgs = {
  input: ContactInput;
};

export type MutationCreateDeploymentArgs = {
  input: NewDeploymentInput;
};

export type MutationCreateJobTemplateArgs = {
  input: JobTemplateInput;
};

export type MutationCreateJobsArgs = {
  input: CreateJobInput;
};

export type MutationCreateSiteArgs = {
  input: SiteInput;
};

export type MutationCreateSpaceArgs = {
  input: SpaceInput;
  locationId: Scalars['LocationId']['input'];
};

export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

export type MutationCreateTenantArgs = {
  input: AccountProfileInput;
};

export type MutationCreateUploadArgs = {
  file: FileInput;
};

export type MutationDeclineJobArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationDeleteJobArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteJobTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};

export type MutationEndTaskArgs = {
  endTime: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
};

export type MutationFinishStocktakeArgs = {
  input: StocktakeInput;
};

export type MutationFlagMessageArgs = {
  flagged: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type MutationGeneratePicksArgs = {
  stockTransferId: Scalars['Int']['input'];
};

export type MutationGuestySetupArgs = {
  integrationToken: Scalars['String']['input'];
};

export type MutationHideJobCalendarEventArgs = {
  id: Scalars['ID']['input'];
};

export type MutationInviteUserArgs = {
  email: Scalars['String']['input'];
};

export type MutationMarkAllAsReadArgs = {
  before: Scalars['ID']['input'];
};

export type MutationMarkAsReadArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkAsReadV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationOfferJobArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  notify?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationOnboardArgs = {
  input?: InputMaybe<Scalars['String']['input']>;
  step: Scalars['Int']['input'];
  version: Scalars['ID']['input'];
};

export type MutationOtpGenerateArgs = {
  input: OtpGenerateInput;
};

export type MutationOtpVerifyArgs = {
  input: OtpVerifyInput;
};

export type MutationPatchOrganisationProfileArgs = {
  input: OrganisationProfileInput;
};

export type MutationPauseJobArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPauseTaskArgs = {
  id: Scalars['ID']['input'];
  pauseTime: Scalars['DateTime']['input'];
};

export type MutationQuickAddArgs = {
  input: QuickAddInput;
};

export type MutationRemoveTagsFromActivityArgs = {
  id: Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']>;
};

export type MutationRemoveTagsFromJobArgs = {
  id: Array<Scalars['ID']['input']>;
  tagIds: Array<Scalars['ID']['input']>;
};

export type MutationRemoveTagsFromTaggableArgs = {
  input: RemoveTagsFromTaggableInput;
};

export type MutationRemoveUserArgs = {
  email: Scalars['String']['input'];
};

export type MutationRescheduleJobArgs = {
  input: RescheduleJobInput;
};

export type MutationRescheduleJobsArgs = {
  input: RescheduleJobsInput;
};

export type MutationResetTaskStatusArgs = {
  id: Scalars['ID']['input'];
};

export type MutationReslySetupArgs = {
  key: Scalars['String']['input'];
  propertyId: Scalars['String']['input'];
};

export type MutationResumeJobArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResumeTaskArgs = {
  id: Scalars['ID']['input'];
  resumeTime: Scalars['DateTime']['input'];
};

export type MutationRevertJobStatusArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  currentStatus: JobStatus;
  id: Scalars['ID']['input'];
};

export type MutationSaveNotificationPreferencesArgs = {
  input: NotificationSettingInput;
};

export type MutationSetProfilePicArgs = {
  image: Scalars['String']['input'];
};

export type MutationSortJobArgs = {
  id: Scalars['ID']['input'];
  rel: Scalars['ID']['input'];
  sort: Scalars['String']['input'];
};

export type MutationStartJobArgs = {
  id: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type MutationStartTaskArgs = {
  id: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type MutationUpdateAccountProfileArgs = {
  input: AccountProfileInput;
};

export type MutationUpdateAssetArgs = {
  id: Scalars['ID']['input'];
  input: AssetInput;
};

export type MutationUpdateAssetModelArgs = {
  id: Scalars['ID']['input'];
  input: AssetModelInput;
};

export type MutationUpdateAutomationArgs = {
  id: Scalars['ID']['input'];
  input: AutomationInput;
};

export type MutationUpdateContactArgs = {
  id: Scalars['ID']['input'];
  input: ContactInput;
};

export type MutationUpdateDeploymentArgs = {
  id: Scalars['ID']['input'];
  images: Array<Scalars['String']['input']>;
};

export type MutationUpdateItemArgs = {
  id: Scalars['ID']['input'];
  input: ItemInput;
};

export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};

export type MutationUpdateJobTemplateArgs = {
  id: Scalars['ID']['input'];
  input: JobTemplateInput;
};

export type MutationUpdateJobTemplatesArgs = {
  jobIds: Array<Scalars['ID']['input']>;
  templateId: Scalars['ID']['input'];
};

export type MutationUpdatePersonalInfoArgs = {
  input: PersonalInfoInput;
};

export type MutationUpdateSiteArgs = {
  id: Scalars['ID']['input'];
  input: SiteInput;
};

export type MutationUpdateSpaceArgs = {
  id: Scalars['ID']['input'];
  input: SpaceInput;
};

export type MutationUpdateSpaceSkuArgs = {
  id: Scalars['ID']['input'];
  input: SpaceSkuInput;
};

export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type NewDeploymentInput = {
  assetId: Scalars['ID']['input'];
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  locationId: Scalars['LocationId']['input'];
  spaceName: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type NotificationSettingInput = {
  checked?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  form: Scalars['String']['output'];
  hasNextStep: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  step: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
  waitingFor?: Maybe<Scalars['String']['output']>;
};

export type Organisation = {
  __typename?: 'Organisation';
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type OrganisationProfileInput = {
  industry?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OtpGenerateInput = {
  identifier: Scalars['String']['input'];
  method?: InputMaybe<Scalars['String']['input']>;
};

export type OtpGenerateResponse = {
  __typename?: 'OtpGenerateResponse';
  id?: Maybe<Scalars['ID']['output']>;
  success: Scalars['Boolean']['output'];
};

export type OtpVerifyInput = {
  id: Scalars['ID']['input'];
  otp: Scalars['String']['input'];
};

export type OtpVerifyResponse = {
  __typename?: 'OtpVerifyResponse';
  success: Scalars['Boolean']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['ID']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type PersonalInfoInput = {
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type PurchaseTask = {
  __typename?: 'PurchaseTask';
  id: Scalars['ID']['output'];
  movements?: Maybe<Array<PurchaseTaskItem>>;
  /** Space names to include in the Purchase Task */
  spaces: Array<Scalars['String']['output']>;
};

export type PurchaseTaskInput = {
  spaces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PurchaseTaskItem = {
  __typename?: 'PurchaseTaskItem';
  id: Scalars['ID']['output'];
  qty: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** @deprecated Use tags (entityTypes: ["Activity"]) instead */
  activityTags: Array<Scalars['String']['output']>;
  addItemsTask: ItemTask;
  allActivity: ActivityConnection;
  asset?: Maybe<Asset>;
  assetModels: Array<AssetModel>;
  assetModelsFindByIds: Array<AssetModel>;
  assetTypes: Array<Scalars['String']['output']>;
  assets: Array<Asset>;
  attributes: Array<Attribute>;
  automation?: Maybe<Automation>;
  automations: Array<Automation>;
  backgroundJob: Scalars['String']['output'];
  /** @deprecated This is for the old S3 strategy. Use CloudFlare images uploader instead. */
  baseUrl: Scalars['String']['output'];
  contact?: Maybe<Contact>;
  contacts: Array<Contact>;
  deployments: Array<Deployment>;
  fsps: Array<Fsp>;
  homheroAreas?: Maybe<Array<HomheroArea>>;
  inbox: Array<Thread>;
  integrations: Array<Scalars['String']['output']>;
  isOnboarding: Scalars['Boolean']['output'];
  items: Array<Item>;
  job?: Maybe<Job>;
  jobBoard: Array<JobList>;
  /** @deprecated Use jobBoard instead */
  jobCalendar: Array<JobCalendarEvent>;
  jobCalendarEvents: Array<JobCalendarEvent>;
  jobNameOptions: Array<Scalars['String']['output']>;
  /** @deprecated Use Query.tags instead */
  jobTags: Array<Scalars['String']['output']>;
  jobTemplates: Array<JobTemplate>;
  jobs: Array<Job>;
  location: Location;
  locations: Array<Location>;
  movementTypes: Array<Scalars['String']['output']>;
  session: Session;
  site: Site;
  siteTypes: Array<Scalars['String']['output']>;
  sites: Array<Site>;
  /** Fetch all SKUs by given IDs otherwise all Active SKUs */
  skus: Array<Sku>;
  spaceNames: Array<Scalars['String']['output']>;
  spaceSkus: Array<SpaceSku>;
  spaces: Array<Space>;
  spacesConnection: SpaceConnection;
  stockAdjustmentTypes: Array<Scalars['String']['output']>;
  stockOnHand: Array<SpaceSku>;
  tags: TagConnection;
  task: Task;
  users: Array<TenantUser>;
};

export type QueryAddItemsTaskArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAllActivityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Array<Scalars['ID']['input']>>;
  commentTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateRange?: InputMaybe<Scalars['String']['input']>;
  first?: Scalars['Int']['input'];
  flagged?: InputMaybe<Scalars['String']['input']>;
  jobTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  kind?: InputMaybe<Scalars['String']['input']>;
  locationAttributes?: InputMaybe<Array<AttributeInput>>;
  query?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<Scalars['ID']['input']>>;
  tag?: InputMaybe<Array<Scalars['String']['input']>>;
  taskTagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  unread?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAssetArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssetModelsFindByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryAssetsArgs = {
  status?: InputMaybe<Status>;
};

export type QueryAttributesArgs = {
  entityType: Scalars['String']['input'];
};

export type QueryAutomationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBackgroundJobArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContactsArgs = {
  relationship?: InputMaybe<Array<ContactRelationship>>;
  status?: InputMaybe<Array<Status>>;
};

export type QueryItemsArgs = {
  inConfig?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<Status>>;
};

export type QueryJobArgs = {
  id: Scalars['ID']['input'];
};

export type QueryJobBoardArgs = {
  asset?: Array<Scalars['ID']['input']>;
  assetType?: Array<Scalars['String']['input']>;
  assignee?: Array<Scalars['ID']['input']>;
  groupBy?: Scalars['String']['input'];
  included?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationAttributes?: Array<AttributeInput>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  locationName?: Array<Scalars['String']['input']>;
  locationOwnerId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationType?: Array<Scalars['String']['input']>;
  ofType?: InputMaybe<Scalars['String']['input']>;
  scheduleFrom?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleTo?: InputMaybe<Scalars['DateTime']['input']>;
  scheduled?: InputMaybe<Scalars['String']['input']>;
  state?: Array<Scalars['String']['input']>;
  status?: Array<JobStatus>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryJobCalendarArgs = {
  locationName?: Array<Scalars['String']['input']>;
};

export type QueryJobCalendarEventsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryJobTemplatesArgs = {
  status?: InputMaybe<Status>;
};

export type QueryJobsArgs = {
  asset?: Array<Scalars['ID']['input']>;
  assetType?: Array<Scalars['String']['input']>;
  assignee?: Array<Scalars['ID']['input']>;
  groupBy?: Scalars['String']['input'];
  included?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationAttributes?: Array<AttributeInput>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  locationName?: Array<Scalars['String']['input']>;
  locationOwnerId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationType?: Array<Scalars['String']['input']>;
  ofType?: InputMaybe<Scalars['String']['input']>;
  scheduleFrom?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleTo?: InputMaybe<Scalars['DateTime']['input']>;
  scheduled?: InputMaybe<Scalars['String']['input']>;
  state?: Array<Scalars['String']['input']>;
  status?: Array<JobStatus>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLocationsArgs = {
  status?: InputMaybe<Status>;
  subtype?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<LocationType>;
};

export type QuerySiteArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySiteTypesArgs = {
  preset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySitesArgs = {
  excludingTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySkusArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QuerySpaceSkusArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QuerySpacesArgs = {
  id: Array<Scalars['ID']['input']>;
};

export type QuerySpacesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<Filter>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QueryStockOnHandArgs = {
  filters?: InputMaybe<StockOnHandFilters>;
  location: Array<Scalars['LocationId']['input']>;
};

export type QueryTagsArgs = {
  entityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};

export enum QueuedType {
  Add = 'Add',
  Remove = 'Remove',
}

export type QuickAddInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  itemName: Scalars['String']['input'];
  itemType: ItemType;
  location: Scalars['ID']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  reorderLevel?: InputMaybe<Scalars['Int']['input']>;
  skuCode?: InputMaybe<Scalars['String']['input']>;
  spaceName: Scalars['String']['input'];
  target?: InputMaybe<Scalars['Int']['input']>;
};

export type RemoveTagsFromTaggableInput = {
  tagIds: Array<Scalars['ID']['input']>;
  taggableId: Scalars['ID']['input'];
};

export type RepeatInput = {
  byday?: InputMaybe<Array<Scalars['String']['input']>>;
  bymonthday?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  freq: Scalars['String']['input'];
  interval: Scalars['String']['input'];
  until?: InputMaybe<Scalars['String']['input']>;
};

export type RescheduleJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  offsetDays?: InputMaybe<Scalars['Int']['input']>;
};

export type RescheduleJobsInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['LocalDate']['input']>;
  endTime?: InputMaybe<Scalars['LocalTime']['input']>;
  jobIds: Array<Scalars['ID']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
  startTime?: InputMaybe<Scalars['LocalTime']['input']>;
  status?: InputMaybe<JobStatus>;
};

export type Role = {
  __typename?: 'Role';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ScheduleEvent = {
  __typename?: 'ScheduleEvent';
  assignee?: Maybe<Contact>;
  /** @deprecated Use `assignee` instead */
  assignee_id?: Maybe<Scalars['ID']['output']>;
  attributes?: Maybe<Array<Attribute>>;
  days: Scalars['Float']['output'];
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  included?: Maybe<Array<Contact>>;
  scheduledEndTime?: Maybe<Scalars['LocalTime']['output']>;
  scheduledStartTime?: Maybe<Scalars['LocalTime']['output']>;
  site_id: Scalars['ID']['output'];
  start: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type ScheduleFilters = {
  assignee?: InputMaybe<Array<Scalars['ID']['input']>>;
  included?: InputMaybe<Array<Scalars['ID']['input']>>;
  ofType?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<JobStatus>>;
  tag?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The option selected for "select stock", the method to use when picking stock */
export enum SelectStockOption {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export type Session = {
  __typename?: 'Session';
  user: User;
};

export type Site = Location & {
  __typename?: 'Site';
  activeLocations: Scalars['Int']['output'];
  address?: Maybe<Scalars['String']['output']>;
  airbnbCalendar?: Maybe<Array<CalendarSubscription>>;
  airbnbDefaultCheckIn?: Maybe<Scalars['String']['output']>;
  airbnbDefaultCheckOut?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<Attribute>>;
  calendar: Array<ScheduleEvent>;
  calendarBounds: Array<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  defaultSource?: Maybe<Site>;
  deployment?: Maybe<Deployment>;
  homhero?: Maybe<HomheroArea>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  integrationAttributes?: Maybe<Array<Attribute>>;
  jobs: JobsConnection;
  licensor?: Maybe<Organisation>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  spaces: Array<Space>;
  /** A shortcut for looking up state from site address */
  state?: Maybe<Scalars['String']['output']>;
  status: Status;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SiteCalendarArgs = {
  filters?: InputMaybe<ScheduleFilters>;
};

export type SiteJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  scheduled: Scalars['String']['input'];
};

export type SiteInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  /** List of calendar URLs to subscribe to */
  airbnbCalendar?: InputMaybe<Array<Scalars['String']['input']>>;
  airbnbDefaultCheckIn?: InputMaybe<Scalars['String']['input']>;
  airbnbDefaultCheckOut?: InputMaybe<Scalars['String']['input']>;
  attributes?: InputMaybe<Array<AttributeInput>>;
  defaultSourceId?: InputMaybe<Scalars['ID']['input']>;
  homheroId?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  licensorId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  status: Status;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Sku = {
  __typename?: 'Sku';
  _name?: Maybe<Scalars['String']['output']>;
  attributes: Array<AttributeSingle>;
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  item: Item;
  itemId: Scalars['ID']['output'];
  /** Short-cut for including item type without the full item */
  itemType: ItemType;
  name: Scalars['String']['output'];
  status: Status;
  variant: Array<VariantValue>;
};

export type Space = {
  __typename?: 'Space';
  activeSkus: Scalars['Int']['output'];
  attributes?: Maybe<Array<Attribute>>;
  createdAt: Scalars['DateTime']['output'];
  deployments: Array<Deployment>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  location: Location;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  skus: Array<SpaceSku>;
  status: Status;
  updatedAt: Scalars['DateTime']['output'];
};

export type SpaceSkusArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SpaceConnection = {
  __typename?: 'SpaceConnection';
  edges: Array<SpaceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SpaceEdge = {
  __typename?: 'SpaceEdge';
  cursor: Scalars['ID']['output'];
  node: Space;
};

export type SpaceInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  images: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status: Status;
};

export type SpaceSku = ISpaceSku & {
  __typename?: 'SpaceSku';
  attributes: Array<Attribute>;
  capacity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  queuedPrice?: Maybe<Scalars['Int']['output']>;
  queuedType?: Maybe<QueuedType>;
  reorderLevel?: Maybe<Scalars['Int']['output']>;
  reserved: Scalars['Int']['output'];
  sku: Sku;
  skuId: Scalars['ID']['output'];
  soh: Scalars['Int']['output'];
  space: Space;
  spaceId: Scalars['ID']['output'];
  status: Status;
  target?: Maybe<Scalars['Int']['output']>;
};

export type SpaceSkuInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  reorderLevel?: InputMaybe<Scalars['Int']['input']>;
  target?: InputMaybe<Scalars['Int']['input']>;
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type StockAdjustment = {
  __typename?: 'StockAdjustment';
  id: Scalars['ID']['output'];
};

export type StockAdjustmentInput = {
  adjustments: Array<StockSelection>;
  date: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
};

export type StockOnHandFilters = {
  itemAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  itemStatus?: InputMaybe<Array<Status>>;
  itemTypes?: InputMaybe<Array<ItemType>>;
  items?: InputMaybe<Array<Scalars['String']['input']>>;
  maxSoh?: InputMaybe<Scalars['Int']['input']>;
  minSoh?: InputMaybe<Scalars['Int']['input']>;
  spaceAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  spaces?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type StockSelection = {
  id: Scalars['CompositeKey']['input'];
  qty: Scalars['Int']['input'];
};

export type StockTransfer = Location & {
  __typename?: 'StockTransfer';
  deployment?: Maybe<Deployment>;
  destination: Location;
  /** A serialized string reference for destination location e.g. "Asset:123" */
  destinationString: Scalars['String']['output'];
  fsp: Fsp;
  id: Scalars['ID']['output'];
  items: Array<StockTransferItem>;
  name: Scalars['String']['output'];
  processQueuedChanges: Scalars['Boolean']['output'];
  rule: StockTransferRule;
  selectStock: SelectStockOption;
  source: Location;
  /** A serialized string reference for source location e.g. "Asset:123" */
  sourceString: Scalars['String']['output'];
  space: Space;
  spaces: Array<Space>;
  status: StockTransferStatus;
};

export type StockTransferItem = {
  __typename?: 'StockTransferItem';
  dispatched?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  packed?: Maybe<Scalars['Int']['output']>;
  pick: Scalars['Int']['output'];
  picked?: Maybe<Scalars['Int']['output']>;
  putAway?: Maybe<Scalars['Int']['output']>;
  received?: Maybe<Scalars['Int']['output']>;
  skuId: Scalars['ID']['output'];
  spaceId: Scalars['ID']['output'];
  stockTransferId: Scalars['ID']['output'];
};

export enum StockTransferRule {
  Capacity = 'Capacity',
  Target = 'Target',
}

export enum StockTransferStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Picked = 'Picked',
  Picking = 'Picking',
  Received = 'Received',
  Requested = 'Requested',
}

export type Stocktake = {
  __typename?: 'Stocktake';
  id: Scalars['ID']['output'];
  /** Item Types to include in the Stocktake */
  include: Array<ItemType>;
  /** Item Status to include in the Stocktake */
  itemStatus: Array<Status>;
  items: Array<StocktakeItem>;
  /** Space names to include in the Stocktake */
  spaces: Array<Scalars['String']['output']>;
};

export type StocktakeInput = {
  adjustments: Array<StockSelection>;
  date: Scalars['DateTime']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type StocktakeItem = {
  __typename?: 'StocktakeItem';
  adjustment: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  newOnHand: Scalars['Int']['output'];
  startOnHand: Scalars['Int']['output'];
};

export type StocktakeTaskInput = {
  include?: InputMaybe<Array<ItemType>>;
  itemStatus?: InputMaybe<Array<Status>>;
  spaces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Tag = {
  __typename?: 'Tag';
  category?: Maybe<Scalars['String']['output']>;
  colour: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entityType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TagConnection = {
  __typename?: 'TagConnection';
  edges: Array<TagEdge>;
  nodes: Array<Tag>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TagEdge = {
  __typename?: 'TagEdge';
  cursor: Scalars['ID']['output'];
  node: Tag;
};

export type Taggable = {
  tags: TagConnection;
};

export type Task = Taggable & {
  __typename?: 'Task';
  assets: Array<Asset>;
  attachments?: Maybe<Array<Attachment>>;
  attribute?: Maybe<AttributeTask>;
  /** Attributes to display on the task (for reading only) */
  attributes?: Maybe<Array<Attribute>>;
  config?: Maybe<AssetTaskConfig>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated See Task.attachments which will replace this */
  images?: Maybe<Array<Scalars['String']['output']>>;
  job: Job;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  purchase?: Maybe<PurchaseTask>;
  required?: Maybe<Scalars['Boolean']['output']>;
  status: JobStatus;
  stockTransfer?: Maybe<StockTransfer>;
  stocktake?: Maybe<Stocktake>;
  tags: TagConnection;
  transfer?: Maybe<TransferTask>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TaskInput = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  asset?: InputMaybe<AssetTaskInput>;
  attribute?: InputMaybe<AttributeTaskInput>;
  /** ID of attributes to display on the task */
  attributes?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  item?: InputMaybe<ItemTaskInput>;
  name: Scalars['String']['input'];
  purchase?: InputMaybe<PurchaseTaskInput>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  stocktake?: InputMaybe<StocktakeTaskInput>;
  /** IDs of tasks to set on this task */
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  transfer?: InputMaybe<TransferTaskInput>;
  type: Scalars['String']['input'];
};

export type TenantUser = Invitation | User;

export type Thread = {
  __typename?: 'Thread';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lastAuthor: User;
  lastMessage: Scalars['String']['output'];
  lastUpdate: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export type TransferTask = {
  __typename?: 'TransferTask';
  available: Array<ItemQty>;
  moved: Array<ItemQty>;
  stockOnHand: Array<SpaceSku>;
};

export type TransferTaskInput = {
  from: LocationSelection;
  include?: InputMaybe<Array<ItemType>>;
  to: LocationSelection;
};

export type UpdateJobInput = {
  assignee?: InputMaybe<Array<Scalars['ID']['input']>>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  /** A message to include with the notification */
  comment?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  enforceOrder?: InputMaybe<Scalars['Boolean']['input']>;
  guestyCleaningStatus?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  includePdf?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<Array<Scalars['ID']['input']>>;
  scheduledEndDate?: InputMaybe<Scalars['LocalDate']['input']>;
  scheduledEndStrict: Scalars['String']['input'];
  scheduledEndTime?: InputMaybe<Scalars['LocalTime']['input']>;
  scheduledStartDate?: InputMaybe<Scalars['LocalDate']['input']>;
  scheduledStartStrict: Scalars['String']['input'];
  scheduledStartTime?: InputMaybe<Scalars['LocalTime']['input']>;
  sendMeACopy?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<JobStatus>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  tasks: Array<TaskInput>;
};

export type UpdateTagInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  colour?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** ID of the tag to be updated */
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Upload = {
  __typename?: 'Upload';
  baseUrl: Scalars['String']['output'];
  ext: Scalars['String']['output'];
  id: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  attributes?: Maybe<Array<Attribute>>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  roles: Array<Role>;
  status: Status;
};

export type VariantInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
};

export type VariantType = {
  __typename?: 'VariantType';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type VariantValue = {
  __typename?: 'VariantValue';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContactFieldsFragment = {
  __typename?: 'Contact';
  id: string;
  status: Status;
  type: ContactType;
  name: string;
  email?: string | null;
  phone?: string | null;
  relationship?: Array<ContactRelationship> | null;
  image?: string | null;
  isCurrentUser: boolean;
  isTeamMember: boolean;
};

export type JobCalendarEventFieldsFragment = {
  __typename?: 'JobCalendarEvent';
  id: string;
  start: any;
  end?: any | null;
  timeZone: string;
  summary: string;
  url?: string | null;
  icon?: string | null;
  cancelled: boolean;
  hidden: boolean;
  location?:
    | { __typename: 'Asset'; id: string; name: string }
    | { __typename: 'Contact'; id: string; name: string }
    | { __typename: 'Elsewhere'; id: string; name: string }
    | { __typename: 'Fsp'; id: string; name: string }
    | { __typename: 'Site'; id: string; name: string }
    | { __typename: 'StockTransfer'; id: string; name: string }
    | null;
  attributes?: Array<{
    __typename?: 'Attribute';
    name: string;
    value: Array<string>;
  }> | null;
  jobs: Array<{
    __typename: 'Job';
    id: string;
    status: JobStatus;
    name: string;
    createdAt: any;
    scheduleStart?: any | null;
    scheduleEnd?: any | null;
    scheduledStartStrict: boolean;
    scheduledStartDate?: any | null;
    scheduledStartTime?: any | null;
    scheduledEndStrict: boolean;
    scheduledEndDate?: any | null;
    scheduledEndTime?: any | null;
    completedAt?: any | null;
    timeZone: string;
    image?: string | null;
    tags?: Array<string> | null;
    location?:
      | { __typename: 'Asset'; id: string; name: string }
      | { __typename: 'Contact'; id: string; name: string }
      | { __typename: 'Elsewhere'; id: string; name: string }
      | { __typename: 'Fsp'; id: string; name: string }
      | { __typename: 'Site'; id: string; name: string }
      | { __typename: 'StockTransfer'; id: string; name: string }
      | null;
    assignee?: {
      __typename?: 'Contact';
      id: string;
      name: string;
      image?: string | null;
    } | null;
  }>;
};

type JobFeedFields_Comment_Fragment = {
  __typename: 'Comment';
  id: string;
  body?: string | null;
  createdAt: any;
  isRead: boolean;
  isFlagged: boolean;
  createdBy?: { __typename?: 'User'; id: string; name: string } | null;
  attachments?: Array<{
    __typename?: 'Attachment';
    id: string;
    kind: string;
    thumb: string;
    url: string;
    originalFilename: string;
  }> | null;
};

type JobFeedFields_JobHistory_Fragment = {
  __typename: 'JobHistory';
  id: string;
  taskId?: string | null;
  status: JobStatus;
  inputDate?: any | null;
  createdAt: any;
  createdBy?: {
    __typename?: 'User';
    id: string;
    image?: string | null;
    name: string;
  } | null;
};

export type JobFeedFieldsFragment =
  | JobFeedFields_Comment_Fragment
  | JobFeedFields_JobHistory_Fragment;

export type JobTemplateFieldsFragment = {
  __typename?: 'JobTemplate';
  id: string;
  isStarter: boolean;
  status: Status;
  name: string;
  notes?: string | null;
  tasks: Array<{
    __typename?: 'Task';
    id: string;
    type: string;
    name: string;
    description: string;
    attributes?: Array<{
      __typename?: 'Attribute';
      id: string;
      type: string;
      category?: string | null;
      name: string;
      value: Array<string>;
    }> | null;
    config?: {
      __typename?: 'AssetTaskConfig';
      selection: string;
      spaces: Array<string>;
      assetTypes: Array<string>;
      allow: Array<string>;
    } | null;
    stocktake?: {
      __typename?: 'Stocktake';
      id: string;
      include: Array<ItemType>;
      itemStatus: Array<Status>;
      spaces: Array<string>;
      items: Array<{
        __typename?: 'StocktakeItem';
        id: string;
        adjustment: number;
        newOnHand: number;
      }>;
    } | null;
  }>;
};

export type SpaceFieldsFragment = {
  __typename?: 'Space';
  id: string;
  status: Status;
  name: string;
  activeSkus: number;
  image?: string | null;
  attributes?: Array<{
    __typename?: 'Attribute';
    name: string;
    value: Array<string>;
  }> | null;
  location:
    | { __typename: 'Asset'; id: string; name: string }
    | { __typename: 'Contact'; id: string; name: string }
    | { __typename: 'Elsewhere'; id: string; name: string }
    | { __typename: 'Fsp'; id: string; name: string }
    | { __typename: 'Site'; id: string; name: string }
    | { __typename: 'StockTransfer'; id: string; name: string };
};

export type SpaceToSkuFragment = {
  __typename?: 'Space';
  id: string;
  name: string;
  skus: Array<{
    __typename?: 'SpaceSku';
    id: string;
    spaceId: string;
    skuId: string;
    queuedType?: QueuedType | null;
    soh: number;
    price?: number | null;
    capacity?: number | null;
    target?: number | null;
    queuedPrice?: number | null;
  }>;
};

export type StocktakeTaskFieldsFragment = {
  __typename?: 'Stocktake';
  id: string;
  include: Array<ItemType>;
  itemStatus: Array<Status>;
  spaces: Array<string>;
  items: Array<{
    __typename?: 'StocktakeItem';
    id: string;
    adjustment: number;
    newOnHand: number;
  }>;
};

export type ItemDataFragment = {
  __typename?: 'Item';
  id: string;
  status: Status;
  name: string;
  type: ItemType;
  description?: string | null;
  skuPrefix?: string | null;
  images: Array<string>;
  image?: string | null;
  activeSkus: number;
  hasVariants: boolean;
  skus: Array<{
    __typename?: 'Sku';
    id: string;
    status: Status;
    _name?: string | null;
    name: string;
    code?: string | null;
    images: Array<string>;
    image?: string | null;
    variant: Array<{
      __typename?: 'VariantValue';
      name: string;
      value: string;
    }>;
  }>;
  variantTypes: Array<{
    __typename?: 'VariantType';
    name: string;
    values: Array<string>;
  }>;
};

type SpaceSkuData_Card_Fragment = {
  __typename?: 'Card';
  id: string;
  reserved: number;
  soh: number;
  target?: number | null;
  capacity?: number | null;
  reorderLevel?: number | null;
  space: {
    __typename?: 'Space';
    id: string;
    name: string;
    location:
      | { __typename: 'Asset'; id: string; name: string }
      | { __typename: 'Contact'; id: string; name: string }
      | { __typename: 'Elsewhere'; id: string; name: string }
      | { __typename: 'Fsp'; id: string; name: string }
      | { __typename: 'Site'; id: string; name: string }
      | { __typename: 'StockTransfer'; id: string; name: string };
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
  };
  sku: {
    __typename?: 'Sku';
    id: string;
    code?: string | null;
    name: string;
    itemType: ItemType;
    image?: string | null;
    attributes: Array<{
      __typename?: 'AttributeSingle';
      name: string;
      value: string;
    }>;
  };
};

type SpaceSkuData_SpaceSku_Fragment = {
  __typename?: 'SpaceSku';
  id: string;
  reserved: number;
  soh: number;
  target?: number | null;
  capacity?: number | null;
  reorderLevel?: number | null;
  space: {
    __typename?: 'Space';
    id: string;
    name: string;
    location:
      | { __typename: 'Asset'; id: string; name: string }
      | { __typename: 'Contact'; id: string; name: string }
      | { __typename: 'Elsewhere'; id: string; name: string }
      | { __typename: 'Fsp'; id: string; name: string }
      | { __typename: 'Site'; id: string; name: string }
      | { __typename: 'StockTransfer'; id: string; name: string };
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
  };
  sku: {
    __typename?: 'Sku';
    id: string;
    code?: string | null;
    name: string;
    itemType: ItemType;
    image?: string | null;
    attributes: Array<{
      __typename?: 'AttributeSingle';
      name: string;
      value: string;
    }>;
  };
};

export type SpaceSkuDataFragment =
  | SpaceSkuData_Card_Fragment
  | SpaceSkuData_SpaceSku_Fragment;

export type AddItemMutationVariables = Exact<{
  input: ItemInput;
}>;

export type AddItemMutation = {
  __typename?: 'Mutation';
  addItem: {
    __typename?: 'Item';
    id: string;
    status: Status;
    name: string;
    type: ItemType;
    description?: string | null;
    skuPrefix?: string | null;
    images: Array<string>;
    image?: string | null;
    activeSkus: number;
    hasVariants: boolean;
    skus: Array<{
      __typename?: 'Sku';
      id: string;
      status: Status;
      _name?: string | null;
      name: string;
      code?: string | null;
      images: Array<string>;
      image?: string | null;
      variant: Array<{
        __typename?: 'VariantValue';
        name: string;
        value: string;
      }>;
    }>;
    variantTypes: Array<{
      __typename?: 'VariantType';
      name: string;
      values: Array<string>;
    }>;
  };
};

export type AdjustStockMutationVariables = Exact<{
  input: StockAdjustmentInput;
}>;

export type AdjustStockMutation = {
  __typename?: 'Mutation';
  adjustStock: { __typename?: 'StockAdjustment'; id: string };
};

export type CompleteCustomTaskMutationVariables = Exact<{
  input: CompleteTaskInput;
}>;

export type CompleteCustomTaskMutation = {
  __typename?: 'Mutation';
  completeCustomTask: {
    __typename?: 'Task';
    id: string;
    status: JobStatus;
    updatedAt: any;
    job: { __typename?: 'Job'; id: string; status: JobStatus };
  };
};

export type CompleteItemTaskMutationVariables = Exact<{
  input: CompleteItemTaskInput;
}>;

export type CompleteItemTaskMutation = {
  __typename?: 'Mutation';
  completeItemTask: {
    __typename?: 'Task';
    id: string;
    status: JobStatus;
    updatedAt: any;
    job: { __typename?: 'Job'; id: string; status: JobStatus };
  };
};

export type CreateContactMutationVariables = Exact<{
  input: ContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact?: {
    __typename?: 'Contact';
    id: string;
    status: Status;
    type: ContactType;
    name: string;
    email?: string | null;
    phone?: string | null;
    relationship?: Array<ContactRelationship> | null;
    image?: string | null;
    isCurrentUser: boolean;
    isTeamMember: boolean;
  } | null;
};

export type CreateDeploymentMutationVariables = Exact<{
  input: NewDeploymentInput;
}>;

export type CreateDeploymentMutation = {
  __typename?: 'Mutation';
  createDeployment: { __typename?: 'Deployment'; id: string };
};

export type CreateUploadMutationVariables = Exact<{
  file: FileInput;
}>;

export type CreateUploadMutation = {
  __typename?: 'Mutation';
  createUpload: {
    __typename?: 'Upload';
    id: string;
    ext: string;
    presignedUrl: string;
    baseUrl: string;
    originalFilename: string;
  };
};

export type FinishStocktakeMutationVariables = Exact<{
  input: StocktakeInput;
}>;

export type FinishStocktakeMutation = {
  __typename?: 'Mutation';
  finishStocktake: number;
};

export type GeneratePicksMutationVariables = Exact<{
  stockTransferId: Scalars['Int']['input'];
}>;

export type GeneratePicksMutation = {
  __typename?: 'Mutation';
  generatePicks: string;
};

export type QuickAddMutationVariables = Exact<{
  input: QuickAddInput;
}>;

export type QuickAddMutation = {
  __typename?: 'Mutation';
  quickAdd: {
    __typename?: 'SpaceSku';
    id: string;
    soh: number;
    price?: number | null;
    capacity?: number | null;
    target?: number | null;
  };
};

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact?: {
    __typename?: 'Contact';
    id: string;
    status: Status;
    type: ContactType;
    name: string;
    relationship?: Array<ContactRelationship> | null;
    phone?: string | null;
    email?: string | null;
    streetAddress?: string | null;
    postalAddress?: string | null;
    notes?: string | null;
    images: Array<string>;
    organisation?: { __typename?: 'Contact'; id: string; name: string } | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
  } | null;
};

export type UpdateItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ItemInput;
}>;

export type UpdateItemMutation = {
  __typename?: 'Mutation';
  updateItem: {
    __typename?: 'Item';
    id: string;
    status: Status;
    name: string;
    type: ItemType;
    description?: string | null;
    skuPrefix?: string | null;
    images: Array<string>;
    image?: string | null;
    activeSkus: number;
    hasVariants: boolean;
    skus: Array<{
      __typename?: 'Sku';
      id: string;
      status: Status;
      _name?: string | null;
      name: string;
      code?: string | null;
      images: Array<string>;
      image?: string | null;
      variant: Array<{
        __typename?: 'VariantValue';
        name: string;
        value: string;
      }>;
    }>;
    variantTypes: Array<{
      __typename?: 'VariantType';
      name: string;
      values: Array<string>;
    }>;
  };
};

export type UpdateSpaceSkuMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SpaceSkuInput;
}>;

export type UpdateSpaceSkuMutation = {
  __typename?: 'Mutation';
  updateSpaceSku: {
    __typename?: 'SpaceSku';
    id: string;
    status: Status;
    soh: number;
    reserved: number;
    price?: number | null;
    capacity?: number | null;
    target?: number | null;
    reorderLevel?: number | null;
    space: { __typename?: 'Space'; id: string; name: string };
    sku: { __typename?: 'Sku'; id: string; name: string };
    attributes: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }>;
  };
};

export type CreateAssetMutationVariables = Exact<{
  input: AssetInput;
}>;

export type CreateAssetMutation = {
  __typename?: 'Mutation';
  createAsset: {
    __typename?: 'Asset';
    id: string;
    status: Status;
    name: string;
    type: string;
    notes?: string | null;
    image?: string | null;
    images: Array<string>;
    attachments: Array<{
      __typename?: 'Attachment';
      id: string;
      url: string;
      originalFilename: string;
    }>;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
    model?: {
      __typename?: 'AssetModel';
      id: string;
      name: string;
      brand: string;
      type: string;
      displayName: string;
    } | null;
  };
};

export type CreateAssetModelMutationVariables = Exact<{
  input: AssetModelInput;
}>;

export type CreateAssetModelMutation = {
  __typename?: 'Mutation';
  createAssetModel: {
    __typename?: 'AssetModel';
    id: string;
    status: Status;
    name: string;
    displayName: string;
    type: string;
    notes?: string | null;
    activeAssets?: number | null;
    image?: string | null;
  };
};

export type UpdateAssetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: AssetInput;
}>;

export type UpdateAssetMutation = {
  __typename?: 'Mutation';
  updateAsset: {
    __typename?: 'Asset';
    id: string;
    status: Status;
    name: string;
    type: string;
    notes?: string | null;
    image?: string | null;
    images: Array<string>;
    attachments: Array<{
      __typename?: 'Attachment';
      id: string;
      url: string;
      originalFilename: string;
    }>;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
    model?: {
      __typename?: 'AssetModel';
      id: string;
      name: string;
      brand: string;
      type: string;
      displayName: string;
    } | null;
  };
};

export type UpdateAssetModelMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: AssetModelInput;
}>;

export type UpdateAssetModelMutation = {
  __typename?: 'Mutation';
  updateAssetModel: {
    __typename?: 'AssetModel';
    id: string;
    status: Status;
    name: string;
    displayName: string;
    type: string;
    notes?: string | null;
    activeAssets?: number | null;
    image?: string | null;
  };
};

export type AcceptJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;

export type AcceptJobMutation = {
  __typename?: 'Mutation';
  acceptJob?: { __typename?: 'Job'; id: string; status: JobStatus } | null;
};

export type CancelJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;

export type CancelJobMutation = {
  __typename?: 'Mutation';
  cancelJob?: {
    __typename?: 'Job';
    id: string;
    status: JobStatus;
    permissions: Array<string>;
    comments: Array<
      | {
          __typename: 'Comment';
          id: string;
          body?: string | null;
          createdAt: any;
          isRead: boolean;
          isFlagged: boolean;
          createdBy?: { __typename?: 'User'; id: string; name: string } | null;
          attachments?: Array<{
            __typename?: 'Attachment';
            id: string;
            kind: string;
            thumb: string;
            url: string;
            originalFilename: string;
          }> | null;
        }
      | {
          __typename: 'JobHistory';
          id: string;
          taskId?: string | null;
          status: JobStatus;
          inputDate?: any | null;
          createdAt: any;
          createdBy?: {
            __typename?: 'User';
            id: string;
            image?: string | null;
            name: string;
          } | null;
        }
    >;
  } | null;
};

export type CompleteJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  endTime: Scalars['DateTime']['input'];
}>;

export type CompleteJobMutation = {
  __typename?: 'Mutation';
  completeJob?: { __typename?: 'Job'; id: string; status: JobStatus } | null;
};

export type DeclineJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeclineJobMutation = {
  __typename?: 'Mutation';
  declineJob?: { __typename?: 'Job'; id: string; status: JobStatus } | null;
};

export type DeleteJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteJobMutation = { __typename?: 'Mutation'; deleteJob: number };

export type EndTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  endTime: Scalars['DateTime']['input'];
}>;

export type EndTaskMutation = {
  __typename?: 'Mutation';
  endTask?: { __typename?: 'Task'; id: string; status: JobStatus } | null;
};

export type OfferJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;

export type OfferJobMutation = {
  __typename?: 'Mutation';
  offerJob?: {
    __typename?: 'Job';
    id: string;
    status: JobStatus;
    comments: Array<
      | {
          __typename: 'Comment';
          id: string;
          body?: string | null;
          createdAt: any;
          isRead: boolean;
          isFlagged: boolean;
          createdBy?: { __typename?: 'User'; id: string; name: string } | null;
          attachments?: Array<{
            __typename?: 'Attachment';
            id: string;
            kind: string;
            thumb: string;
            url: string;
            originalFilename: string;
          }> | null;
        }
      | {
          __typename: 'JobHistory';
          id: string;
          taskId?: string | null;
          status: JobStatus;
          inputDate?: any | null;
          createdAt: any;
          createdBy?: {
            __typename?: 'User';
            id: string;
            image?: string | null;
            name: string;
          } | null;
        }
    >;
  } | null;
};

export type PauseTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  pauseTime: Scalars['DateTime']['input'];
}>;

export type PauseTaskMutation = {
  __typename?: 'Mutation';
  pauseTask?: { __typename?: 'Task'; id: string; status: JobStatus } | null;
};

export type ResetTaskStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResetTaskStatusMutation = {
  __typename?: 'Mutation';
  resetTaskStatus?: {
    __typename?: 'Task';
    id: string;
    status: JobStatus;
  } | null;
};

export type ResumeTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  resumeTime: Scalars['DateTime']['input'];
}>;

export type ResumeTaskMutation = {
  __typename?: 'Mutation';
  resumeTask?: { __typename?: 'Task'; id: string; status: JobStatus } | null;
};

export type SortJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  sort: Scalars['String']['input'];
  rel: Scalars['ID']['input'];
}>;

export type SortJobMutation = { __typename?: 'Mutation'; sortJob: boolean };

export type StartJobMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
}>;

export type StartJobMutation = {
  __typename?: 'Mutation';
  startJob?: { __typename?: 'Job'; id: string; status: JobStatus } | null;
};

export type StartTaskMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
}>;

export type StartTaskMutation = {
  __typename?: 'Mutation';
  startTask?: { __typename?: 'Task'; id: string; status: JobStatus } | null;
};

export type OnboardingMutationVariables = Exact<{
  version: Scalars['ID']['input'];
  step: Scalars['Int']['input'];
  input?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnboardingMutation = {
  __typename?: 'Mutation';
  onboard: {
    __typename?: 'Onboarding';
    id: string;
    form: string;
    version: number;
    step: number;
    hasNextStep: boolean;
    waitingFor?: string | null;
  };
};

export type CreateSiteMutationVariables = Exact<{
  input: SiteInput;
}>;

export type CreateSiteMutation = {
  __typename?: 'Mutation';
  createSite: {
    __typename?: 'Site';
    id: string;
    status: Status;
    type?: string | null;
    name: string;
    address?: string | null;
    state?: string | null;
    image?: string | null;
    activeLocations: number;
    attributes?: Array<{
      __typename?: 'Attribute';
      id: string;
      type: string;
      category?: string | null;
      name: string;
      value: Array<string>;
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        kind: string;
        thumb: string;
        url: string;
        originalFilename: string;
      }> | null;
    }> | null;
  };
};

export type UpdateSiteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SiteInput;
}>;

export type UpdateSiteMutation = {
  __typename?: 'Mutation';
  updateSite: {
    __typename?: 'Site';
    id: string;
    status: Status;
    type?: string | null;
    name: string;
    address?: string | null;
    state?: string | null;
    image?: string | null;
    activeLocations: number;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
  };
};

export type CreateSpaceMutationVariables = Exact<{
  locationId: Scalars['LocationId']['input'];
  input: SpaceInput;
}>;

export type CreateSpaceMutation = {
  __typename?: 'Mutation';
  createSpace: {
    __typename?: 'Space';
    id: string;
    status: Status;
    name: string;
    activeSkus: number;
    image?: string | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
    location:
      | { __typename: 'Asset'; id: string; name: string }
      | { __typename: 'Contact'; id: string; name: string }
      | { __typename: 'Elsewhere'; id: string; name: string }
      | { __typename: 'Fsp'; id: string; name: string }
      | { __typename: 'Site'; id: string; name: string }
      | { __typename: 'StockTransfer'; id: string; name: string };
  };
};

export type UpdateSpaceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SpaceInput;
}>;

export type UpdateSpaceMutation = {
  __typename?: 'Mutation';
  updateSpace: {
    __typename?: 'Space';
    id: string;
    status: Status;
    name: string;
    activeSkus: number;
    image?: string | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
    location:
      | { __typename: 'Asset'; id: string; name: string }
      | { __typename: 'Contact'; id: string; name: string }
      | { __typename: 'Elsewhere'; id: string; name: string }
      | { __typename: 'Fsp'; id: string; name: string }
      | { __typename: 'Site'; id: string; name: string }
      | { __typename: 'StockTransfer'; id: string; name: string };
  };
};

export type CreateTenantMutationVariables = Exact<{
  input: AccountProfileInput;
}>;

export type CreateTenantMutation = {
  __typename?: 'Mutation';
  createTenant: string;
};

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteUser: { __typename?: 'Invitation'; id: string; email: string };
};

export type RemoveUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type RemoveUserMutation = {
  __typename?: 'Mutation';
  removeUser: boolean;
};

export type UpdateAccountProfileMutationVariables = Exact<{
  input: AccountProfileInput;
}>;

export type UpdateAccountProfileMutation = {
  __typename?: 'Mutation';
  updateAccountProfile: boolean;
};

export type ChangeEmailMutationVariables = Exact<{
  input: ChangeEmailInput;
}>;

export type ChangeEmailMutation = {
  __typename?: 'Mutation';
  changeEmailAddress: number;
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword: number;
};

export type PatchOrganisationProfileMutationVariables = Exact<{
  input: OrganisationProfileInput;
}>;

export type PatchOrganisationProfileMutation = {
  __typename?: 'Mutation';
  patchOrganisationProfile: number;
};

export type SaveNotificationPreferencesMutationVariables = Exact<{
  input: NotificationSettingInput;
}>;

export type SaveNotificationPreferencesMutation = {
  __typename?: 'Mutation';
  saveNotificationPreferences: number;
};

export type SetProfilePicMutationVariables = Exact<{
  image: Scalars['String']['input'];
}>;

export type SetProfilePicMutation = {
  __typename?: 'Mutation';
  setProfilePic: { __typename?: 'User'; id: string; image?: string | null };
};

export type UpdatePersonalInfoMutationVariables = Exact<{
  input: PersonalInfoInput;
}>;

export type UpdatePersonalInfoMutation = {
  __typename?: 'Mutation';
  updatePersonalInfo: number;
};

export type AddItemsTaskQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AddItemsTaskQuery = {
  __typename?: 'Query';
  addItemsTask: {
    __typename?: 'ItemTask';
    id: string;
    type: string;
    status: JobStatus;
    cards: Array<{
      __typename?: 'Card';
      pick?: number | null;
      picked?: number | null;
      received?: number | null;
      putAway?: number | null;
      id: string;
      reserved: number;
      soh: number;
      target?: number | null;
      capacity?: number | null;
      reorderLevel?: number | null;
      space: {
        __typename?: 'Space';
        id: string;
        name: string;
        location:
          | { __typename: 'Asset'; id: string; name: string }
          | { __typename: 'Contact'; id: string; name: string }
          | { __typename: 'Elsewhere'; id: string; name: string }
          | { __typename: 'Fsp'; id: string; name: string }
          | { __typename: 'Site'; id: string; name: string }
          | { __typename: 'StockTransfer'; id: string; name: string };
        attributes?: Array<{
          __typename?: 'Attribute';
          name: string;
          value: Array<string>;
        }> | null;
      };
      sku: {
        __typename?: 'Sku';
        id: string;
        code?: string | null;
        name: string;
        itemType: ItemType;
        image?: string | null;
        attributes: Array<{
          __typename?: 'AttributeSingle';
          name: string;
          value: string;
        }>;
      };
    }>;
    stockTransfer: {
      __typename?: 'StockTransfer';
      id: string;
      rule: StockTransferRule;
      selectStock: SelectStockOption;
      processQueuedChanges: boolean;
      source:
        | { __typename: 'Asset'; id: string; name: string }
        | { __typename: 'Contact'; id: string; name: string }
        | { __typename: 'Elsewhere'; id: string; name: string }
        | { __typename: 'Fsp'; id: string; name: string }
        | { __typename: 'Site'; id: string; name: string }
        | { __typename: 'StockTransfer'; id: string; name: string };
      destination:
        | { __typename: 'Asset'; id: string; name: string }
        | { __typename: 'Contact'; id: string; name: string }
        | { __typename: 'Elsewhere'; id: string; name: string }
        | { __typename: 'Fsp'; id: string; name: string }
        | { __typename: 'Site'; id: string; name: string }
        | { __typename: 'StockTransfer'; id: string; name: string };
      items: Array<{
        __typename?: 'StockTransferItem';
        id: string;
        spaceId: string;
        skuId: string;
        pick: number;
        picked?: number | null;
        packed?: number | null;
        dispatched?: number | null;
        received?: number | null;
        putAway?: number | null;
      }>;
    };
  };
};

export type AssetFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type AssetFiltersQuery = {
  __typename?: 'Query';
  assetTypes: Array<string>;
  assetModels: Array<{
    __typename?: 'AssetModel';
    id: string;
    type: string;
    value: string;
    label: string;
  }>;
};

export type AssetsQueryVariables = Exact<{ [key: string]: never }>;

export type AssetsQuery = {
  __typename?: 'Query';
  assets: Array<{
    __typename?: 'Asset';
    id: string;
    status: Status;
    name: string;
    type: string;
    notes?: string | null;
    image?: string | null;
    images: Array<string>;
    attachments: Array<{
      __typename?: 'Attachment';
      id: string;
      ext: string;
      url: string;
      originalFilename: string;
    }>;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
    deployment?: {
      __typename?: 'Deployment';
      id: string;
      displayLocation: string;
      startedAt: any;
      image?: string | null;
      location:
        | { __typename: 'Asset'; id: string; name: string }
        | { __typename: 'Contact'; id: string; name: string }
        | { __typename: 'Elsewhere'; id: string; name: string }
        | { __typename: 'Fsp'; id: string; name: string }
        | { __typename: 'Site'; id: string; name: string }
        | { __typename: 'StockTransfer'; id: string; name: string };
      space: { __typename?: 'Space'; id: string; name: string };
    } | null;
    model?: {
      __typename?: 'AssetModel';
      id: string;
      name: string;
      brand: string;
      type: string;
      displayName: string;
    } | null;
  }>;
};

export type AttributesQueryVariables = Exact<{
  entityType: Scalars['String']['input'];
}>;

export type AttributesQuery = {
  __typename?: 'Query';
  attributes: Array<{
    __typename?: 'Attribute';
    name: string;
    value: Array<string>;
  }>;
};

export type BackgroundJobQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BackgroundJobQuery = {
  __typename?: 'Query';
  backgroundJob: string;
};

export type ContactQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ContactQuery = {
  __typename?: 'Query';
  contact?: {
    __typename?: 'Contact';
    id: string;
    status: Status;
    type: ContactType;
    name: string;
    relationship?: Array<ContactRelationship> | null;
    phone?: string | null;
    email?: string | null;
    streetAddress?: string | null;
    postalAddress?: string | null;
    notes?: string | null;
    image?: string | null;
    images: Array<string>;
    isCurrentUser: boolean;
    organisation?: { __typename?: 'Contact'; id: string; name: string } | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
  } | null;
};

export type ContactsQueryVariables = Exact<{
  status?: InputMaybe<Array<Status> | Status>;
  relationship?: InputMaybe<Array<ContactRelationship> | ContactRelationship>;
}>;

export type ContactsQuery = {
  __typename?: 'Query';
  contacts: Array<{
    __typename?: 'Contact';
    id: string;
    status: Status;
    type: ContactType;
    name: string;
    email?: string | null;
    phone?: string | null;
    relationship?: Array<ContactRelationship> | null;
    image?: string | null;
    isCurrentUser: boolean;
    isTeamMember: boolean;
  }>;
};

export type DeploymentFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type DeploymentFiltersQuery = {
  __typename?: 'Query';
  assetTypes: Array<string>;
};

export type DeploymentsQueryVariables = Exact<{ [key: string]: never }>;

export type DeploymentsQuery = {
  __typename?: 'Query';
  deployments: Array<{
    __typename?: 'Deployment';
    id: string;
    status: Status;
    startedAt: any;
    endedAt?: any | null;
    image?: string | null;
    images: Array<string>;
    displayLocation: string;
    state?: string | null;
    owner?: string | null;
    asset: {
      __typename?: 'Asset';
      id: string;
      name: string;
      type: string;
      model?: {
        __typename?: 'AssetModel';
        id: string;
        displayName: string;
      } | null;
    };
    location:
      | { __typename: 'Asset'; id: string; name: string }
      | { __typename: 'Contact'; id: string; name: string }
      | { __typename: 'Elsewhere'; id: string; name: string }
      | { __typename: 'Fsp'; id: string; name: string }
      | { __typename: 'Site'; id: string; name: string }
      | { __typename: 'StockTransfer'; id: string; name: string };
    space: { __typename?: 'Space'; id: string; name: string };
  }>;
};

export type ItemsQueryVariables = Exact<{
  status?: InputMaybe<Array<Status> | Status>;
  inConfig?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ItemsQuery = {
  __typename?: 'Query';
  items: Array<{
    __typename?: 'Item';
    id: string;
    status: Status;
    name: string;
    type: ItemType;
    description?: string | null;
    skuPrefix?: string | null;
    images: Array<string>;
    image?: string | null;
    activeSkus: number;
    hasVariants: boolean;
    skus: Array<{
      __typename?: 'Sku';
      id: string;
      status: Status;
      _name?: string | null;
      name: string;
      code?: string | null;
      images: Array<string>;
      image?: string | null;
      variant: Array<{
        __typename?: 'VariantValue';
        name: string;
        value: string;
      }>;
    }>;
    variantTypes: Array<{
      __typename?: 'VariantType';
      name: string;
      values: Array<string>;
    }>;
  }>;
};

export type ItemsAndVariantsQueryVariables = Exact<{ [key: string]: never }>;

export type ItemsAndVariantsQuery = {
  __typename?: 'Query';
  items: Array<{
    __typename?: 'Item';
    id: string;
    type: ItemType;
    name: string;
    description?: string | null;
  }>;
  skus: Array<{
    __typename?: 'Sku';
    id: string;
    code?: string | null;
    name: string;
    itemId: string;
  }>;
};

export type JobHistoryQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
}>;

export type JobHistoryQuery = {
  __typename?: 'Query';
  job?: {
    __typename?: 'Job';
    id: string;
    history: Array<{
      __typename?: 'JobHistory';
      id: string;
      status: JobStatus;
      inputDate?: any | null;
      createdAt: any;
      task?: { __typename?: 'Task'; id: string; name: string } | null;
      createdBy?: {
        __typename?: 'User';
        id: string;
        image?: string | null;
        name: string;
      } | null;
    }>;
  } | null;
};

export type JobsQueryVariables = Exact<{
  status?: InputMaybe<Array<JobStatus> | JobStatus>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  locationType?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  locationName?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  assetType?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  asset?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  assignee?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  state?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  scheduleFrom?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleTo?: InputMaybe<Scalars['DateTime']['input']>;
  groupBy?: InputMaybe<Scalars['String']['input']>;
}>;

export type JobsQuery = {
  __typename?: 'Query';
  assetTypes: Array<string>;
  assignees: Array<{ __typename?: 'Contact'; id: string; name: string }>;
  jobCalendar: Array<{
    __typename?: 'JobCalendarEvent';
    id: string;
    date: any;
    summary: string;
    url?: string | null;
    location?:
      | { __typename?: 'Asset'; id: string; name: string }
      | { __typename?: 'Contact'; id: string; name: string }
      | { __typename?: 'Elsewhere'; id: string; name: string }
      | { __typename?: 'Fsp'; id: string; name: string }
      | { __typename?: 'Site'; id: string; name: string }
      | { __typename?: 'StockTransfer'; id: string; name: string }
      | null;
  }>;
  jobs: Array<{
    __typename?: 'Job';
    _dateCreated: string;
    _dateScheduled?: string | null;
    id: string;
    jobNumber: string;
    status: JobStatus;
    name: string;
    deploymentString?: string | null;
    state?: string | null;
    timeZone: string;
    scheduleStart?: any | null;
    scheduleEnd?: any | null;
    scheduledStartStrict: boolean;
    scheduledStartDate?: any | null;
    scheduledStartTime?: any | null;
    scheduledEndStrict: boolean;
    scheduledEndDate?: any | null;
    scheduledEndTime?: any | null;
    completedAt?: any | null;
    createdAt: any;
    updatedAt: any;
    isSource: boolean;
    image?: string | null;
    permissions: Array<string>;
    location?:
      | {
          __typename: 'Asset';
          id: string;
          name: string;
          deployment?: {
            __typename?: 'Deployment';
            id: string;
            displayLocation: string;
            state?: string | null;
            image?: string | null;
          } | null;
        }
      | {
          __typename: 'Contact';
          id: string;
          name: string;
          deployment?: {
            __typename?: 'Deployment';
            id: string;
            displayLocation: string;
            state?: string | null;
            image?: string | null;
          } | null;
        }
      | {
          __typename: 'Elsewhere';
          id: string;
          name: string;
          deployment?: {
            __typename?: 'Deployment';
            id: string;
            displayLocation: string;
            state?: string | null;
            image?: string | null;
          } | null;
        }
      | {
          __typename: 'Fsp';
          id: string;
          name: string;
          deployment?: {
            __typename?: 'Deployment';
            id: string;
            displayLocation: string;
            state?: string | null;
            image?: string | null;
          } | null;
        }
      | {
          __typename: 'Site';
          id: string;
          name: string;
          deployment?: {
            __typename?: 'Deployment';
            id: string;
            displayLocation: string;
            state?: string | null;
            image?: string | null;
          } | null;
        }
      | {
          __typename: 'StockTransfer';
          id: string;
          name: string;
          deployment?: {
            __typename?: 'Deployment';
            id: string;
            displayLocation: string;
            state?: string | null;
            image?: string | null;
          } | null;
        }
      | null;
    tasks: Array<{
      __typename?: 'Task';
      id: string;
      type: string;
      name: string;
    }>;
    assignee?: {
      __typename?: 'Contact';
      id: string;
      name: string;
      image?: string | null;
    } | null;
    owner: {
      __typename?: 'Organisation';
      id: string;
      name: string;
      image?: string | null;
    };
  }>;
};

export type LocationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type LocationQuery = {
  __typename?: 'Query';
  location:
    | {
        __typename: 'Asset';
        id: string;
        name: string;
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          status: Status;
          name: string;
          skus: Array<{ __typename?: 'SpaceSku'; id: string; skuId: string }>;
        }>;
      }
    | {
        __typename: 'Contact';
        id: string;
        name: string;
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          status: Status;
          name: string;
          skus: Array<{ __typename?: 'SpaceSku'; id: string; skuId: string }>;
        }>;
      }
    | {
        __typename: 'Elsewhere';
        id: string;
        name: string;
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          status: Status;
          name: string;
          skus: Array<{ __typename?: 'SpaceSku'; id: string; skuId: string }>;
        }>;
      }
    | {
        __typename: 'Fsp';
        id: string;
        name: string;
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          status: Status;
          name: string;
          skus: Array<{ __typename?: 'SpaceSku'; id: string; skuId: string }>;
        }>;
      }
    | {
        __typename: 'Site';
        id: string;
        name: string;
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          status: Status;
          name: string;
          skus: Array<{ __typename?: 'SpaceSku'; id: string; skuId: string }>;
        }>;
      }
    | {
        __typename: 'StockTransfer';
        id: string;
        name: string;
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          status: Status;
          name: string;
          skus: Array<{ __typename?: 'SpaceSku'; id: string; skuId: string }>;
        }>;
      };
};

export type LocationsQueryVariables = Exact<{
  type?: InputMaybe<LocationType>;
  subtype?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type LocationsQuery = {
  __typename?: 'Query';
  locations: Array<
    | { __typename: 'Asset'; id: string; name: string }
    | { __typename: 'Contact'; id: string; name: string }
    | { __typename: 'Elsewhere'; id: string; name: string }
    | { __typename: 'Fsp'; id: string; name: string }
    | { __typename: 'Site'; id: string; name: string }
    | { __typename: 'StockTransfer'; id: string; name: string }
  >;
};

export type OnboardingStatusQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingStatusQuery = {
  __typename?: 'Query';
  isOnboarding: boolean;
};

export type SiteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SiteQuery = {
  __typename?: 'Query';
  integrations: Array<string>;
  site: {
    __typename?: 'Site';
    id: string;
    status: Status;
    name: string;
    type?: string | null;
    address?: string | null;
    image?: string | null;
    notes?: string | null;
    images: Array<string>;
    airbnbDefaultCheckIn?: string | null;
    airbnbDefaultCheckOut?: string | null;
    licensor?: { __typename?: 'Organisation'; id: string; name: string } | null;
    defaultSource?: {
      __typename?: 'Site';
      id: string;
      type?: string | null;
      name: string;
    } | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      id: string;
      type: string;
      category?: string | null;
      name: string;
      value: Array<string>;
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        kind: string;
        ext: string;
        thumb: string;
        url: string;
        originalFilename: string;
      }> | null;
    }> | null;
    integrationAttributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
    airbnbCalendar?: Array<{
      __typename?: 'CalendarSubscription';
      id: string;
      provider?: string | null;
      url: string;
      syncedAt?: any | null;
    }> | null;
    homhero?: { __typename?: 'HomheroArea'; id: string; name: string } | null;
  };
};

export type SiteTypesQueryVariables = Exact<{
  preset?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SiteTypesQuery = { __typename?: 'Query'; siteTypes: Array<string> };

export type SitesQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']['input']>;
  excludingTypes?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type SitesQuery = {
  __typename?: 'Query';
  sites: Array<{
    __typename?: 'Site';
    id: string;
    status: Status;
    type?: string | null;
    name: string;
    address?: string | null;
    state?: string | null;
    image?: string | null;
    activeLocations: number;
    attributes?: Array<{
      __typename?: 'Attribute';
      id: string;
      type: string;
      category?: string | null;
      name: string;
      value: Array<string>;
    }> | null;
  }>;
};

export type SkusQueryVariables = Exact<{ [key: string]: never }>;

export type SkusQuery = {
  __typename?: 'Query';
  skus: Array<{
    __typename?: 'Sku';
    id: string;
    code?: string | null;
    name: string;
    itemType: ItemType;
    image?: string | null;
    item: { __typename?: 'Item'; id: string; status: Status; name: string };
  }>;
};

export type SpaceSkuDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SpaceSkuDetailQuery = {
  __typename?: 'Query';
  spaceSkus: Array<{
    __typename?: 'SpaceSku';
    id: string;
    status: Status;
    soh: number;
    reserved: number;
    price?: number | null;
    capacity?: number | null;
    target?: number | null;
    reorderLevel?: number | null;
    space: { __typename?: 'Space'; id: string; name: string };
    sku: { __typename?: 'Sku'; id: string; name: string };
    attributes: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }>;
  }>;
};

export type SpaceSkusQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type SpaceSkusQuery = {
  __typename?: 'Query';
  spaceSkus: Array<{
    __typename?: 'SpaceSku';
    id: string;
    reserved: number;
    soh: number;
    target?: number | null;
    capacity?: number | null;
    reorderLevel?: number | null;
    space: {
      __typename?: 'Space';
      id: string;
      name: string;
      location:
        | { __typename: 'Asset'; id: string; name: string }
        | { __typename: 'Contact'; id: string; name: string }
        | { __typename: 'Elsewhere'; id: string; name: string }
        | { __typename: 'Fsp'; id: string; name: string }
        | { __typename: 'Site'; id: string; name: string }
        | { __typename: 'StockTransfer'; id: string; name: string };
      attributes?: Array<{
        __typename?: 'Attribute';
        name: string;
        value: Array<string>;
      }> | null;
    };
    sku: {
      __typename?: 'Sku';
      id: string;
      code?: string | null;
      name: string;
      itemType: ItemType;
      image?: string | null;
      attributes: Array<{
        __typename?: 'AttributeSingle';
        name: string;
        value: string;
      }>;
    };
  }>;
};

export type SpacesQueryVariables = Exact<{ [key: string]: never }>;

export type SpacesQuery = { __typename?: 'Query'; spaceNames: Array<string> };

export type StockAdjustmentTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type StockAdjustmentTypesQuery = {
  __typename?: 'Query';
  stockAdjustmentTypes: Array<string>;
};

export type StockMovementFiltersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type StockMovementFiltersQuery = {
  __typename?: 'Query';
  spaceNames: Array<string>;
  movementTypes: Array<string>;
  items: Array<{
    __typename?: 'Item';
    id: string;
    skus: Array<{ __typename?: 'Sku'; id: string; name: string }>;
  }>;
  users: Array<{
    __typename?: 'Contact';
    id: string;
    value: string;
    label: string;
  }>;
};

export type StockOnHandQueryVariables = Exact<{
  location:
    | Array<Scalars['LocationId']['input']>
    | Scalars['LocationId']['input'];
  filters?: InputMaybe<StockOnHandFilters>;
}>;

export type StockOnHandQuery = {
  __typename?: 'Query';
  stockOnHand: Array<{
    __typename?: 'SpaceSku';
    id: string;
    reserved: number;
    soh: number;
    target?: number | null;
    capacity?: number | null;
    reorderLevel?: number | null;
    space: {
      __typename?: 'Space';
      id: string;
      name: string;
      location:
        | { __typename: 'Asset'; id: string; name: string }
        | { __typename: 'Contact'; id: string; name: string }
        | { __typename: 'Elsewhere'; id: string; name: string }
        | { __typename: 'Fsp'; id: string; name: string }
        | { __typename: 'Site'; id: string; name: string }
        | { __typename: 'StockTransfer'; id: string; name: string };
      attributes?: Array<{
        __typename?: 'Attribute';
        name: string;
        value: Array<string>;
      }> | null;
    };
    sku: {
      __typename?: 'Sku';
      id: string;
      code?: string | null;
      name: string;
      itemType: ItemType;
      image?: string | null;
      attributes: Array<{
        __typename?: 'AttributeSingle';
        name: string;
        value: string;
      }>;
    };
  }>;
};

export type ListAssetModelsQueryVariables = Exact<{ [key: string]: never }>;

export type ListAssetModelsQuery = {
  __typename?: 'Query';
  assetModels: Array<{
    __typename?: 'AssetModel';
    id: string;
    status: Status;
    name: string;
    displayName: string;
    type: string;
    notes?: string | null;
    activeAssets?: number | null;
    image?: string | null;
  }>;
};

export type ListLocationDeploymentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ListLocationDeploymentsQuery = {
  __typename?: 'Query';
  location:
    | {
        __typename?: 'Asset';
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          name: string;
          deployments: Array<{
            __typename?: 'Deployment';
            id: string;
            status: Status;
            startedAt: any;
            endedAt?: any | null;
            image?: string | null;
            asset: {
              __typename?: 'Asset';
              id: string;
              name: string;
              type: string;
              image?: string | null;
              model?: {
                __typename?: 'AssetModel';
                id: string;
                displayName: string;
              } | null;
            };
          }>;
        }>;
      }
    | {
        __typename?: 'Contact';
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          name: string;
          deployments: Array<{
            __typename?: 'Deployment';
            id: string;
            status: Status;
            startedAt: any;
            endedAt?: any | null;
            image?: string | null;
            asset: {
              __typename?: 'Asset';
              id: string;
              name: string;
              type: string;
              image?: string | null;
              model?: {
                __typename?: 'AssetModel';
                id: string;
                displayName: string;
              } | null;
            };
          }>;
        }>;
      }
    | {
        __typename?: 'Elsewhere';
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          name: string;
          deployments: Array<{
            __typename?: 'Deployment';
            id: string;
            status: Status;
            startedAt: any;
            endedAt?: any | null;
            image?: string | null;
            asset: {
              __typename?: 'Asset';
              id: string;
              name: string;
              type: string;
              image?: string | null;
              model?: {
                __typename?: 'AssetModel';
                id: string;
                displayName: string;
              } | null;
            };
          }>;
        }>;
      }
    | {
        __typename?: 'Fsp';
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          name: string;
          deployments: Array<{
            __typename?: 'Deployment';
            id: string;
            status: Status;
            startedAt: any;
            endedAt?: any | null;
            image?: string | null;
            asset: {
              __typename?: 'Asset';
              id: string;
              name: string;
              type: string;
              image?: string | null;
              model?: {
                __typename?: 'AssetModel';
                id: string;
                displayName: string;
              } | null;
            };
          }>;
        }>;
      }
    | {
        __typename?: 'Site';
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          name: string;
          deployments: Array<{
            __typename?: 'Deployment';
            id: string;
            status: Status;
            startedAt: any;
            endedAt?: any | null;
            image?: string | null;
            asset: {
              __typename?: 'Asset';
              id: string;
              name: string;
              type: string;
              image?: string | null;
              model?: {
                __typename?: 'AssetModel';
                id: string;
                displayName: string;
              } | null;
            };
          }>;
        }>;
      }
    | {
        __typename?: 'StockTransfer';
        spaces: Array<{
          __typename?: 'Space';
          id: string;
          name: string;
          deployments: Array<{
            __typename?: 'Deployment';
            id: string;
            status: Status;
            startedAt: any;
            endedAt?: any | null;
            image?: string | null;
            asset: {
              __typename?: 'Asset';
              id: string;
              name: string;
              type: string;
              image?: string | null;
              model?: {
                __typename?: 'AssetModel';
                id: string;
                displayName: string;
              } | null;
            };
          }>;
        }>;
      };
};

export type ListSpacesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;

export type ListSpacesQuery = {
  __typename?: 'Query';
  spacesConnection: {
    __typename?: 'SpaceConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'SpaceEdge';
      cursor: string;
      node: {
        __typename?: 'Space';
        id: string;
        status: Status;
        name: string;
        activeSkus: number;
        image?: string | null;
        attributes?: Array<{
          __typename?: 'Attribute';
          name: string;
          value: Array<string>;
        }> | null;
        location:
          | { __typename: 'Asset'; id: string; name: string }
          | { __typename: 'Contact'; id: string; name: string }
          | { __typename: 'Elsewhere'; id: string; name: string }
          | { __typename: 'Fsp'; id: string; name: string }
          | { __typename: 'Site'; id: string; name: string }
          | { __typename: 'StockTransfer'; id: string; name: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type ViewSpaceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ViewSpaceQuery = {
  __typename?: 'Query';
  spaces: Array<{
    __typename?: 'Space';
    id: string;
    status: Status;
    name: string;
    description?: string | null;
    notes?: string | null;
    images: Array<string>;
    attributes?: Array<{
      __typename?: 'Attribute';
      name: string;
      value: Array<string>;
    }> | null;
    location:
      | { __typename: 'Asset'; id: string; name: string }
      | { __typename: 'Contact'; id: string; name: string }
      | { __typename: 'Elsewhere'; id: string; name: string }
      | { __typename: 'Fsp'; id: string; name: string }
      | { __typename: 'Site'; id: string; name: string }
      | { __typename: 'StockTransfer'; id: string; name: string };
  }>;
};

export type ListUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListUsersQuery = {
  __typename?: 'Query';
  users: Array<
    | { __typename: 'Invitation'; id: string; email: string }
    | {
        __typename: 'User';
        id: string;
        name: string;
        email: string;
        isOwner?: boolean | null;
      }
  >;
};

export const ContactFieldsFragmentDoc = gql`
  fragment ContactFields on Contact {
    id
    status
    type
    name
    email
    phone
    relationship
    image
    isCurrentUser
    isTeamMember
  }
`;
export const JobCalendarEventFieldsFragmentDoc = gql`
  fragment JobCalendarEventFields on JobCalendarEvent {
    id
    start
    end
    timeZone
    summary
    url
    icon
    cancelled
    hidden
    location {
      __typename
      id
      name
    }
    attributes {
      name
      value
    }
    jobs {
      __typename
      id
      status
      name
      createdAt
      scheduleStart
      scheduleEnd
      scheduledStartStrict
      scheduledStartDate
      scheduledStartTime
      scheduledEndStrict
      scheduledEndDate
      scheduledEndTime
      completedAt
      timeZone
      image
      tags
      location {
        __typename
        id
        name
      }
      assignee {
        id
        name
        image
      }
    }
  }
`;
export const JobFeedFieldsFragmentDoc = gql`
  fragment JobFeedFields on JobFeed {
    __typename
    ... on Comment {
      id
      body
      createdAt
      isRead
      isFlagged
      createdBy {
        id
        name
      }
      attachments {
        id
        kind
        thumb
        url
        originalFilename
      }
    }
    ... on JobHistory {
      id
      taskId
      status
      inputDate
      createdAt
      createdBy {
        id
        image
        name
      }
    }
  }
`;
export const StocktakeTaskFieldsFragmentDoc = gql`
  fragment StocktakeTaskFields on Stocktake {
    id
    include
    itemStatus
    spaces
    items {
      id
      adjustment
      newOnHand
    }
  }
`;
export const JobTemplateFieldsFragmentDoc = gql`
  fragment JobTemplateFields on JobTemplate {
    id
    isStarter
    status
    name
    notes
    tasks {
      id
      type
      name
      description
      attributes {
        id
        type
        category
        name
        value
      }
      config {
        selection
        spaces
        assetTypes
        allow
      }
      stocktake {
        ...StocktakeTaskFields
      }
    }
  }
  ${StocktakeTaskFieldsFragmentDoc}
`;
export const SpaceFieldsFragmentDoc = gql`
  fragment SpaceFields on Space {
    id
    status
    name
    activeSkus
    image
    attributes {
      name
      value
    }
    location {
      __typename
      id
      name
    }
  }
`;
export const SpaceToSkuFragmentDoc = gql`
  fragment SpaceToSku on Space {
    id
    name
    skus {
      id
      spaceId
      skuId
      queuedType
      soh
      price
      capacity
      target
      queuedPrice
    }
  }
`;
export const ItemDataFragmentDoc = gql`
  fragment itemData on Item {
    id
    status
    name
    type
    description
    skuPrefix
    images
    image
    activeSkus
    hasVariants
    skus {
      id
      status
      _name
      name
      code
      images
      image
      variant {
        name
        value
      }
    }
    variantTypes {
      name
      values
    }
  }
`;
export const SpaceSkuDataFragmentDoc = gql`
  fragment spaceSkuData on ISpaceSku {
    id
    reserved
    soh
    target
    capacity
    reorderLevel
    space {
      id
      name
      location {
        __typename
        id
        name
      }
      attributes {
        name
        value
      }
    }
    sku {
      id
      code
      name
      itemType
      image
      attributes {
        name
        value
      }
    }
  }
`;
export const AddItemDocument = gql`
  mutation AddItem($input: ItemInput!) {
    addItem(input: $input) {
      ...itemData
    }
  }
  ${ItemDataFragmentDoc}
`;

export function useAddItemMutation() {
  return Urql.useMutation<AddItemMutation, AddItemMutationVariables>(
    AddItemDocument
  );
}
export const AdjustStockDocument = gql`
  mutation AdjustStock($input: StockAdjustmentInput!) {
    adjustStock(input: $input) {
      id
    }
  }
`;

export function useAdjustStockMutation() {
  return Urql.useMutation<AdjustStockMutation, AdjustStockMutationVariables>(
    AdjustStockDocument
  );
}
export const CompleteCustomTaskDocument = gql`
  mutation CompleteCustomTask($input: CompleteTaskInput!) {
    completeCustomTask(input: $input) {
      id
      status
      updatedAt
      job {
        id
        status
      }
    }
  }
`;

export function useCompleteCustomTaskMutation() {
  return Urql.useMutation<
    CompleteCustomTaskMutation,
    CompleteCustomTaskMutationVariables
  >(CompleteCustomTaskDocument);
}
export const CompleteItemTaskDocument = gql`
  mutation CompleteItemTask($input: CompleteItemTaskInput!) {
    completeItemTask(input: $input) {
      id
      status
      updatedAt
      job {
        id
        status
      }
    }
  }
`;

export function useCompleteItemTaskMutation() {
  return Urql.useMutation<
    CompleteItemTaskMutation,
    CompleteItemTaskMutationVariables
  >(CompleteItemTaskDocument);
}
export const CreateContactDocument = gql`
  mutation CreateContact($input: ContactInput!) {
    createContact(input: $input) {
      ...ContactFields
    }
  }
  ${ContactFieldsFragmentDoc}
`;

export function useCreateContactMutation() {
  return Urql.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument);
}
export const CreateDeploymentDocument = gql`
  mutation CreateDeployment($input: NewDeploymentInput!) {
    createDeployment(input: $input) {
      id
    }
  }
`;

export function useCreateDeploymentMutation() {
  return Urql.useMutation<
    CreateDeploymentMutation,
    CreateDeploymentMutationVariables
  >(CreateDeploymentDocument);
}
export const CreateUploadDocument = gql`
  mutation CreateUpload($file: FileInput!) {
    createUpload(file: $file) {
      id
      ext
      presignedUrl
      baseUrl
      originalFilename
    }
  }
`;

export function useCreateUploadMutation() {
  return Urql.useMutation<CreateUploadMutation, CreateUploadMutationVariables>(
    CreateUploadDocument
  );
}
export const FinishStocktakeDocument = gql`
  mutation FinishStocktake($input: StocktakeInput!) {
    finishStocktake(input: $input)
  }
`;

export function useFinishStocktakeMutation() {
  return Urql.useMutation<
    FinishStocktakeMutation,
    FinishStocktakeMutationVariables
  >(FinishStocktakeDocument);
}
export const GeneratePicksDocument = gql`
  mutation GeneratePicks($stockTransferId: Int!) {
    generatePicks(stockTransferId: $stockTransferId)
  }
`;

export function useGeneratePicksMutation() {
  return Urql.useMutation<
    GeneratePicksMutation,
    GeneratePicksMutationVariables
  >(GeneratePicksDocument);
}
export const QuickAddDocument = gql`
  mutation QuickAdd($input: QuickAddInput!) {
    quickAdd(input: $input) {
      id
      soh
      price
      capacity
      target
    }
  }
`;

export function useQuickAddMutation() {
  return Urql.useMutation<QuickAddMutation, QuickAddMutationVariables>(
    QuickAddDocument
  );
}
export const UpdateContactDocument = gql`
  mutation UpdateContact($id: ID!, $input: ContactInput!) {
    updateContact(id: $id, input: $input) {
      id
      status
      type
      name
      organisation {
        id
        name
      }
      relationship
      phone
      email
      streetAddress
      postalAddress
      notes
      attributes {
        name
        value
      }
      images
    }
  }
`;

export function useUpdateContactMutation() {
  return Urql.useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UpdateContactDocument);
}
export const UpdateItemDocument = gql`
  mutation UpdateItem($id: ID!, $input: ItemInput!) {
    updateItem(id: $id, input: $input) {
      ...itemData
    }
  }
  ${ItemDataFragmentDoc}
`;

export function useUpdateItemMutation() {
  return Urql.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(
    UpdateItemDocument
  );
}
export const UpdateSpaceSkuDocument = gql`
  mutation UpdateSpaceSku($id: ID!, $input: SpaceSkuInput!) {
    updateSpaceSku(id: $id, input: $input) {
      id
      status
      soh
      reserved
      price
      capacity
      target
      reorderLevel
      space {
        id
        name
      }
      sku {
        id
        name
      }
      attributes {
        name
        value
      }
    }
  }
`;

export function useUpdateSpaceSkuMutation() {
  return Urql.useMutation<
    UpdateSpaceSkuMutation,
    UpdateSpaceSkuMutationVariables
  >(UpdateSpaceSkuDocument);
}
export const CreateAssetDocument = gql`
  mutation CreateAsset($input: AssetInput!) {
    createAsset(input: $input) {
      id
      status
      name
      type
      notes
      image
      images
      attachments {
        id
        url
        originalFilename
      }
      attributes {
        name
        value
      }
      model {
        id
        name
        brand
        type
        displayName
      }
    }
  }
`;

export function useCreateAssetMutation() {
  return Urql.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(
    CreateAssetDocument
  );
}
export const CreateAssetModelDocument = gql`
  mutation CreateAssetModel($input: AssetModelInput!) {
    createAssetModel(input: $input) {
      id
      status
      name
      displayName
      type
      notes
      activeAssets
      image
    }
  }
`;

export function useCreateAssetModelMutation() {
  return Urql.useMutation<
    CreateAssetModelMutation,
    CreateAssetModelMutationVariables
  >(CreateAssetModelDocument);
}
export const UpdateAssetDocument = gql`
  mutation UpdateAsset($id: ID!, $input: AssetInput!) {
    updateAsset(id: $id, input: $input) {
      id
      status
      name
      type
      notes
      image
      images
      attachments {
        id
        url
        originalFilename
      }
      attributes {
        name
        value
      }
      model {
        id
        name
        brand
        type
        displayName
      }
    }
  }
`;

export function useUpdateAssetMutation() {
  return Urql.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(
    UpdateAssetDocument
  );
}
export const UpdateAssetModelDocument = gql`
  mutation UpdateAssetModel($id: ID!, $input: AssetModelInput!) {
    updateAssetModel(id: $id, input: $input) {
      id
      status
      name
      displayName
      type
      notes
      activeAssets
      image
    }
  }
`;

export function useUpdateAssetModelMutation() {
  return Urql.useMutation<
    UpdateAssetModelMutation,
    UpdateAssetModelMutationVariables
  >(UpdateAssetModelDocument);
}
export const AcceptJobDocument = gql`
  mutation AcceptJob($id: ID!, $comment: String) {
    acceptJob(id: $id, comment: $comment) {
      id
      status
    }
  }
`;

export function useAcceptJobMutation() {
  return Urql.useMutation<AcceptJobMutation, AcceptJobMutationVariables>(
    AcceptJobDocument
  );
}
export const CancelJobDocument = gql`
  mutation CancelJob($id: ID!, $comment: String) {
    cancelJob(id: $id, comment: $comment) {
      id
      status
      permissions
      comments(last: 1) {
        ...JobFeedFields
      }
    }
  }
  ${JobFeedFieldsFragmentDoc}
`;

export function useCancelJobMutation() {
  return Urql.useMutation<CancelJobMutation, CancelJobMutationVariables>(
    CancelJobDocument
  );
}
export const CompleteJobDocument = gql`
  mutation CompleteJob($id: ID!, $endTime: DateTime!) {
    completeJob(id: $id, endTime: $endTime) {
      id
      status
    }
  }
`;

export function useCompleteJobMutation() {
  return Urql.useMutation<CompleteJobMutation, CompleteJobMutationVariables>(
    CompleteJobDocument
  );
}
export const DeclineJobDocument = gql`
  mutation DeclineJob($id: ID!, $comment: String) {
    declineJob(id: $id, comment: $comment) {
      id
      status
    }
  }
`;

export function useDeclineJobMutation() {
  return Urql.useMutation<DeclineJobMutation, DeclineJobMutationVariables>(
    DeclineJobDocument
  );
}
export const DeleteJobDocument = gql`
  mutation DeleteJob($id: ID!) {
    deleteJob(id: $id)
  }
`;

export function useDeleteJobMutation() {
  return Urql.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(
    DeleteJobDocument
  );
}
export const EndTaskDocument = gql`
  mutation EndTask($id: ID!, $endTime: DateTime!) {
    endTask(id: $id, endTime: $endTime) {
      id
      status
    }
  }
`;

export function useEndTaskMutation() {
  return Urql.useMutation<EndTaskMutation, EndTaskMutationVariables>(
    EndTaskDocument
  );
}
export const OfferJobDocument = gql`
  mutation OfferJob($id: ID!, $comment: String) {
    offerJob(id: $id, comment: $comment) {
      id
      status
      comments(last: 1) {
        ...JobFeedFields
      }
    }
  }
  ${JobFeedFieldsFragmentDoc}
`;

export function useOfferJobMutation() {
  return Urql.useMutation<OfferJobMutation, OfferJobMutationVariables>(
    OfferJobDocument
  );
}
export const PauseTaskDocument = gql`
  mutation PauseTask($id: ID!, $pauseTime: DateTime!) {
    pauseTask(id: $id, pauseTime: $pauseTime) {
      id
      status
    }
  }
`;

export function usePauseTaskMutation() {
  return Urql.useMutation<PauseTaskMutation, PauseTaskMutationVariables>(
    PauseTaskDocument
  );
}
export const ResetTaskStatusDocument = gql`
  mutation ResetTaskStatus($id: ID!) {
    resetTaskStatus(id: $id) {
      id
      status
    }
  }
`;

export function useResetTaskStatusMutation() {
  return Urql.useMutation<
    ResetTaskStatusMutation,
    ResetTaskStatusMutationVariables
  >(ResetTaskStatusDocument);
}
export const ResumeTaskDocument = gql`
  mutation ResumeTask($id: ID!, $resumeTime: DateTime!) {
    resumeTask(id: $id, resumeTime: $resumeTime) {
      id
      status
    }
  }
`;

export function useResumeTaskMutation() {
  return Urql.useMutation<ResumeTaskMutation, ResumeTaskMutationVariables>(
    ResumeTaskDocument
  );
}
export const SortJobDocument = gql`
  mutation SortJob($id: ID!, $sort: String!, $rel: ID!) {
    sortJob(id: $id, sort: $sort, rel: $rel)
  }
`;

export function useSortJobMutation() {
  return Urql.useMutation<SortJobMutation, SortJobMutationVariables>(
    SortJobDocument
  );
}
export const StartJobDocument = gql`
  mutation StartJob($id: ID!, $startTime: DateTime!) {
    startJob(id: $id, startTime: $startTime) {
      id
      status
    }
  }
`;

export function useStartJobMutation() {
  return Urql.useMutation<StartJobMutation, StartJobMutationVariables>(
    StartJobDocument
  );
}
export const StartTaskDocument = gql`
  mutation StartTask($id: ID!, $startTime: DateTime!) {
    startTask(id: $id, startTime: $startTime) {
      id
      status
    }
  }
`;

export function useStartTaskMutation() {
  return Urql.useMutation<StartTaskMutation, StartTaskMutationVariables>(
    StartTaskDocument
  );
}
export const OnboardingDocument = gql`
  mutation Onboarding($version: ID!, $step: Int!, $input: String) {
    onboard(version: $version, step: $step, input: $input) {
      id
      form
      version
      step
      hasNextStep
      waitingFor
    }
  }
`;

export function useOnboardingMutation() {
  return Urql.useMutation<OnboardingMutation, OnboardingMutationVariables>(
    OnboardingDocument
  );
}
export const CreateSiteDocument = gql`
  mutation CreateSite($input: SiteInput!) {
    createSite(input: $input) {
      id
      status
      type
      name
      address
      state
      image
      activeLocations
      attributes {
        id
        type
        category
        name
        value
        attachments {
          id
          kind
          thumb
          url
          originalFilename
        }
      }
    }
  }
`;

export function useCreateSiteMutation() {
  return Urql.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(
    CreateSiteDocument
  );
}
export const UpdateSiteDocument = gql`
  mutation UpdateSite($id: ID!, $input: SiteInput!) {
    updateSite(id: $id, input: $input) {
      id
      status
      type
      name
      address
      state
      image
      activeLocations
      attributes {
        name
        value
      }
    }
  }
`;

export function useUpdateSiteMutation() {
  return Urql.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(
    UpdateSiteDocument
  );
}
export const CreateSpaceDocument = gql`
  mutation CreateSpace($locationId: LocationId!, $input: SpaceInput!) {
    createSpace(locationId: $locationId, input: $input) {
      ...SpaceFields
    }
  }
  ${SpaceFieldsFragmentDoc}
`;

export function useCreateSpaceMutation() {
  return Urql.useMutation<CreateSpaceMutation, CreateSpaceMutationVariables>(
    CreateSpaceDocument
  );
}
export const UpdateSpaceDocument = gql`
  mutation UpdateSpace($id: ID!, $input: SpaceInput!) {
    updateSpace(id: $id, input: $input) {
      ...SpaceFields
    }
  }
  ${SpaceFieldsFragmentDoc}
`;

export function useUpdateSpaceMutation() {
  return Urql.useMutation<UpdateSpaceMutation, UpdateSpaceMutationVariables>(
    UpdateSpaceDocument
  );
}
export const CreateTenantDocument = gql`
  mutation CreateTenant($input: AccountProfileInput!) {
    createTenant(input: $input)
  }
`;

export function useCreateTenantMutation() {
  return Urql.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(
    CreateTenantDocument
  );
}
export const InviteUserDocument = gql`
  mutation InviteUser($email: String!) {
    inviteUser(email: $email) {
      id
      email
    }
  }
`;

export function useInviteUserMutation() {
  return Urql.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument
  );
}
export const RemoveUserDocument = gql`
  mutation RemoveUser($email: String!) {
    removeUser(email: $email)
  }
`;

export function useRemoveUserMutation() {
  return Urql.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(
    RemoveUserDocument
  );
}
export const UpdateAccountProfileDocument = gql`
  mutation UpdateAccountProfile($input: AccountProfileInput!) {
    updateAccountProfile(input: $input)
  }
`;

export function useUpdateAccountProfileMutation() {
  return Urql.useMutation<
    UpdateAccountProfileMutation,
    UpdateAccountProfileMutationVariables
  >(UpdateAccountProfileDocument);
}
export const ChangeEmailDocument = gql`
  mutation ChangeEmail($input: ChangeEmailInput!) {
    changeEmailAddress(input: $input)
  }
`;

export function useChangeEmailMutation() {
  return Urql.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(
    ChangeEmailDocument
  );
}
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

export function useChangePasswordMutation() {
  return Urql.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument);
}
export const PatchOrganisationProfileDocument = gql`
  mutation PatchOrganisationProfile($input: OrganisationProfileInput!) {
    patchOrganisationProfile(input: $input)
  }
`;

export function usePatchOrganisationProfileMutation() {
  return Urql.useMutation<
    PatchOrganisationProfileMutation,
    PatchOrganisationProfileMutationVariables
  >(PatchOrganisationProfileDocument);
}
export const SaveNotificationPreferencesDocument = gql`
  mutation SaveNotificationPreferences($input: NotificationSettingInput!) {
    saveNotificationPreferences(input: $input)
  }
`;

export function useSaveNotificationPreferencesMutation() {
  return Urql.useMutation<
    SaveNotificationPreferencesMutation,
    SaveNotificationPreferencesMutationVariables
  >(SaveNotificationPreferencesDocument);
}
export const SetProfilePicDocument = gql`
  mutation SetProfilePic($image: String!) {
    setProfilePic(image: $image) {
      id
      image
    }
  }
`;

export function useSetProfilePicMutation() {
  return Urql.useMutation<
    SetProfilePicMutation,
    SetProfilePicMutationVariables
  >(SetProfilePicDocument);
}
export const UpdatePersonalInfoDocument = gql`
  mutation updatePersonalInfo($input: PersonalInfoInput!) {
    updatePersonalInfo(input: $input)
  }
`;

export function useUpdatePersonalInfoMutation() {
  return Urql.useMutation<
    UpdatePersonalInfoMutation,
    UpdatePersonalInfoMutationVariables
  >(UpdatePersonalInfoDocument);
}
export const AddItemsTaskDocument = gql`
  query AddItemsTask($id: ID!) {
    addItemsTask(id: $id) {
      id
      type
      status
      cards {
        ...spaceSkuData
        pick
        picked
        received
        putAway
      }
      stockTransfer {
        id
        rule
        selectStock
        processQueuedChanges
        source {
          __typename
          id
          name
        }
        destination {
          __typename
          id
          name
        }
        items {
          id
          spaceId
          skuId
          pick
          picked
          packed
          dispatched
          received
          putAway
        }
      }
    }
  }
  ${SpaceSkuDataFragmentDoc}
`;

export function useAddItemsTaskQuery(
  options: Omit<Urql.UseQueryArgs<AddItemsTaskQueryVariables>, 'query'>
) {
  return Urql.useQuery<AddItemsTaskQuery, AddItemsTaskQueryVariables>({
    query: AddItemsTaskDocument,
    ...options,
  });
}
export const AssetFiltersDocument = gql`
  query AssetFilters {
    assetModels {
      id
      value: id
      label: displayName
      type
    }
    assetTypes
  }
`;

export function useAssetFiltersQuery(
  options?: Omit<Urql.UseQueryArgs<AssetFiltersQueryVariables>, 'query'>
) {
  return Urql.useQuery<AssetFiltersQuery, AssetFiltersQueryVariables>({
    query: AssetFiltersDocument,
    ...options,
  });
}
export const AssetsDocument = gql`
  query Assets {
    assets {
      id
      status
      name
      type
      notes
      image
      images
      attachments {
        id
        ext
        url
        originalFilename
      }
      attributes {
        name
        value
      }
      deployment {
        id
        displayLocation
        startedAt
        image
        location {
          __typename
          id
          name
        }
        space {
          id
          name
        }
      }
      model {
        id
        name
        brand
        type
        displayName
      }
    }
  }
`;

export function useAssetsQuery(
  options?: Omit<Urql.UseQueryArgs<AssetsQueryVariables>, 'query'>
) {
  return Urql.useQuery<AssetsQuery, AssetsQueryVariables>({
    query: AssetsDocument,
    ...options,
  });
}
export const AttributesDocument = gql`
  query Attributes($entityType: String!) {
    attributes(entityType: $entityType) {
      name
      value
    }
  }
`;

export function useAttributesQuery(
  options: Omit<Urql.UseQueryArgs<AttributesQueryVariables>, 'query'>
) {
  return Urql.useQuery<AttributesQuery, AttributesQueryVariables>({
    query: AttributesDocument,
    ...options,
  });
}
export const BackgroundJobDocument = gql`
  query BackgroundJob($id: ID!) {
    backgroundJob(id: $id)
  }
`;

export function useBackgroundJobQuery(
  options: Omit<Urql.UseQueryArgs<BackgroundJobQueryVariables>, 'query'>
) {
  return Urql.useQuery<BackgroundJobQuery, BackgroundJobQueryVariables>({
    query: BackgroundJobDocument,
    ...options,
  });
}
export const ContactDocument = gql`
  query Contact($id: ID!) {
    contact(id: $id) {
      id
      status
      type
      name
      organisation {
        id
        name
      }
      relationship
      phone
      email
      streetAddress
      postalAddress
      notes
      attributes {
        name
        value
      }
      image
      images
      isCurrentUser
    }
  }
`;

export function useContactQuery(
  options: Omit<Urql.UseQueryArgs<ContactQueryVariables>, 'query'>
) {
  return Urql.useQuery<ContactQuery, ContactQueryVariables>({
    query: ContactDocument,
    ...options,
  });
}
export const ContactsDocument = gql`
  query Contacts($status: [Status!], $relationship: [ContactRelationship!]) {
    contacts(status: $status, relationship: $relationship) {
      ...ContactFields
    }
  }
  ${ContactFieldsFragmentDoc}
`;

export function useContactsQuery(
  options?: Omit<Urql.UseQueryArgs<ContactsQueryVariables>, 'query'>
) {
  return Urql.useQuery<ContactsQuery, ContactsQueryVariables>({
    query: ContactsDocument,
    ...options,
  });
}
export const DeploymentFiltersDocument = gql`
  query DeploymentFilters {
    assetTypes
  }
`;

export function useDeploymentFiltersQuery(
  options?: Omit<Urql.UseQueryArgs<DeploymentFiltersQueryVariables>, 'query'>
) {
  return Urql.useQuery<DeploymentFiltersQuery, DeploymentFiltersQueryVariables>(
    { query: DeploymentFiltersDocument, ...options }
  );
}
export const DeploymentsDocument = gql`
  query Deployments {
    deployments {
      id
      status
      startedAt
      endedAt
      image
      images
      displayLocation
      state
      owner
      asset {
        id
        name
        type
        model {
          id
          displayName
        }
      }
      location {
        __typename
        id
        name
      }
      space {
        id
        name
      }
    }
  }
`;

export function useDeploymentsQuery(
  options?: Omit<Urql.UseQueryArgs<DeploymentsQueryVariables>, 'query'>
) {
  return Urql.useQuery<DeploymentsQuery, DeploymentsQueryVariables>({
    query: DeploymentsDocument,
    ...options,
  });
}
export const ItemsDocument = gql`
  query Items($status: [Status!], $inConfig: Boolean) {
    items(status: $status, inConfig: $inConfig) {
      ...itemData
    }
  }
  ${ItemDataFragmentDoc}
`;

export function useItemsQuery(
  options?: Omit<Urql.UseQueryArgs<ItemsQueryVariables>, 'query'>
) {
  return Urql.useQuery<ItemsQuery, ItemsQueryVariables>({
    query: ItemsDocument,
    ...options,
  });
}
export const ItemsAndVariantsDocument = gql`
  query ItemsAndVariants {
    items {
      id
      type
      name
      description
    }
    skus {
      id
      code
      name
      itemId
    }
  }
`;

export function useItemsAndVariantsQuery(
  options?: Omit<Urql.UseQueryArgs<ItemsAndVariantsQueryVariables>, 'query'>
) {
  return Urql.useQuery<ItemsAndVariantsQuery, ItemsAndVariantsQueryVariables>({
    query: ItemsAndVariantsDocument,
    ...options,
  });
}
export const JobHistoryDocument = gql`
  query JobHistory($jobId: ID!) {
    job(id: $jobId) {
      id
      history {
        id
        task {
          id
          name
        }
        status
        inputDate
        createdAt
        createdBy {
          id
          image
          name
        }
      }
    }
  }
`;

export function useJobHistoryQuery(
  options: Omit<Urql.UseQueryArgs<JobHistoryQueryVariables>, 'query'>
) {
  return Urql.useQuery<JobHistoryQuery, JobHistoryQueryVariables>({
    query: JobHistoryDocument,
    ...options,
  });
}
export const JobsDocument = gql`
  query Jobs(
    $status: [JobStatus!]
    $locationId: String
    $locationType: [String!]
    $locationName: [String!]
    $assetType: [String!]
    $asset: [ID!]
    $assignee: [ID!]
    $state: [String!]
    $scheduleFrom: DateTime
    $scheduleTo: DateTime
    $groupBy: String
  ) {
    assetTypes
    assignees: contacts {
      id
      name
    }
    jobCalendar(locationName: $locationName) {
      id
      date
      summary
      url
      location {
        id
        name
      }
    }
    jobs(
      status: $status
      locationId: $locationId
      locationType: $locationType
      locationName: $locationName
      assetType: $assetType
      asset: $asset
      assignee: $assignee
      state: $state
      scheduleFrom: $scheduleFrom
      scheduleTo: $scheduleTo
      groupBy: $groupBy
    ) {
      _dateCreated
      _dateScheduled
      id
      jobNumber
      status
      name
      location {
        __typename
        id
        name
        deployment {
          id
          displayLocation(separator: ">")
          state
          image
        }
      }
      tasks {
        id
        type
        name
      }
      deploymentString
      state
      timeZone
      scheduleStart
      scheduleEnd
      scheduledStartStrict
      scheduledStartDate
      scheduledStartTime
      scheduledEndStrict
      scheduledEndDate
      scheduledEndTime
      completedAt
      assignee {
        id
        name
        image
      }
      owner {
        id
        name
        image: logo
      }
      createdAt
      updatedAt
      isSource
      image
      permissions
    }
  }
`;

export function useJobsQuery(
  options?: Omit<Urql.UseQueryArgs<JobsQueryVariables>, 'query'>
) {
  return Urql.useQuery<JobsQuery, JobsQueryVariables>({
    query: JobsDocument,
    ...options,
  });
}
export const LocationDocument = gql`
  query Location($id: ID!) {
    location(id: $id) {
      __typename
      id
      name
      spaces {
        id
        status
        name
        skus {
          id
          skuId
        }
      }
    }
  }
`;

export function useLocationQuery(
  options: Omit<Urql.UseQueryArgs<LocationQueryVariables>, 'query'>
) {
  return Urql.useQuery<LocationQuery, LocationQueryVariables>({
    query: LocationDocument,
    ...options,
  });
}
export const LocationsDocument = gql`
  query Locations($type: LocationType, $subtype: [String!]) {
    locations(type: $type, subtype: $subtype, status: Active) {
      __typename
      id
      name
    }
  }
`;

export function useLocationsQuery(
  options?: Omit<Urql.UseQueryArgs<LocationsQueryVariables>, 'query'>
) {
  return Urql.useQuery<LocationsQuery, LocationsQueryVariables>({
    query: LocationsDocument,
    ...options,
  });
}
export const OnboardingStatusDocument = gql`
  query OnboardingStatus {
    isOnboarding
  }
`;

export function useOnboardingStatusQuery(
  options?: Omit<Urql.UseQueryArgs<OnboardingStatusQueryVariables>, 'query'>
) {
  return Urql.useQuery<OnboardingStatusQuery, OnboardingStatusQueryVariables>({
    query: OnboardingStatusDocument,
    ...options,
  });
}
export const SiteDocument = gql`
  query Site($id: ID!) {
    integrations
    site(id: $id) {
      id
      status
      name
      type
      address
      image
      notes
      licensor {
        id
        name
      }
      defaultSource {
        id
        type
        name
      }
      attributes {
        id
        type
        category
        name
        value
        attachments {
          id
          kind
          ext
          thumb
          url
          originalFilename
        }
      }
      integrationAttributes {
        name
        value
      }
      images
      airbnbCalendar {
        id
        provider
        url
        syncedAt
      }
      airbnbDefaultCheckIn
      airbnbDefaultCheckOut
      homhero {
        id
        name
      }
    }
  }
`;

export function useSiteQuery(
  options: Omit<Urql.UseQueryArgs<SiteQueryVariables>, 'query'>
) {
  return Urql.useQuery<SiteQuery, SiteQueryVariables>({
    query: SiteDocument,
    ...options,
  });
}
export const SiteTypesDocument = gql`
  query SiteTypes($preset: Boolean) {
    siteTypes(preset: $preset)
  }
`;

export function useSiteTypesQuery(
  options?: Omit<Urql.UseQueryArgs<SiteTypesQueryVariables>, 'query'>
) {
  return Urql.useQuery<SiteTypesQuery, SiteTypesQueryVariables>({
    query: SiteTypesDocument,
    ...options,
  });
}
export const SitesDocument = gql`
  query Sites($type: String, $excludingTypes: [String!]) {
    sites(type: $type, excludingTypes: $excludingTypes) {
      id
      status
      type
      name
      address
      state
      image
      activeLocations
      attributes {
        id
        type
        category
        name
        value
      }
    }
  }
`;

export function useSitesQuery(
  options?: Omit<Urql.UseQueryArgs<SitesQueryVariables>, 'query'>
) {
  return Urql.useQuery<SitesQuery, SitesQueryVariables>({
    query: SitesDocument,
    ...options,
  });
}
export const SkusDocument = gql`
  query Skus {
    skus {
      id
      code
      name
      itemType
      image
      item {
        id
        status
        name
      }
    }
  }
`;

export function useSkusQuery(
  options?: Omit<Urql.UseQueryArgs<SkusQueryVariables>, 'query'>
) {
  return Urql.useQuery<SkusQuery, SkusQueryVariables>({
    query: SkusDocument,
    ...options,
  });
}
export const SpaceSkuDetailDocument = gql`
  query SpaceSkuDetail($id: ID!) {
    spaceSkus(ids: [$id]) {
      id
      status
      soh
      reserved
      price
      capacity
      target
      reorderLevel
      space {
        id
        name
      }
      sku {
        id
        name
      }
      attributes {
        name
        value
      }
    }
  }
`;

export function useSpaceSkuDetailQuery(
  options: Omit<Urql.UseQueryArgs<SpaceSkuDetailQueryVariables>, 'query'>
) {
  return Urql.useQuery<SpaceSkuDetailQuery, SpaceSkuDetailQueryVariables>({
    query: SpaceSkuDetailDocument,
    ...options,
  });
}
export const SpaceSkusDocument = gql`
  query SpaceSkus($ids: [ID!]!) {
    spaceSkus(ids: $ids) {
      ...spaceSkuData
    }
  }
  ${SpaceSkuDataFragmentDoc}
`;

export function useSpaceSkusQuery(
  options: Omit<Urql.UseQueryArgs<SpaceSkusQueryVariables>, 'query'>
) {
  return Urql.useQuery<SpaceSkusQuery, SpaceSkusQueryVariables>({
    query: SpaceSkusDocument,
    ...options,
  });
}
export const SpacesDocument = gql`
  query Spaces {
    spaceNames
  }
`;

export function useSpacesQuery(
  options?: Omit<Urql.UseQueryArgs<SpacesQueryVariables>, 'query'>
) {
  return Urql.useQuery<SpacesQuery, SpacesQueryVariables>({
    query: SpacesDocument,
    ...options,
  });
}
export const StockAdjustmentTypesDocument = gql`
  query StockAdjustmentTypes {
    stockAdjustmentTypes
  }
`;

export function useStockAdjustmentTypesQuery(
  options?: Omit<Urql.UseQueryArgs<StockAdjustmentTypesQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    StockAdjustmentTypesQuery,
    StockAdjustmentTypesQueryVariables
  >({ query: StockAdjustmentTypesDocument, ...options });
}
export const StockMovementFiltersDocument = gql`
  query StockMovementFilters {
    spaceNames
    movementTypes
    items(status: Active, inConfig: true) {
      id
      skus {
        id
        name
      }
    }
    users: contacts {
      id
      value: id
      label: name
    }
  }
`;

export function useStockMovementFiltersQuery(
  options?: Omit<Urql.UseQueryArgs<StockMovementFiltersQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    StockMovementFiltersQuery,
    StockMovementFiltersQueryVariables
  >({ query: StockMovementFiltersDocument, ...options });
}
export const StockOnHandDocument = gql`
  query StockOnHand($location: [LocationId!]!, $filters: StockOnHandFilters) {
    stockOnHand(location: $location, filters: $filters) {
      ...spaceSkuData
    }
  }
  ${SpaceSkuDataFragmentDoc}
`;

export function useStockOnHandQuery(
  options: Omit<Urql.UseQueryArgs<StockOnHandQueryVariables>, 'query'>
) {
  return Urql.useQuery<StockOnHandQuery, StockOnHandQueryVariables>({
    query: StockOnHandDocument,
    ...options,
  });
}
export const ListAssetModelsDocument = gql`
  query ListAssetModels {
    assetModels {
      id
      status
      name
      displayName
      type
      notes
      activeAssets
      image
    }
  }
`;

export function useListAssetModelsQuery(
  options?: Omit<Urql.UseQueryArgs<ListAssetModelsQueryVariables>, 'query'>
) {
  return Urql.useQuery<ListAssetModelsQuery, ListAssetModelsQueryVariables>({
    query: ListAssetModelsDocument,
    ...options,
  });
}
export const ListLocationDeploymentsDocument = gql`
  query ListLocationDeployments($id: ID!) {
    location(id: $id) {
      spaces {
        id
        name
        deployments {
          id
          status
          startedAt
          endedAt
          image
          asset {
            id
            name
            type
            image
            model {
              id
              displayName
            }
          }
        }
      }
    }
  }
`;

export function useListLocationDeploymentsQuery(
  options: Omit<
    Urql.UseQueryArgs<ListLocationDeploymentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    ListLocationDeploymentsQuery,
    ListLocationDeploymentsQueryVariables
  >({ query: ListLocationDeploymentsDocument, ...options });
}
export const ListSpacesDocument = gql`
  query ListSpaces(
    $first: Int
    $after: ID
    $query: String
    $filters: [Filter!]
  ) {
    spacesConnection(
      first: $first
      after: $after
      query: $query
      filters: $filters
    ) {
      totalCount
      edges {
        cursor
        node {
          ...SpaceFields
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${SpaceFieldsFragmentDoc}
`;

export function useListSpacesQuery(
  options?: Omit<Urql.UseQueryArgs<ListSpacesQueryVariables>, 'query'>
) {
  return Urql.useQuery<ListSpacesQuery, ListSpacesQueryVariables>({
    query: ListSpacesDocument,
    ...options,
  });
}
export const ViewSpaceDocument = gql`
  query ViewSpace($id: ID!) {
    spaces(id: [$id]) {
      id
      status
      name
      description
      notes
      images
      attributes {
        name
        value
      }
      location {
        __typename
        id
        name
      }
    }
  }
`;

export function useViewSpaceQuery(
  options: Omit<Urql.UseQueryArgs<ViewSpaceQueryVariables>, 'query'>
) {
  return Urql.useQuery<ViewSpaceQuery, ViewSpaceQueryVariables>({
    query: ViewSpaceDocument,
    ...options,
  });
}
export const ListUsersDocument = gql`
  query ListUsers {
    users {
      __typename
      ... on User {
        id
        name
        email
        isOwner
      }
      ... on Invitation {
        id
        email
      }
    }
  }
`;

export function useListUsersQuery(
  options?: Omit<Urql.UseQueryArgs<ListUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<ListUsersQuery, ListUsersQueryVariables>({
    query: ListUsersDocument,
    ...options,
  });
}
export type WithTypename<T extends { __typename?: any }> = Partial<T> & {
  __typename: NonNullable<T['__typename']>;
};

export type GraphCacheKeysConfig = {
  Activity?: (data: WithTypename<Activity>) => null | string;
  ActivityConnection?: (
    data: WithTypename<ActivityConnection>
  ) => null | string;
  ActivityEdge?: (data: WithTypename<ActivityEdge>) => null | string;
  Asset?: (data: WithTypename<Asset>) => null | string;
  AssetModel?: (data: WithTypename<AssetModel>) => null | string;
  AssetTaskConfig?: (data: WithTypename<AssetTaskConfig>) => null | string;
  Attachment?: (data: WithTypename<Attachment>) => null | string;
  Attribute?: (data: WithTypename<Attribute>) => null | string;
  AttributeSingle?: (data: WithTypename<AttributeSingle>) => null | string;
  AttributeTask?: (data: WithTypename<AttributeTask>) => null | string;
  Automation?: (data: WithTypename<Automation>) => null | string;
  AutomationCondition?: (
    data: WithTypename<AutomationCondition>
  ) => null | string;
  Booking?: (data: WithTypename<Booking>) => null | string;
  CalendarSubscription?: (
    data: WithTypename<CalendarSubscription>
  ) => null | string;
  Card?: (data: WithTypename<Card>) => null | string;
  Comment?: (data: WithTypename<Comment>) => null | string;
  Contact?: (data: WithTypename<Contact>) => null | string;
  Deployment?: (data: WithTypename<Deployment>) => null | string;
  Elsewhere?: (data: WithTypename<Elsewhere>) => null | string;
  Fsp?: (data: WithTypename<Fsp>) => null | string;
  HomheroArea?: (data: WithTypename<HomheroArea>) => null | string;
  Invitation?: (data: WithTypename<Invitation>) => null | string;
  Item?: (data: WithTypename<Item>) => null | string;
  ItemQty?: (data: WithTypename<ItemQty>) => null | string;
  ItemTask?: (data: WithTypename<ItemTask>) => null | string;
  Job?: (data: WithTypename<Job>) => null | string;
  JobCalendarEvent?: (data: WithTypename<JobCalendarEvent>) => null | string;
  JobHistory?: (data: WithTypename<JobHistory>) => null | string;
  JobTemplate?: (data: WithTypename<JobTemplate>) => null | string;
  JobsConnection?: (data: WithTypename<JobsConnection>) => null | string;
  JobsEdge?: (data: WithTypename<JobsEdge>) => null | string;
  Onboarding?: (data: WithTypename<Onboarding>) => null | string;
  Organisation?: (data: WithTypename<Organisation>) => null | string;
  OtpGenerateResponse?: (
    data: WithTypename<OtpGenerateResponse>
  ) => null | string;
  OtpVerifyResponse?: (data: WithTypename<OtpVerifyResponse>) => null | string;
  PageInfo?: (data: WithTypename<PageInfo>) => null | string;
  PurchaseTask?: (data: WithTypename<PurchaseTask>) => null | string;
  PurchaseTaskItem?: (data: WithTypename<PurchaseTaskItem>) => null | string;
  Role?: (data: WithTypename<Role>) => null | string;
  ScheduleEvent?: (data: WithTypename<ScheduleEvent>) => null | string;
  Session?: (data: WithTypename<Session>) => null | string;
  Site?: (data: WithTypename<Site>) => null | string;
  Sku?: (data: WithTypename<Sku>) => null | string;
  Space?: (data: WithTypename<Space>) => null | string;
  SpaceConnection?: (data: WithTypename<SpaceConnection>) => null | string;
  SpaceEdge?: (data: WithTypename<SpaceEdge>) => null | string;
  SpaceSku?: (data: WithTypename<SpaceSku>) => null | string;
  StockAdjustment?: (data: WithTypename<StockAdjustment>) => null | string;
  StockTransfer?: (data: WithTypename<StockTransfer>) => null | string;
  StockTransferItem?: (data: WithTypename<StockTransferItem>) => null | string;
  Stocktake?: (data: WithTypename<Stocktake>) => null | string;
  StocktakeItem?: (data: WithTypename<StocktakeItem>) => null | string;
  Tag?: (data: WithTypename<Tag>) => null | string;
  TagConnection?: (data: WithTypename<TagConnection>) => null | string;
  TagEdge?: (data: WithTypename<TagEdge>) => null | string;
  Task?: (data: WithTypename<Task>) => null | string;
  Thread?: (data: WithTypename<Thread>) => null | string;
  TransferTask?: (data: WithTypename<TransferTask>) => null | string;
  Upload?: (data: WithTypename<Upload>) => null | string;
  User?: (data: WithTypename<User>) => null | string;
  VariantType?: (data: WithTypename<VariantType>) => null | string;
  VariantValue?: (data: WithTypename<VariantValue>) => null | string;
};

export type GraphCacheResolvers = {
  Query?: {
    activityTags?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    addItemsTask?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAddItemsTaskArgs,
      WithTypename<ItemTask> | string
    >;
    allActivity?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAllActivityArgs,
      WithTypename<ActivityConnection> | string
    >;
    asset?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAssetArgs,
      WithTypename<Asset> | string
    >;
    assetModels?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<AssetModel> | string>
    >;
    assetModelsFindByIds?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAssetModelsFindByIdsArgs,
      Array<WithTypename<AssetModel> | string>
    >;
    assetTypes?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    assets?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAssetsArgs,
      Array<WithTypename<Asset> | string>
    >;
    attributes?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAttributesArgs,
      Array<WithTypename<Attribute> | string>
    >;
    automation?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAutomationArgs,
      WithTypename<Automation> | string
    >;
    automations?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<Automation> | string>
    >;
    backgroundJob?: GraphCacheResolver<
      WithTypename<Query>,
      QueryBackgroundJobArgs,
      Scalars['String'] | string
    >;
    baseUrl?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    contact?: GraphCacheResolver<
      WithTypename<Query>,
      QueryContactArgs,
      WithTypename<Contact> | string
    >;
    contacts?: GraphCacheResolver<
      WithTypename<Query>,
      QueryContactsArgs,
      Array<WithTypename<Contact> | string>
    >;
    deployments?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<Deployment> | string>
    >;
    fsps?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<Fsp> | string>
    >;
    homheroAreas?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<HomheroArea> | string>
    >;
    inbox?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<Thread> | string>
    >;
    integrations?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    isOnboarding?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    items?: GraphCacheResolver<
      WithTypename<Query>,
      QueryItemsArgs,
      Array<WithTypename<Item> | string>
    >;
    job?: GraphCacheResolver<
      WithTypename<Query>,
      QueryJobArgs,
      WithTypename<Job> | string
    >;
    jobBoard?: GraphCacheResolver<
      WithTypename<Query>,
      QueryJobBoardArgs,
      Array<WithTypename<JobList> | string>
    >;
    jobCalendar?: GraphCacheResolver<
      WithTypename<Query>,
      QueryJobCalendarArgs,
      Array<WithTypename<JobCalendarEvent> | string>
    >;
    jobCalendarEvents?: GraphCacheResolver<
      WithTypename<Query>,
      QueryJobCalendarEventsArgs,
      Array<WithTypename<JobCalendarEvent> | string>
    >;
    jobNameOptions?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    jobTags?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    jobTemplates?: GraphCacheResolver<
      WithTypename<Query>,
      QueryJobTemplatesArgs,
      Array<WithTypename<JobTemplate> | string>
    >;
    jobs?: GraphCacheResolver<
      WithTypename<Query>,
      QueryJobsArgs,
      Array<WithTypename<Job> | string>
    >;
    location?: GraphCacheResolver<
      WithTypename<Query>,
      QueryLocationArgs,
      | WithTypename<Asset>
      | WithTypename<Contact>
      | WithTypename<Elsewhere>
      | WithTypename<Fsp>
      | WithTypename<Site>
      | WithTypename<StockTransfer>
      | string
    >;
    locations?: GraphCacheResolver<
      WithTypename<Query>,
      QueryLocationsArgs,
      Array<
        | WithTypename<Asset>
        | WithTypename<Contact>
        | WithTypename<Elsewhere>
        | WithTypename<Fsp>
        | WithTypename<Site>
        | WithTypename<StockTransfer>
        | string
      >
    >;
    movementTypes?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    session?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<Session> | string
    >;
    site?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySiteArgs,
      WithTypename<Site> | string
    >;
    siteTypes?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySiteTypesArgs,
      Array<Scalars['String'] | string>
    >;
    sites?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySitesArgs,
      Array<WithTypename<Site> | string>
    >;
    skus?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySkusArgs,
      Array<WithTypename<Sku> | string>
    >;
    spaceNames?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    spaceSkus?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySpaceSkusArgs,
      Array<WithTypename<SpaceSku> | string>
    >;
    spaces?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySpacesArgs,
      Array<WithTypename<Space> | string>
    >;
    spacesConnection?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySpacesConnectionArgs,
      WithTypename<SpaceConnection> | string
    >;
    stockAdjustmentTypes?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    stockOnHand?: GraphCacheResolver<
      WithTypename<Query>,
      QueryStockOnHandArgs,
      Array<WithTypename<SpaceSku> | string>
    >;
    tags?: GraphCacheResolver<
      WithTypename<Query>,
      QueryTagsArgs,
      WithTypename<TagConnection> | string
    >;
    task?: GraphCacheResolver<
      WithTypename<Query>,
      QueryTaskArgs,
      WithTypename<Task> | string
    >;
    users?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<TenantUser> | string>
    >;
  };
  Activity?: {
    comment?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      WithTypename<Comment> | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    createdBy?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      WithTypename<User> | string
    >;
    entry?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      WithTypename<JobHistory> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isFlagged?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isRead?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    job?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      WithTypename<Job> | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    tags?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      Array<WithTypename<Tag> | string>
    >;
    task?: GraphCacheResolver<
      WithTypename<Activity>,
      Record<string, never>,
      WithTypename<Task> | string
    >;
  };
  ActivityConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<ActivityConnection>,
      Record<string, never>,
      Array<WithTypename<ActivityEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<ActivityConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
    totalCount?: GraphCacheResolver<
      WithTypename<ActivityConnection>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    totalUnreadCount?: GraphCacheResolver<
      WithTypename<ActivityConnection>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  ActivityEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<ActivityEdge>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    node?: GraphCacheResolver<
      WithTypename<ActivityEdge>,
      Record<string, never>,
      WithTypename<Activity> | string
    >;
  };
  Asset?: {
    attachments?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<WithTypename<Attachment> | string>
    >;
    attributes?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    deployment?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      WithTypename<Deployment> | string
    >;
    deployments?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<WithTypename<Deployment> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    jobs?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<WithTypename<Job> | string>
    >;
    model?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      WithTypename<AssetModel> | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    spaces?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<WithTypename<Space> | string>
    >;
    status?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Status | string
    >;
    type?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  AssetModel?: {
    activeAssets?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    brand?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    displayName?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Status | string
    >;
    type?: GraphCacheResolver<
      WithTypename<AssetModel>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  AssetTaskConfig?: {
    allow?: GraphCacheResolver<
      WithTypename<AssetTaskConfig>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    assetTypes?: GraphCacheResolver<
      WithTypename<AssetTaskConfig>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    selection?: GraphCacheResolver<
      WithTypename<AssetTaskConfig>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    spaces?: GraphCacheResolver<
      WithTypename<AssetTaskConfig>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
  };
  Attachment?: {
    ext?: GraphCacheResolver<
      WithTypename<Attachment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Attachment>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<Attachment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    originalFilename?: GraphCacheResolver<
      WithTypename<Attachment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    thumb?: GraphCacheResolver<
      WithTypename<Attachment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    url?: GraphCacheResolver<
      WithTypename<Attachment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Attribute?: {
    attachments?: GraphCacheResolver<
      WithTypename<Attribute>,
      Record<string, never>,
      Array<WithTypename<Attachment> | string>
    >;
    category?: GraphCacheResolver<
      WithTypename<Attribute>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    condition?: GraphCacheResolver<
      WithTypename<Attribute>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Attribute>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Attribute>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    options?: GraphCacheResolver<
      WithTypename<Attribute>,
      Record<string, never>,
      Scalars['JSON'] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<Attribute>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    value?: GraphCacheResolver<
      WithTypename<Attribute>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
  };
  AttributeSingle?: {
    name?: GraphCacheResolver<
      WithTypename<AttributeSingle>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    value?: GraphCacheResolver<
      WithTypename<AttributeSingle>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  AttributeTask?: {
    attributes?: GraphCacheResolver<
      WithTypename<AttributeTask>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    formValues?: GraphCacheResolver<
      WithTypename<AttributeTask>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<AttributeTask>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  Automation?: {
    assignees?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Array<WithTypename<Contact> | string>
    >;
    conditions?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Array<WithTypename<AutomationCondition> | string>
    >;
    day?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    do?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    jobName?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    matchAttribute?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    name?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    on?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    owners?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Array<WithTypename<Contact> | string>
    >;
    repeatUntilOffset?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    sites?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Array<WithTypename<Site> | string>
    >;
    status?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Status | string
    >;
    template?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      WithTypename<JobTemplate> | string
    >;
    time?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    whereType?: GraphCacheResolver<
      WithTypename<Automation>,
      Record<string, never>,
      AutomationWhereType | string
    >;
  };
  AutomationCondition?: {
    nights?: GraphCacheResolver<
      WithTypename<AutomationCondition>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    operator?: GraphCacheResolver<
      WithTypename<AutomationCondition>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<AutomationCondition>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Booking?: {
    end?: GraphCacheResolver<
      WithTypename<Booking>,
      Record<string, never>,
      Scalars['LocalDateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Booking>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Booking>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    start?: GraphCacheResolver<
      WithTypename<Booking>,
      Record<string, never>,
      Scalars['LocalDateTime'] | string
    >;
  };
  CalendarSubscription?: {
    id?: GraphCacheResolver<
      WithTypename<CalendarSubscription>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    provider?: GraphCacheResolver<
      WithTypename<CalendarSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    syncedAt?: GraphCacheResolver<
      WithTypename<CalendarSubscription>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    url?: GraphCacheResolver<
      WithTypename<CalendarSubscription>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Card?: {
    attributes?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    capacity?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    dispatched?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    fill?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    packed?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    pick?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    picked?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    price?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    putAway?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    queuedPrice?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    queuedType?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      QueuedType | string
    >;
    received?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    reorderLevel?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    reserved?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    sku?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      WithTypename<Sku> | string
    >;
    skuId?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    soh?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    space?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      WithTypename<Space> | string
    >;
    spaceId?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Status | string
    >;
    stockTransferId?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    target?: GraphCacheResolver<
      WithTypename<Card>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Comment?: {
    attachments?: GraphCacheResolver<
      WithTypename<Comment>,
      Record<string, never>,
      Array<WithTypename<Attachment> | string>
    >;
    body?: GraphCacheResolver<
      WithTypename<Comment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Comment>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    createdBy?: GraphCacheResolver<
      WithTypename<Comment>,
      Record<string, never>,
      WithTypename<User> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Comment>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    isFlagged?: GraphCacheResolver<
      WithTypename<Comment>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isRead?: GraphCacheResolver<
      WithTypename<Comment>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    tags?: GraphCacheResolver<
      WithTypename<Comment>,
      Record<string, never>,
      Array<WithTypename<Tag> | string>
    >;
  };
  Contact?: {
    attributes?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    deployment?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      WithTypename<Deployment> | string
    >;
    email?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    isCurrentUser?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isTeamMember?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    organisation?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      WithTypename<Contact> | string
    >;
    organisationId?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    phone?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    postalAddress?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    relationship?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Array<ContactRelationship | string>
    >;
    spaces?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Array<WithTypename<Space> | string>
    >;
    status?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Status | string
    >;
    streetAddress?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      ContactType | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<Contact>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
  };
  Deployment?: {
    asset?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      WithTypename<Asset> | string
    >;
    assetId?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    defaultSourceId?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    displayLocation?: GraphCacheResolver<
      WithTypename<Deployment>,
      DeploymentDisplayLocationArgs,
      Scalars['String'] | string
    >;
    endedAt?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    location?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      | WithTypename<Asset>
      | WithTypename<Contact>
      | WithTypename<Elsewhere>
      | WithTypename<Fsp>
      | WithTypename<Site>
      | WithTypename<StockTransfer>
      | string
    >;
    owner?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    space?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      WithTypename<Space> | string
    >;
    spaceId?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    startedAt?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    state?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Deployment>,
      Record<string, never>,
      Status | string
    >;
  };
  Elsewhere?: {
    deployment?: GraphCacheResolver<
      WithTypename<Elsewhere>,
      Record<string, never>,
      WithTypename<Deployment> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Elsewhere>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Elsewhere>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    spaces?: GraphCacheResolver<
      WithTypename<Elsewhere>,
      Record<string, never>,
      Array<WithTypename<Space> | string>
    >;
  };
  Fsp?: {
    deployment?: GraphCacheResolver<
      WithTypename<Fsp>,
      Record<string, never>,
      WithTypename<Deployment> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Fsp>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Fsp>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Fsp>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    spaces?: GraphCacheResolver<
      WithTypename<Fsp>,
      Record<string, never>,
      Array<WithTypename<Space> | string>
    >;
  };
  HomheroArea?: {
    id?: GraphCacheResolver<
      WithTypename<HomheroArea>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<HomheroArea>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Invitation?: {
    email?: GraphCacheResolver<
      WithTypename<Invitation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Invitation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  Item?: {
    activeSkus?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    hasVariants?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    name?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    skuPrefix?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    skus?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Array<WithTypename<Sku> | string>
    >;
    status?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Status | string
    >;
    type?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      ItemType | string
    >;
    variantTypes?: GraphCacheResolver<
      WithTypename<Item>,
      Record<string, never>,
      Array<WithTypename<VariantType> | string>
    >;
  };
  ItemQty?: {
    id?: GraphCacheResolver<
      WithTypename<ItemQty>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    qty?: GraphCacheResolver<
      WithTypename<ItemQty>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  ItemTask?: {
    cards?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      Array<WithTypename<Card> | string>
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    lineItems?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      Array<WithTypename<StockTransferItem> | string>
    >;
    spaceSkus?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      Array<WithTypename<SpaceSku> | string>
    >;
    status?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      JobStatus | string
    >;
    stockTransfer?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      WithTypename<StockTransfer> | string
    >;
    type?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<ItemTask>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
  };
  Job?: {
    _dateCreated?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    _dateScheduled?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    assignee?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      WithTypename<Contact> | string
    >;
    assignees?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<WithTypename<Contact> | string>
    >;
    attachments?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<WithTypename<Attachment> | string>
    >;
    attributes?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    booking?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      WithTypename<Booking> | string
    >;
    canAcceptOffer?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    comments?: GraphCacheResolver<
      WithTypename<Job>,
      JobCommentsArgs,
      Array<WithTypename<JobFeed> | string>
    >;
    completedAt?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    customer?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      WithTypename<Contact> | string
    >;
    deploymentString?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    emailAddress?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['EmailAddress'] | string
    >;
    enforceOrder?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    guestyCleaningStatus?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    history?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<WithTypename<JobHistory> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    include?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<WithTypename<Contact> | string>
    >;
    isScheduled?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isSource?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    jobNumber?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    jobTags?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<WithTypename<Tag> | string>
    >;
    location?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      | WithTypename<Asset>
      | WithTypename<Contact>
      | WithTypename<Elsewhere>
      | WithTypename<Fsp>
      | WithTypename<Site>
      | WithTypename<StockTransfer>
      | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    nextCheckIn?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      WithTypename<Booking> | string
    >;
    nodeId?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    occupied?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      WithTypename<Booking> | string
    >;
    owner?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      WithTypename<Organisation> | string
    >;
    owners?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<WithTypename<Contact> | string>
    >;
    permissions?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    prevCheckOut?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      WithTypename<Booking> | string
    >;
    scheduleEnd?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    scheduleStart?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    scheduledEndDate?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['LocalDate'] | string
    >;
    scheduledEndStrict?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    scheduledEndTime?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['LocalTime'] | string
    >;
    scheduledStartDate?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['LocalDate'] | string
    >;
    scheduledStartStrict?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    scheduledStartTime?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['LocalTime'] | string
    >;
    site?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      WithTypename<Site> | string
    >;
    state?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      JobStatus | string
    >;
    tags?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    tasks?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Array<WithTypename<Task> | string>
    >;
    timeZone?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<Job>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
  };
  JobCalendarEvent?: {
    attributes?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    cancelled?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    date?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    end?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    hidden?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    icon?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    jobs?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Array<WithTypename<Job> | string>
    >;
    location?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      | WithTypename<Asset>
      | WithTypename<Contact>
      | WithTypename<Elsewhere>
      | WithTypename<Fsp>
      | WithTypename<Site>
      | WithTypename<StockTransfer>
      | string
    >;
    start?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    summary?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    timeZone?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    uid?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    url?: GraphCacheResolver<
      WithTypename<JobCalendarEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  JobHistory?: {
    createdAt?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    createdBy?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      WithTypename<User> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    inputDate?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    isFlagged?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    jobId?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    reverted?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      JobStatus | string
    >;
    task?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      WithTypename<Task> | string
    >;
    taskId?: GraphCacheResolver<
      WithTypename<JobHistory>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  JobTemplate?: {
    attachments?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Array<WithTypename<Attachment> | string>
    >;
    guestyCleaningStatus?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    includeAttributes?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    isStarter?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    location?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Status | string
    >;
    tags?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Array<WithTypename<Tag> | string>
    >;
    tasks?: GraphCacheResolver<
      WithTypename<JobTemplate>,
      Record<string, never>,
      Array<WithTypename<Task> | string>
    >;
  };
  JobsConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<JobsConnection>,
      Record<string, never>,
      Array<WithTypename<JobsEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<JobsConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
    totalCount?: GraphCacheResolver<
      WithTypename<JobsConnection>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  JobsEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<JobsEdge>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    node?: GraphCacheResolver<
      WithTypename<JobsEdge>,
      Record<string, never>,
      WithTypename<Job> | string
    >;
  };
  Onboarding?: {
    form?: GraphCacheResolver<
      WithTypename<Onboarding>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    hasNextStep?: GraphCacheResolver<
      WithTypename<Onboarding>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Onboarding>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    step?: GraphCacheResolver<
      WithTypename<Onboarding>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    version?: GraphCacheResolver<
      WithTypename<Onboarding>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    waitingFor?: GraphCacheResolver<
      WithTypename<Onboarding>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Organisation?: {
    id?: GraphCacheResolver<
      WithTypename<Organisation>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    logo?: GraphCacheResolver<
      WithTypename<Organisation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Organisation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  OtpGenerateResponse?: {
    id?: GraphCacheResolver<
      WithTypename<OtpGenerateResponse>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<OtpGenerateResponse>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  OtpVerifyResponse?: {
    success?: GraphCacheResolver<
      WithTypename<OtpVerifyResponse>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  PageInfo?: {
    endCursor?: GraphCacheResolver<
      WithTypename<PageInfo>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    hasNextPage?: GraphCacheResolver<
      WithTypename<PageInfo>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    hasPreviousPage?: GraphCacheResolver<
      WithTypename<PageInfo>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    startCursor?: GraphCacheResolver<
      WithTypename<PageInfo>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  PurchaseTask?: {
    id?: GraphCacheResolver<
      WithTypename<PurchaseTask>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    movements?: GraphCacheResolver<
      WithTypename<PurchaseTask>,
      Record<string, never>,
      Array<WithTypename<PurchaseTaskItem> | string>
    >;
    spaces?: GraphCacheResolver<
      WithTypename<PurchaseTask>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
  };
  PurchaseTaskItem?: {
    id?: GraphCacheResolver<
      WithTypename<PurchaseTaskItem>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    qty?: GraphCacheResolver<
      WithTypename<PurchaseTaskItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Role?: {
    key?: GraphCacheResolver<
      WithTypename<Role>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Role>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ScheduleEvent?: {
    assignee?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      WithTypename<Contact> | string
    >;
    assignee_id?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    attributes?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    days?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    end?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    included?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Array<WithTypename<Contact> | string>
    >;
    scheduledEndTime?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['LocalTime'] | string
    >;
    scheduledStartTime?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['LocalTime'] | string
    >;
    site_id?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    start?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    summary?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    tags?: GraphCacheResolver<
      WithTypename<ScheduleEvent>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
  };
  Session?: {
    user?: GraphCacheResolver<
      WithTypename<Session>,
      Record<string, never>,
      WithTypename<User> | string
    >;
  };
  Site?: {
    activeLocations?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    address?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    airbnbCalendar?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Array<WithTypename<CalendarSubscription> | string>
    >;
    airbnbDefaultCheckIn?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    airbnbDefaultCheckOut?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    attributes?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    calendar?: GraphCacheResolver<
      WithTypename<Site>,
      SiteCalendarArgs,
      Array<WithTypename<ScheduleEvent> | string>
    >;
    calendarBounds?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Array<Scalars['DateTime'] | string>
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    defaultSource?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      WithTypename<Site> | string
    >;
    deployment?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      WithTypename<Deployment> | string
    >;
    homhero?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      WithTypename<HomheroArea> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    integrationAttributes?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    jobs?: GraphCacheResolver<
      WithTypename<Site>,
      SiteJobsArgs,
      WithTypename<JobsConnection> | string
    >;
    licensor?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      WithTypename<Organisation> | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    spaces?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Array<WithTypename<Space> | string>
    >;
    state?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Status | string
    >;
    type?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<Site>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
  };
  Sku?: {
    _name?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    attributes?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Array<WithTypename<AttributeSingle> | string>
    >;
    code?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    item?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      WithTypename<Item> | string
    >;
    itemId?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    itemType?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      ItemType | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Status | string
    >;
    variant?: GraphCacheResolver<
      WithTypename<Sku>,
      Record<string, never>,
      Array<WithTypename<VariantValue> | string>
    >;
  };
  Space?: {
    activeSkus?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    attributes?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    deployments?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Array<WithTypename<Deployment> | string>
    >;
    description?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    location?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      | WithTypename<Asset>
      | WithTypename<Contact>
      | WithTypename<Elsewhere>
      | WithTypename<Fsp>
      | WithTypename<Site>
      | WithTypename<StockTransfer>
      | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    skus?: GraphCacheResolver<
      WithTypename<Space>,
      SpaceSkusArgs,
      Array<WithTypename<SpaceSku> | string>
    >;
    status?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Status | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<Space>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
  };
  SpaceConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<SpaceConnection>,
      Record<string, never>,
      Array<WithTypename<SpaceEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<SpaceConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
    totalCount?: GraphCacheResolver<
      WithTypename<SpaceConnection>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  SpaceEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<SpaceEdge>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    node?: GraphCacheResolver<
      WithTypename<SpaceEdge>,
      Record<string, never>,
      WithTypename<Space> | string
    >;
  };
  SpaceSku?: {
    attributes?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    capacity?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    price?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    queuedPrice?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    queuedType?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      QueuedType | string
    >;
    reorderLevel?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    reserved?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    sku?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      WithTypename<Sku> | string
    >;
    skuId?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    soh?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    space?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      WithTypename<Space> | string
    >;
    spaceId?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Status | string
    >;
    target?: GraphCacheResolver<
      WithTypename<SpaceSku>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  StockAdjustment?: {
    id?: GraphCacheResolver<
      WithTypename<StockAdjustment>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  StockTransfer?: {
    deployment?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      WithTypename<Deployment> | string
    >;
    destination?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      | WithTypename<Asset>
      | WithTypename<Contact>
      | WithTypename<Elsewhere>
      | WithTypename<Fsp>
      | WithTypename<Site>
      | WithTypename<StockTransfer>
      | string
    >;
    destinationString?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    fsp?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      WithTypename<Fsp> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    items?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      Array<WithTypename<StockTransferItem> | string>
    >;
    name?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    processQueuedChanges?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    rule?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      StockTransferRule | string
    >;
    selectStock?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      SelectStockOption | string
    >;
    source?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      | WithTypename<Asset>
      | WithTypename<Contact>
      | WithTypename<Elsewhere>
      | WithTypename<Fsp>
      | WithTypename<Site>
      | WithTypename<StockTransfer>
      | string
    >;
    sourceString?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    space?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      WithTypename<Space> | string
    >;
    spaces?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      Array<WithTypename<Space> | string>
    >;
    status?: GraphCacheResolver<
      WithTypename<StockTransfer>,
      Record<string, never>,
      StockTransferStatus | string
    >;
  };
  StockTransferItem?: {
    dispatched?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    packed?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    pick?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    picked?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    putAway?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    received?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    skuId?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    spaceId?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    stockTransferId?: GraphCacheResolver<
      WithTypename<StockTransferItem>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
  };
  Stocktake?: {
    id?: GraphCacheResolver<
      WithTypename<Stocktake>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    include?: GraphCacheResolver<
      WithTypename<Stocktake>,
      Record<string, never>,
      Array<ItemType | string>
    >;
    itemStatus?: GraphCacheResolver<
      WithTypename<Stocktake>,
      Record<string, never>,
      Array<Status | string>
    >;
    items?: GraphCacheResolver<
      WithTypename<Stocktake>,
      Record<string, never>,
      Array<WithTypename<StocktakeItem> | string>
    >;
    spaces?: GraphCacheResolver<
      WithTypename<Stocktake>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
  };
  StocktakeItem?: {
    adjustment?: GraphCacheResolver<
      WithTypename<StocktakeItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<StocktakeItem>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    newOnHand?: GraphCacheResolver<
      WithTypename<StocktakeItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    startOnHand?: GraphCacheResolver<
      WithTypename<StocktakeItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Tag?: {
    category?: GraphCacheResolver<
      WithTypename<Tag>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    colour?: GraphCacheResolver<
      WithTypename<Tag>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<Tag>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    entityType?: GraphCacheResolver<
      WithTypename<Tag>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Tag>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Tag>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  TagConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<TagConnection>,
      Record<string, never>,
      Array<WithTypename<TagEdge> | string>
    >;
    nodes?: GraphCacheResolver<
      WithTypename<TagConnection>,
      Record<string, never>,
      Array<WithTypename<Tag> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<TagConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
    totalCount?: GraphCacheResolver<
      WithTypename<TagConnection>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  TagEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<TagEdge>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    node?: GraphCacheResolver<
      WithTypename<TagEdge>,
      Record<string, never>,
      WithTypename<Tag> | string
    >;
  };
  Task?: {
    assets?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<WithTypename<Asset> | string>
    >;
    attachments?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<WithTypename<Attachment> | string>
    >;
    attribute?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<AttributeTask> | string
    >;
    attributes?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    config?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<AssetTaskConfig> | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    job?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<Job> | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    purchase?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<PurchaseTask> | string
    >;
    required?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      JobStatus | string
    >;
    stockTransfer?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<StockTransfer> | string
    >;
    stocktake?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<Stocktake> | string
    >;
    tags?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<TagConnection> | string
    >;
    transfer?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<TransferTask> | string
    >;
    type?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
  };
  Thread?: {
    count?: GraphCacheResolver<
      WithTypename<Thread>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Thread>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    lastAuthor?: GraphCacheResolver<
      WithTypename<Thread>,
      Record<string, never>,
      WithTypename<User> | string
    >;
    lastMessage?: GraphCacheResolver<
      WithTypename<Thread>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    lastUpdate?: GraphCacheResolver<
      WithTypename<Thread>,
      Record<string, never>,
      Scalars['DateTime'] | string
    >;
    title?: GraphCacheResolver<
      WithTypename<Thread>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  TransferTask?: {
    available?: GraphCacheResolver<
      WithTypename<TransferTask>,
      Record<string, never>,
      Array<WithTypename<ItemQty> | string>
    >;
    moved?: GraphCacheResolver<
      WithTypename<TransferTask>,
      Record<string, never>,
      Array<WithTypename<ItemQty> | string>
    >;
    stockOnHand?: GraphCacheResolver<
      WithTypename<TransferTask>,
      Record<string, never>,
      Array<WithTypename<SpaceSku> | string>
    >;
  };
  Upload?: {
    baseUrl?: GraphCacheResolver<
      WithTypename<Upload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    ext?: GraphCacheResolver<
      WithTypename<Upload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<Upload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    originalFilename?: GraphCacheResolver<
      WithTypename<Upload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    presignedUrl?: GraphCacheResolver<
      WithTypename<Upload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  User?: {
    attributes?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<WithTypename<Attribute> | string>
    >;
    email?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars['ID'] | string
    >;
    image?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    images?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    isOwner?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    permissions?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
    roles?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<WithTypename<Role> | string>
    >;
    status?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Status | string
    >;
  };
  VariantType?: {
    name?: GraphCacheResolver<
      WithTypename<VariantType>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    values?: GraphCacheResolver<
      WithTypename<VariantType>,
      Record<string, never>,
      Array<Scalars['String'] | string>
    >;
  };
  VariantValue?: {
    name?: GraphCacheResolver<
      WithTypename<VariantValue>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    value?: GraphCacheResolver<
      WithTypename<VariantValue>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
};

export type GraphCacheOptimisticUpdaters = {
  acceptJob?: GraphCacheOptimisticMutationResolver<
    MutationAcceptJobArgs,
    Maybe<WithTypename<Job>>
  >;
  addComment?: GraphCacheOptimisticMutationResolver<
    MutationAddCommentArgs,
    WithTypename<Comment>
  >;
  addItem?: GraphCacheOptimisticMutationResolver<
    MutationAddItemArgs,
    WithTypename<Item>
  >;
  addTagsToActivity?: GraphCacheOptimisticMutationResolver<
    MutationAddTagsToActivityArgs,
    WithTypename<Activity>
  >;
  addTagsToJob?: GraphCacheOptimisticMutationResolver<
    MutationAddTagsToJobArgs,
    Array<WithTypename<Job>>
  >;
  addTagsToTaggable?: GraphCacheOptimisticMutationResolver<
    MutationAddTagsToTaggableArgs,
    WithTypename<Task>
  >;
  adjustStock?: GraphCacheOptimisticMutationResolver<
    MutationAdjustStockArgs,
    WithTypename<StockAdjustment>
  >;
  assignJobs?: GraphCacheOptimisticMutationResolver<
    MutationAssignJobsArgs,
    Maybe<Array<WithTypename<Job>>>
  >;
  cancelJob?: GraphCacheOptimisticMutationResolver<
    MutationCancelJobArgs,
    Maybe<WithTypename<Job>>
  >;
  cancelJobs?: GraphCacheOptimisticMutationResolver<
    MutationCancelJobsArgs,
    Maybe<Array<WithTypename<Job>>>
  >;
  changeEmailAddress?: GraphCacheOptimisticMutationResolver<
    MutationChangeEmailAddressArgs,
    Scalars['Int']
  >;
  changePassword?: GraphCacheOptimisticMutationResolver<
    MutationChangePasswordArgs,
    Scalars['Int']
  >;
  completeAttributeAuditTask?: GraphCacheOptimisticMutationResolver<
    MutationCompleteAttributeAuditTaskArgs,
    WithTypename<Task>
  >;
  completeAttributeTask?: GraphCacheOptimisticMutationResolver<
    MutationCompleteAttributeTaskArgs,
    Scalars['Int']
  >;
  completeCustomTask?: GraphCacheOptimisticMutationResolver<
    MutationCompleteCustomTaskArgs,
    WithTypename<Task>
  >;
  completeItemTask?: GraphCacheOptimisticMutationResolver<
    MutationCompleteItemTaskArgs,
    WithTypename<Task>
  >;
  completeJob?: GraphCacheOptimisticMutationResolver<
    MutationCompleteJobArgs,
    Maybe<WithTypename<Job>>
  >;
  completeJobs?: GraphCacheOptimisticMutationResolver<
    MutationCompleteJobsArgs,
    Maybe<Array<WithTypename<Job>>>
  >;
  completePurchaseTask?: GraphCacheOptimisticMutationResolver<
    MutationCompletePurchaseTaskArgs,
    Scalars['Int']
  >;
  createAsset?: GraphCacheOptimisticMutationResolver<
    MutationCreateAssetArgs,
    WithTypename<Asset>
  >;
  createAssetModel?: GraphCacheOptimisticMutationResolver<
    MutationCreateAssetModelArgs,
    WithTypename<AssetModel>
  >;
  createAutomation?: GraphCacheOptimisticMutationResolver<
    MutationCreateAutomationArgs,
    Maybe<Scalars['ID']>
  >;
  createContact?: GraphCacheOptimisticMutationResolver<
    MutationCreateContactArgs,
    Maybe<WithTypename<Contact>>
  >;
  createDeployment?: GraphCacheOptimisticMutationResolver<
    MutationCreateDeploymentArgs,
    WithTypename<Deployment>
  >;
  createJobTemplate?: GraphCacheOptimisticMutationResolver<
    MutationCreateJobTemplateArgs,
    WithTypename<JobTemplate>
  >;
  createJobs?: GraphCacheOptimisticMutationResolver<
    MutationCreateJobsArgs,
    Array<WithTypename<Job>>
  >;
  createSite?: GraphCacheOptimisticMutationResolver<
    MutationCreateSiteArgs,
    WithTypename<Site>
  >;
  createSpace?: GraphCacheOptimisticMutationResolver<
    MutationCreateSpaceArgs,
    WithTypename<Space>
  >;
  createTag?: GraphCacheOptimisticMutationResolver<
    MutationCreateTagArgs,
    WithTypename<Tag>
  >;
  createTenant?: GraphCacheOptimisticMutationResolver<
    MutationCreateTenantArgs,
    Scalars['ID']
  >;
  createUpload?: GraphCacheOptimisticMutationResolver<
    MutationCreateUploadArgs,
    WithTypename<Upload>
  >;
  declineJob?: GraphCacheOptimisticMutationResolver<
    MutationDeclineJobArgs,
    Maybe<WithTypename<Job>>
  >;
  deleteJob?: GraphCacheOptimisticMutationResolver<
    MutationDeleteJobArgs,
    Scalars['Int']
  >;
  deleteJobTemplate?: GraphCacheOptimisticMutationResolver<
    MutationDeleteJobTemplateArgs,
    Scalars['Int']
  >;
  deleteTag?: GraphCacheOptimisticMutationResolver<
    MutationDeleteTagArgs,
    Scalars['Boolean']
  >;
  endTask?: GraphCacheOptimisticMutationResolver<
    MutationEndTaskArgs,
    Maybe<WithTypename<Task>>
  >;
  finishStocktake?: GraphCacheOptimisticMutationResolver<
    MutationFinishStocktakeArgs,
    Scalars['Int']
  >;
  flagMessage?: GraphCacheOptimisticMutationResolver<
    MutationFlagMessageArgs,
    Scalars['Boolean']
  >;
  generatePicks?: GraphCacheOptimisticMutationResolver<
    MutationGeneratePicksArgs,
    Scalars['String']
  >;
  guestySetup?: GraphCacheOptimisticMutationResolver<
    MutationGuestySetupArgs,
    Maybe<Scalars['String']>
  >;
  hideJobCalendarEvent?: GraphCacheOptimisticMutationResolver<
    MutationHideJobCalendarEventArgs,
    WithTypename<JobCalendarEvent>
  >;
  inviteUser?: GraphCacheOptimisticMutationResolver<
    MutationInviteUserArgs,
    WithTypename<Invitation>
  >;
  login?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    WithTypename<Session>
  >;
  markAllAsRead?: GraphCacheOptimisticMutationResolver<
    MutationMarkAllAsReadArgs,
    Scalars['Int']
  >;
  markAsRead?: GraphCacheOptimisticMutationResolver<
    MutationMarkAsReadArgs,
    Scalars['Int']
  >;
  markAsReadV2?: GraphCacheOptimisticMutationResolver<
    MutationMarkAsReadV2Args,
    WithTypename<Activity>
  >;
  offerJob?: GraphCacheOptimisticMutationResolver<
    MutationOfferJobArgs,
    Maybe<WithTypename<Job>>
  >;
  onboard?: GraphCacheOptimisticMutationResolver<
    MutationOnboardArgs,
    WithTypename<Onboarding>
  >;
  otpGenerate?: GraphCacheOptimisticMutationResolver<
    MutationOtpGenerateArgs,
    WithTypename<OtpGenerateResponse>
  >;
  otpVerify?: GraphCacheOptimisticMutationResolver<
    MutationOtpVerifyArgs,
    WithTypename<OtpVerifyResponse>
  >;
  patchOrganisationProfile?: GraphCacheOptimisticMutationResolver<
    MutationPatchOrganisationProfileArgs,
    Scalars['Int']
  >;
  pauseJob?: GraphCacheOptimisticMutationResolver<
    MutationPauseJobArgs,
    Maybe<WithTypename<Job>>
  >;
  pauseTask?: GraphCacheOptimisticMutationResolver<
    MutationPauseTaskArgs,
    Maybe<WithTypename<Task>>
  >;
  quickAdd?: GraphCacheOptimisticMutationResolver<
    MutationQuickAddArgs,
    WithTypename<SpaceSku>
  >;
  removeTagsFromActivity?: GraphCacheOptimisticMutationResolver<
    MutationRemoveTagsFromActivityArgs,
    WithTypename<Activity>
  >;
  removeTagsFromJob?: GraphCacheOptimisticMutationResolver<
    MutationRemoveTagsFromJobArgs,
    Array<WithTypename<Job>>
  >;
  removeTagsFromTaggable?: GraphCacheOptimisticMutationResolver<
    MutationRemoveTagsFromTaggableArgs,
    WithTypename<Task>
  >;
  removeUser?: GraphCacheOptimisticMutationResolver<
    MutationRemoveUserArgs,
    Scalars['Boolean']
  >;
  rescheduleJob?: GraphCacheOptimisticMutationResolver<
    MutationRescheduleJobArgs,
    WithTypename<Job>
  >;
  rescheduleJobs?: GraphCacheOptimisticMutationResolver<
    MutationRescheduleJobsArgs,
    Array<WithTypename<Job>>
  >;
  resetTaskStatus?: GraphCacheOptimisticMutationResolver<
    MutationResetTaskStatusArgs,
    Maybe<WithTypename<Task>>
  >;
  reslySetup?: GraphCacheOptimisticMutationResolver<
    MutationReslySetupArgs,
    Maybe<Scalars['String']>
  >;
  resumeJob?: GraphCacheOptimisticMutationResolver<
    MutationResumeJobArgs,
    Maybe<WithTypename<Job>>
  >;
  resumeTask?: GraphCacheOptimisticMutationResolver<
    MutationResumeTaskArgs,
    Maybe<WithTypename<Task>>
  >;
  revertJobStatus?: GraphCacheOptimisticMutationResolver<
    MutationRevertJobStatusArgs,
    Maybe<WithTypename<Job>>
  >;
  saveNotificationPreferences?: GraphCacheOptimisticMutationResolver<
    MutationSaveNotificationPreferencesArgs,
    Scalars['Int']
  >;
  sendVerificationEmail?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    Scalars['Boolean']
  >;
  setProfilePic?: GraphCacheOptimisticMutationResolver<
    MutationSetProfilePicArgs,
    WithTypename<User>
  >;
  sortJob?: GraphCacheOptimisticMutationResolver<
    MutationSortJobArgs,
    Scalars['Boolean']
  >;
  startJob?: GraphCacheOptimisticMutationResolver<
    MutationStartJobArgs,
    Maybe<WithTypename<Job>>
  >;
  startTask?: GraphCacheOptimisticMutationResolver<
    MutationStartTaskArgs,
    Maybe<WithTypename<Task>>
  >;
  updateAccountProfile?: GraphCacheOptimisticMutationResolver<
    MutationUpdateAccountProfileArgs,
    Scalars['Boolean']
  >;
  updateAsset?: GraphCacheOptimisticMutationResolver<
    MutationUpdateAssetArgs,
    WithTypename<Asset>
  >;
  updateAssetModel?: GraphCacheOptimisticMutationResolver<
    MutationUpdateAssetModelArgs,
    WithTypename<AssetModel>
  >;
  updateAutomation?: GraphCacheOptimisticMutationResolver<
    MutationUpdateAutomationArgs,
    Maybe<Scalars['ID']>
  >;
  updateContact?: GraphCacheOptimisticMutationResolver<
    MutationUpdateContactArgs,
    Maybe<WithTypename<Contact>>
  >;
  updateDeployment?: GraphCacheOptimisticMutationResolver<
    MutationUpdateDeploymentArgs,
    Scalars['Int']
  >;
  updateItem?: GraphCacheOptimisticMutationResolver<
    MutationUpdateItemArgs,
    WithTypename<Item>
  >;
  updateJob?: GraphCacheOptimisticMutationResolver<
    MutationUpdateJobArgs,
    WithTypename<Job>
  >;
  updateJobTemplate?: GraphCacheOptimisticMutationResolver<
    MutationUpdateJobTemplateArgs,
    WithTypename<JobTemplate>
  >;
  updateJobTemplates?: GraphCacheOptimisticMutationResolver<
    MutationUpdateJobTemplatesArgs,
    Maybe<Array<WithTypename<Job>>>
  >;
  updatePersonalInfo?: GraphCacheOptimisticMutationResolver<
    MutationUpdatePersonalInfoArgs,
    Scalars['Int']
  >;
  updateSite?: GraphCacheOptimisticMutationResolver<
    MutationUpdateSiteArgs,
    WithTypename<Site>
  >;
  updateSpace?: GraphCacheOptimisticMutationResolver<
    MutationUpdateSpaceArgs,
    WithTypename<Space>
  >;
  updateSpaceSku?: GraphCacheOptimisticMutationResolver<
    MutationUpdateSpaceSkuArgs,
    WithTypename<SpaceSku>
  >;
  updateTag?: GraphCacheOptimisticMutationResolver<
    MutationUpdateTagArgs,
    WithTypename<Tag>
  >;
};

export type GraphCacheUpdaters = {
  Query?: {
    activityTags?: GraphCacheUpdateResolver<
      { activityTags: Array<Scalars['String']> },
      Record<string, never>
    >;
    addItemsTask?: GraphCacheUpdateResolver<
      { addItemsTask: WithTypename<ItemTask> },
      QueryAddItemsTaskArgs
    >;
    allActivity?: GraphCacheUpdateResolver<
      { allActivity: WithTypename<ActivityConnection> },
      QueryAllActivityArgs
    >;
    asset?: GraphCacheUpdateResolver<
      { asset: Maybe<WithTypename<Asset>> },
      QueryAssetArgs
    >;
    assetModels?: GraphCacheUpdateResolver<
      { assetModels: Array<WithTypename<AssetModel>> },
      Record<string, never>
    >;
    assetModelsFindByIds?: GraphCacheUpdateResolver<
      { assetModelsFindByIds: Array<WithTypename<AssetModel>> },
      QueryAssetModelsFindByIdsArgs
    >;
    assetTypes?: GraphCacheUpdateResolver<
      { assetTypes: Array<Scalars['String']> },
      Record<string, never>
    >;
    assets?: GraphCacheUpdateResolver<
      { assets: Array<WithTypename<Asset>> },
      QueryAssetsArgs
    >;
    attributes?: GraphCacheUpdateResolver<
      { attributes: Array<WithTypename<Attribute>> },
      QueryAttributesArgs
    >;
    automation?: GraphCacheUpdateResolver<
      { automation: Maybe<WithTypename<Automation>> },
      QueryAutomationArgs
    >;
    automations?: GraphCacheUpdateResolver<
      { automations: Array<WithTypename<Automation>> },
      Record<string, never>
    >;
    backgroundJob?: GraphCacheUpdateResolver<
      { backgroundJob: Scalars['String'] },
      QueryBackgroundJobArgs
    >;
    baseUrl?: GraphCacheUpdateResolver<
      { baseUrl: Scalars['String'] },
      Record<string, never>
    >;
    contact?: GraphCacheUpdateResolver<
      { contact: Maybe<WithTypename<Contact>> },
      QueryContactArgs
    >;
    contacts?: GraphCacheUpdateResolver<
      { contacts: Array<WithTypename<Contact>> },
      QueryContactsArgs
    >;
    deployments?: GraphCacheUpdateResolver<
      { deployments: Array<WithTypename<Deployment>> },
      Record<string, never>
    >;
    fsps?: GraphCacheUpdateResolver<
      { fsps: Array<WithTypename<Fsp>> },
      Record<string, never>
    >;
    homheroAreas?: GraphCacheUpdateResolver<
      { homheroAreas: Maybe<Array<WithTypename<HomheroArea>>> },
      Record<string, never>
    >;
    inbox?: GraphCacheUpdateResolver<
      { inbox: Array<WithTypename<Thread>> },
      Record<string, never>
    >;
    integrations?: GraphCacheUpdateResolver<
      { integrations: Array<Scalars['String']> },
      Record<string, never>
    >;
    isOnboarding?: GraphCacheUpdateResolver<
      { isOnboarding: Scalars['Boolean'] },
      Record<string, never>
    >;
    items?: GraphCacheUpdateResolver<
      { items: Array<WithTypename<Item>> },
      QueryItemsArgs
    >;
    job?: GraphCacheUpdateResolver<
      { job: Maybe<WithTypename<Job>> },
      QueryJobArgs
    >;
    jobBoard?: GraphCacheUpdateResolver<
      { jobBoard: Array<WithTypename<JobList>> },
      QueryJobBoardArgs
    >;
    jobCalendar?: GraphCacheUpdateResolver<
      { jobCalendar: Array<WithTypename<JobCalendarEvent>> },
      QueryJobCalendarArgs
    >;
    jobCalendarEvents?: GraphCacheUpdateResolver<
      { jobCalendarEvents: Array<WithTypename<JobCalendarEvent>> },
      QueryJobCalendarEventsArgs
    >;
    jobNameOptions?: GraphCacheUpdateResolver<
      { jobNameOptions: Array<Scalars['String']> },
      Record<string, never>
    >;
    jobTags?: GraphCacheUpdateResolver<
      { jobTags: Array<Scalars['String']> },
      Record<string, never>
    >;
    jobTemplates?: GraphCacheUpdateResolver<
      { jobTemplates: Array<WithTypename<JobTemplate>> },
      QueryJobTemplatesArgs
    >;
    jobs?: GraphCacheUpdateResolver<
      { jobs: Array<WithTypename<Job>> },
      QueryJobsArgs
    >;
    location?: GraphCacheUpdateResolver<
      {
        location:
          | WithTypename<Asset>
          | WithTypename<Contact>
          | WithTypename<Elsewhere>
          | WithTypename<Fsp>
          | WithTypename<Site>
          | WithTypename<StockTransfer>;
      },
      QueryLocationArgs
    >;
    locations?: GraphCacheUpdateResolver<
      {
        locations: Array<
          | WithTypename<Asset>
          | WithTypename<Contact>
          | WithTypename<Elsewhere>
          | WithTypename<Fsp>
          | WithTypename<Site>
          | WithTypename<StockTransfer>
        >;
      },
      QueryLocationsArgs
    >;
    movementTypes?: GraphCacheUpdateResolver<
      { movementTypes: Array<Scalars['String']> },
      Record<string, never>
    >;
    session?: GraphCacheUpdateResolver<
      { session: WithTypename<Session> },
      Record<string, never>
    >;
    site?: GraphCacheUpdateResolver<
      { site: WithTypename<Site> },
      QuerySiteArgs
    >;
    siteTypes?: GraphCacheUpdateResolver<
      { siteTypes: Array<Scalars['String']> },
      QuerySiteTypesArgs
    >;
    sites?: GraphCacheUpdateResolver<
      { sites: Array<WithTypename<Site>> },
      QuerySitesArgs
    >;
    skus?: GraphCacheUpdateResolver<
      { skus: Array<WithTypename<Sku>> },
      QuerySkusArgs
    >;
    spaceNames?: GraphCacheUpdateResolver<
      { spaceNames: Array<Scalars['String']> },
      Record<string, never>
    >;
    spaceSkus?: GraphCacheUpdateResolver<
      { spaceSkus: Array<WithTypename<SpaceSku>> },
      QuerySpaceSkusArgs
    >;
    spaces?: GraphCacheUpdateResolver<
      { spaces: Array<WithTypename<Space>> },
      QuerySpacesArgs
    >;
    spacesConnection?: GraphCacheUpdateResolver<
      { spacesConnection: WithTypename<SpaceConnection> },
      QuerySpacesConnectionArgs
    >;
    stockAdjustmentTypes?: GraphCacheUpdateResolver<
      { stockAdjustmentTypes: Array<Scalars['String']> },
      Record<string, never>
    >;
    stockOnHand?: GraphCacheUpdateResolver<
      { stockOnHand: Array<WithTypename<SpaceSku>> },
      QueryStockOnHandArgs
    >;
    tags?: GraphCacheUpdateResolver<
      { tags: WithTypename<TagConnection> },
      QueryTagsArgs
    >;
    task?: GraphCacheUpdateResolver<
      { task: WithTypename<Task> },
      QueryTaskArgs
    >;
    users?: GraphCacheUpdateResolver<
      { users: Array<WithTypename<TenantUser>> },
      Record<string, never>
    >;
  };
  Mutation?: {
    acceptJob?: GraphCacheUpdateResolver<
      { acceptJob: Maybe<WithTypename<Job>> },
      MutationAcceptJobArgs
    >;
    addComment?: GraphCacheUpdateResolver<
      { addComment: WithTypename<Comment> },
      MutationAddCommentArgs
    >;
    addItem?: GraphCacheUpdateResolver<
      { addItem: WithTypename<Item> },
      MutationAddItemArgs
    >;
    addTagsToActivity?: GraphCacheUpdateResolver<
      { addTagsToActivity: WithTypename<Activity> },
      MutationAddTagsToActivityArgs
    >;
    addTagsToJob?: GraphCacheUpdateResolver<
      { addTagsToJob: Array<WithTypename<Job>> },
      MutationAddTagsToJobArgs
    >;
    addTagsToTaggable?: GraphCacheUpdateResolver<
      { addTagsToTaggable: WithTypename<Task> },
      MutationAddTagsToTaggableArgs
    >;
    adjustStock?: GraphCacheUpdateResolver<
      { adjustStock: WithTypename<StockAdjustment> },
      MutationAdjustStockArgs
    >;
    assignJobs?: GraphCacheUpdateResolver<
      { assignJobs: Maybe<Array<WithTypename<Job>>> },
      MutationAssignJobsArgs
    >;
    cancelJob?: GraphCacheUpdateResolver<
      { cancelJob: Maybe<WithTypename<Job>> },
      MutationCancelJobArgs
    >;
    cancelJobs?: GraphCacheUpdateResolver<
      { cancelJobs: Maybe<Array<WithTypename<Job>>> },
      MutationCancelJobsArgs
    >;
    changeEmailAddress?: GraphCacheUpdateResolver<
      { changeEmailAddress: Scalars['Int'] },
      MutationChangeEmailAddressArgs
    >;
    changePassword?: GraphCacheUpdateResolver<
      { changePassword: Scalars['Int'] },
      MutationChangePasswordArgs
    >;
    completeAttributeAuditTask?: GraphCacheUpdateResolver<
      { completeAttributeAuditTask: WithTypename<Task> },
      MutationCompleteAttributeAuditTaskArgs
    >;
    completeAttributeTask?: GraphCacheUpdateResolver<
      { completeAttributeTask: Scalars['Int'] },
      MutationCompleteAttributeTaskArgs
    >;
    completeCustomTask?: GraphCacheUpdateResolver<
      { completeCustomTask: WithTypename<Task> },
      MutationCompleteCustomTaskArgs
    >;
    completeItemTask?: GraphCacheUpdateResolver<
      { completeItemTask: WithTypename<Task> },
      MutationCompleteItemTaskArgs
    >;
    completeJob?: GraphCacheUpdateResolver<
      { completeJob: Maybe<WithTypename<Job>> },
      MutationCompleteJobArgs
    >;
    completeJobs?: GraphCacheUpdateResolver<
      { completeJobs: Maybe<Array<WithTypename<Job>>> },
      MutationCompleteJobsArgs
    >;
    completePurchaseTask?: GraphCacheUpdateResolver<
      { completePurchaseTask: Scalars['Int'] },
      MutationCompletePurchaseTaskArgs
    >;
    createAsset?: GraphCacheUpdateResolver<
      { createAsset: WithTypename<Asset> },
      MutationCreateAssetArgs
    >;
    createAssetModel?: GraphCacheUpdateResolver<
      { createAssetModel: WithTypename<AssetModel> },
      MutationCreateAssetModelArgs
    >;
    createAutomation?: GraphCacheUpdateResolver<
      { createAutomation: Maybe<Scalars['ID']> },
      MutationCreateAutomationArgs
    >;
    createContact?: GraphCacheUpdateResolver<
      { createContact: Maybe<WithTypename<Contact>> },
      MutationCreateContactArgs
    >;
    createDeployment?: GraphCacheUpdateResolver<
      { createDeployment: WithTypename<Deployment> },
      MutationCreateDeploymentArgs
    >;
    createJobTemplate?: GraphCacheUpdateResolver<
      { createJobTemplate: WithTypename<JobTemplate> },
      MutationCreateJobTemplateArgs
    >;
    createJobs?: GraphCacheUpdateResolver<
      { createJobs: Array<WithTypename<Job>> },
      MutationCreateJobsArgs
    >;
    createSite?: GraphCacheUpdateResolver<
      { createSite: WithTypename<Site> },
      MutationCreateSiteArgs
    >;
    createSpace?: GraphCacheUpdateResolver<
      { createSpace: WithTypename<Space> },
      MutationCreateSpaceArgs
    >;
    createTag?: GraphCacheUpdateResolver<
      { createTag: WithTypename<Tag> },
      MutationCreateTagArgs
    >;
    createTenant?: GraphCacheUpdateResolver<
      { createTenant: Scalars['ID'] },
      MutationCreateTenantArgs
    >;
    createUpload?: GraphCacheUpdateResolver<
      { createUpload: WithTypename<Upload> },
      MutationCreateUploadArgs
    >;
    declineJob?: GraphCacheUpdateResolver<
      { declineJob: Maybe<WithTypename<Job>> },
      MutationDeclineJobArgs
    >;
    deleteJob?: GraphCacheUpdateResolver<
      { deleteJob: Scalars['Int'] },
      MutationDeleteJobArgs
    >;
    deleteJobTemplate?: GraphCacheUpdateResolver<
      { deleteJobTemplate: Scalars['Int'] },
      MutationDeleteJobTemplateArgs
    >;
    deleteTag?: GraphCacheUpdateResolver<
      { deleteTag: Scalars['Boolean'] },
      MutationDeleteTagArgs
    >;
    endTask?: GraphCacheUpdateResolver<
      { endTask: Maybe<WithTypename<Task>> },
      MutationEndTaskArgs
    >;
    finishStocktake?: GraphCacheUpdateResolver<
      { finishStocktake: Scalars['Int'] },
      MutationFinishStocktakeArgs
    >;
    flagMessage?: GraphCacheUpdateResolver<
      { flagMessage: Scalars['Boolean'] },
      MutationFlagMessageArgs
    >;
    generatePicks?: GraphCacheUpdateResolver<
      { generatePicks: Scalars['String'] },
      MutationGeneratePicksArgs
    >;
    guestySetup?: GraphCacheUpdateResolver<
      { guestySetup: Maybe<Scalars['String']> },
      MutationGuestySetupArgs
    >;
    hideJobCalendarEvent?: GraphCacheUpdateResolver<
      { hideJobCalendarEvent: WithTypename<JobCalendarEvent> },
      MutationHideJobCalendarEventArgs
    >;
    inviteUser?: GraphCacheUpdateResolver<
      { inviteUser: WithTypename<Invitation> },
      MutationInviteUserArgs
    >;
    login?: GraphCacheUpdateResolver<
      { login: WithTypename<Session> },
      Record<string, never>
    >;
    markAllAsRead?: GraphCacheUpdateResolver<
      { markAllAsRead: Scalars['Int'] },
      MutationMarkAllAsReadArgs
    >;
    markAsRead?: GraphCacheUpdateResolver<
      { markAsRead: Scalars['Int'] },
      MutationMarkAsReadArgs
    >;
    markAsReadV2?: GraphCacheUpdateResolver<
      { markAsReadV2: WithTypename<Activity> },
      MutationMarkAsReadV2Args
    >;
    offerJob?: GraphCacheUpdateResolver<
      { offerJob: Maybe<WithTypename<Job>> },
      MutationOfferJobArgs
    >;
    onboard?: GraphCacheUpdateResolver<
      { onboard: WithTypename<Onboarding> },
      MutationOnboardArgs
    >;
    otpGenerate?: GraphCacheUpdateResolver<
      { otpGenerate: WithTypename<OtpGenerateResponse> },
      MutationOtpGenerateArgs
    >;
    otpVerify?: GraphCacheUpdateResolver<
      { otpVerify: WithTypename<OtpVerifyResponse> },
      MutationOtpVerifyArgs
    >;
    patchOrganisationProfile?: GraphCacheUpdateResolver<
      { patchOrganisationProfile: Scalars['Int'] },
      MutationPatchOrganisationProfileArgs
    >;
    pauseJob?: GraphCacheUpdateResolver<
      { pauseJob: Maybe<WithTypename<Job>> },
      MutationPauseJobArgs
    >;
    pauseTask?: GraphCacheUpdateResolver<
      { pauseTask: Maybe<WithTypename<Task>> },
      MutationPauseTaskArgs
    >;
    quickAdd?: GraphCacheUpdateResolver<
      { quickAdd: WithTypename<SpaceSku> },
      MutationQuickAddArgs
    >;
    removeTagsFromActivity?: GraphCacheUpdateResolver<
      { removeTagsFromActivity: WithTypename<Activity> },
      MutationRemoveTagsFromActivityArgs
    >;
    removeTagsFromJob?: GraphCacheUpdateResolver<
      { removeTagsFromJob: Array<WithTypename<Job>> },
      MutationRemoveTagsFromJobArgs
    >;
    removeTagsFromTaggable?: GraphCacheUpdateResolver<
      { removeTagsFromTaggable: WithTypename<Task> },
      MutationRemoveTagsFromTaggableArgs
    >;
    removeUser?: GraphCacheUpdateResolver<
      { removeUser: Scalars['Boolean'] },
      MutationRemoveUserArgs
    >;
    rescheduleJob?: GraphCacheUpdateResolver<
      { rescheduleJob: WithTypename<Job> },
      MutationRescheduleJobArgs
    >;
    rescheduleJobs?: GraphCacheUpdateResolver<
      { rescheduleJobs: Array<WithTypename<Job>> },
      MutationRescheduleJobsArgs
    >;
    resetTaskStatus?: GraphCacheUpdateResolver<
      { resetTaskStatus: Maybe<WithTypename<Task>> },
      MutationResetTaskStatusArgs
    >;
    reslySetup?: GraphCacheUpdateResolver<
      { reslySetup: Maybe<Scalars['String']> },
      MutationReslySetupArgs
    >;
    resumeJob?: GraphCacheUpdateResolver<
      { resumeJob: Maybe<WithTypename<Job>> },
      MutationResumeJobArgs
    >;
    resumeTask?: GraphCacheUpdateResolver<
      { resumeTask: Maybe<WithTypename<Task>> },
      MutationResumeTaskArgs
    >;
    revertJobStatus?: GraphCacheUpdateResolver<
      { revertJobStatus: Maybe<WithTypename<Job>> },
      MutationRevertJobStatusArgs
    >;
    saveNotificationPreferences?: GraphCacheUpdateResolver<
      { saveNotificationPreferences: Scalars['Int'] },
      MutationSaveNotificationPreferencesArgs
    >;
    sendVerificationEmail?: GraphCacheUpdateResolver<
      { sendVerificationEmail: Scalars['Boolean'] },
      Record<string, never>
    >;
    setProfilePic?: GraphCacheUpdateResolver<
      { setProfilePic: WithTypename<User> },
      MutationSetProfilePicArgs
    >;
    sortJob?: GraphCacheUpdateResolver<
      { sortJob: Scalars['Boolean'] },
      MutationSortJobArgs
    >;
    startJob?: GraphCacheUpdateResolver<
      { startJob: Maybe<WithTypename<Job>> },
      MutationStartJobArgs
    >;
    startTask?: GraphCacheUpdateResolver<
      { startTask: Maybe<WithTypename<Task>> },
      MutationStartTaskArgs
    >;
    updateAccountProfile?: GraphCacheUpdateResolver<
      { updateAccountProfile: Scalars['Boolean'] },
      MutationUpdateAccountProfileArgs
    >;
    updateAsset?: GraphCacheUpdateResolver<
      { updateAsset: WithTypename<Asset> },
      MutationUpdateAssetArgs
    >;
    updateAssetModel?: GraphCacheUpdateResolver<
      { updateAssetModel: WithTypename<AssetModel> },
      MutationUpdateAssetModelArgs
    >;
    updateAutomation?: GraphCacheUpdateResolver<
      { updateAutomation: Maybe<Scalars['ID']> },
      MutationUpdateAutomationArgs
    >;
    updateContact?: GraphCacheUpdateResolver<
      { updateContact: Maybe<WithTypename<Contact>> },
      MutationUpdateContactArgs
    >;
    updateDeployment?: GraphCacheUpdateResolver<
      { updateDeployment: Scalars['Int'] },
      MutationUpdateDeploymentArgs
    >;
    updateItem?: GraphCacheUpdateResolver<
      { updateItem: WithTypename<Item> },
      MutationUpdateItemArgs
    >;
    updateJob?: GraphCacheUpdateResolver<
      { updateJob: WithTypename<Job> },
      MutationUpdateJobArgs
    >;
    updateJobTemplate?: GraphCacheUpdateResolver<
      { updateJobTemplate: WithTypename<JobTemplate> },
      MutationUpdateJobTemplateArgs
    >;
    updateJobTemplates?: GraphCacheUpdateResolver<
      { updateJobTemplates: Maybe<Array<WithTypename<Job>>> },
      MutationUpdateJobTemplatesArgs
    >;
    updatePersonalInfo?: GraphCacheUpdateResolver<
      { updatePersonalInfo: Scalars['Int'] },
      MutationUpdatePersonalInfoArgs
    >;
    updateSite?: GraphCacheUpdateResolver<
      { updateSite: WithTypename<Site> },
      MutationUpdateSiteArgs
    >;
    updateSpace?: GraphCacheUpdateResolver<
      { updateSpace: WithTypename<Space> },
      MutationUpdateSpaceArgs
    >;
    updateSpaceSku?: GraphCacheUpdateResolver<
      { updateSpaceSku: WithTypename<SpaceSku> },
      MutationUpdateSpaceSkuArgs
    >;
    updateTag?: GraphCacheUpdateResolver<
      { updateTag: WithTypename<Tag> },
      MutationUpdateTagArgs
    >;
  };
  Subscription?: {};
  Activity?: {
    comment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    createdBy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    entry?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    isFlagged?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    isRead?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    job?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    tags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
    task?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Activity>>,
      Record<string, never>
    >;
  };
  ActivityConnection?: {
    edges?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ActivityConnection>>,
      Record<string, never>
    >;
    pageInfo?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ActivityConnection>>,
      Record<string, never>
    >;
    totalCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ActivityConnection>>,
      Record<string, never>
    >;
    totalUnreadCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ActivityConnection>>,
      Record<string, never>
    >;
  };
  ActivityEdge?: {
    cursor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ActivityEdge>>,
      Record<string, never>
    >;
    node?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ActivityEdge>>,
      Record<string, never>
    >;
  };
  Asset?: {
    attachments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    deployment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    deployments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    jobs?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    model?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Asset>>,
      Record<string, never>
    >;
  };
  AssetModel?: {
    activeAssets?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
    brand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
    displayName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetModel>>,
      Record<string, never>
    >;
  };
  AssetTaskConfig?: {
    allow?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetTaskConfig>>,
      Record<string, never>
    >;
    assetTypes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetTaskConfig>>,
      Record<string, never>
    >;
    selection?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetTaskConfig>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AssetTaskConfig>>,
      Record<string, never>
    >;
  };
  Attachment?: {
    ext?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attachment>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attachment>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attachment>>,
      Record<string, never>
    >;
    originalFilename?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attachment>>,
      Record<string, never>
    >;
    thumb?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attachment>>,
      Record<string, never>
    >;
    url?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attachment>>,
      Record<string, never>
    >;
  };
  Attribute?: {
    attachments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attribute>>,
      Record<string, never>
    >;
    category?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attribute>>,
      Record<string, never>
    >;
    condition?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attribute>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attribute>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attribute>>,
      Record<string, never>
    >;
    options?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attribute>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attribute>>,
      Record<string, never>
    >;
    value?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Attribute>>,
      Record<string, never>
    >;
  };
  AttributeSingle?: {
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AttributeSingle>>,
      Record<string, never>
    >;
    value?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AttributeSingle>>,
      Record<string, never>
    >;
  };
  AttributeTask?: {
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AttributeTask>>,
      Record<string, never>
    >;
    formValues?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AttributeTask>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AttributeTask>>,
      Record<string, never>
    >;
  };
  Automation?: {
    assignees?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    conditions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    day?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    do?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    jobName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    matchAttribute?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    on?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    owners?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    repeatUntilOffset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    sites?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    template?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    time?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
    whereType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Automation>>,
      Record<string, never>
    >;
  };
  AutomationCondition?: {
    nights?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AutomationCondition>>,
      Record<string, never>
    >;
    operator?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AutomationCondition>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<AutomationCondition>>,
      Record<string, never>
    >;
  };
  Booking?: {
    end?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Booking>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Booking>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Booking>>,
      Record<string, never>
    >;
    start?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Booking>>,
      Record<string, never>
    >;
  };
  CalendarSubscription?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CalendarSubscription>>,
      Record<string, never>
    >;
    provider?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CalendarSubscription>>,
      Record<string, never>
    >;
    syncedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CalendarSubscription>>,
      Record<string, never>
    >;
    url?: GraphCacheUpdateResolver<
      Maybe<WithTypename<CalendarSubscription>>,
      Record<string, never>
    >;
  };
  Card?: {
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    capacity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    dispatched?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    fill?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    packed?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    pick?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    picked?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    price?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    putAway?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    queuedPrice?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    queuedType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    received?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    reorderLevel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    reserved?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    sku?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    skuId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    soh?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    space?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    spaceId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    stockTransferId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
    target?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Card>>,
      Record<string, never>
    >;
  };
  Comment?: {
    attachments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Comment>>,
      Record<string, never>
    >;
    body?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Comment>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Comment>>,
      Record<string, never>
    >;
    createdBy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Comment>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Comment>>,
      Record<string, never>
    >;
    isFlagged?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Comment>>,
      Record<string, never>
    >;
    isRead?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Comment>>,
      Record<string, never>
    >;
    tags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Comment>>,
      Record<string, never>
    >;
  };
  Contact?: {
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    deployment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    email?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    isCurrentUser?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    isTeamMember?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    organisation?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    organisationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    phone?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    postalAddress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    relationship?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    streetAddress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
    updatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Contact>>,
      Record<string, never>
    >;
  };
  Deployment?: {
    asset?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    assetId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    defaultSourceId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    displayLocation?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      DeploymentDisplayLocationArgs
    >;
    endedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    owner?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    space?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    spaceId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    startedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    state?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Deployment>>,
      Record<string, never>
    >;
  };
  Elsewhere?: {
    deployment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Elsewhere>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Elsewhere>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Elsewhere>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Elsewhere>>,
      Record<string, never>
    >;
  };
  Fsp?: {
    deployment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Fsp>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Fsp>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Fsp>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Fsp>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Fsp>>,
      Record<string, never>
    >;
  };
  HomheroArea?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<HomheroArea>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<HomheroArea>>,
      Record<string, never>
    >;
  };
  Invitation?: {
    email?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Invitation>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Invitation>>,
      Record<string, never>
    >;
  };
  Item?: {
    activeSkus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    hasVariants?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    skuPrefix?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    skus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
    variantTypes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Item>>,
      Record<string, never>
    >;
  };
  ItemQty?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemQty>>,
      Record<string, never>
    >;
    qty?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemQty>>,
      Record<string, never>
    >;
  };
  ItemTask?: {
    cards?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
    lineItems?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
    spaceSkus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
    stockTransfer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
    updatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ItemTask>>,
      Record<string, never>
    >;
  };
  Job?: {
    _dateCreated?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    _dateScheduled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    assignee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    assignees?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    attachments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    booking?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    canAcceptOffer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    comments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      JobCommentsArgs
    >;
    completedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    customer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    deploymentString?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    emailAddress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    enforceOrder?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    guestyCleaningStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    history?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    include?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    isScheduled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    isSource?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    jobNumber?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    jobTags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    nextCheckIn?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    nodeId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    occupied?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    owner?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    owners?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    permissions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    prevCheckOut?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    scheduleEnd?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    scheduleStart?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    scheduledEndDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    scheduledEndStrict?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    scheduledEndTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    scheduledStartDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    scheduledStartStrict?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    scheduledStartTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    site?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    state?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    tags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    tasks?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    timeZone?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
    updatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Job>>,
      Record<string, never>
    >;
  };
  JobCalendarEvent?: {
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    cancelled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    date?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    end?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    hidden?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    icon?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    jobs?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    start?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    summary?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    timeZone?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    uid?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
    url?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobCalendarEvent>>,
      Record<string, never>
    >;
  };
  JobHistory?: {
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    createdBy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    inputDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    isFlagged?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    jobId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    reverted?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    task?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
    taskId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobHistory>>,
      Record<string, never>
    >;
  };
  JobTemplate?: {
    attachments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    guestyCleaningStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    includeAttributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    isStarter?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    tags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
    tasks?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobTemplate>>,
      Record<string, never>
    >;
  };
  JobsConnection?: {
    edges?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobsConnection>>,
      Record<string, never>
    >;
    pageInfo?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobsConnection>>,
      Record<string, never>
    >;
    totalCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobsConnection>>,
      Record<string, never>
    >;
  };
  JobsEdge?: {
    cursor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobsEdge>>,
      Record<string, never>
    >;
    node?: GraphCacheUpdateResolver<
      Maybe<WithTypename<JobsEdge>>,
      Record<string, never>
    >;
  };
  Onboarding?: {
    form?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Onboarding>>,
      Record<string, never>
    >;
    hasNextStep?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Onboarding>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Onboarding>>,
      Record<string, never>
    >;
    step?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Onboarding>>,
      Record<string, never>
    >;
    version?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Onboarding>>,
      Record<string, never>
    >;
    waitingFor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Onboarding>>,
      Record<string, never>
    >;
  };
  Organisation?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Organisation>>,
      Record<string, never>
    >;
    logo?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Organisation>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Organisation>>,
      Record<string, never>
    >;
  };
  OtpGenerateResponse?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OtpGenerateResponse>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OtpGenerateResponse>>,
      Record<string, never>
    >;
  };
  OtpVerifyResponse?: {
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<OtpVerifyResponse>>,
      Record<string, never>
    >;
  };
  PageInfo?: {
    endCursor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PageInfo>>,
      Record<string, never>
    >;
    hasNextPage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PageInfo>>,
      Record<string, never>
    >;
    hasPreviousPage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PageInfo>>,
      Record<string, never>
    >;
    startCursor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PageInfo>>,
      Record<string, never>
    >;
  };
  PurchaseTask?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PurchaseTask>>,
      Record<string, never>
    >;
    movements?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PurchaseTask>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PurchaseTask>>,
      Record<string, never>
    >;
  };
  PurchaseTaskItem?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PurchaseTaskItem>>,
      Record<string, never>
    >;
    qty?: GraphCacheUpdateResolver<
      Maybe<WithTypename<PurchaseTaskItem>>,
      Record<string, never>
    >;
  };
  Role?: {
    key?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Role>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Role>>,
      Record<string, never>
    >;
  };
  ScheduleEvent?: {
    assignee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    assignee_id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    days?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    end?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    included?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    scheduledEndTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    scheduledStartTime?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    site_id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    start?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    summary?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
    tags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ScheduleEvent>>,
      Record<string, never>
    >;
  };
  Session?: {
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Session>>,
      Record<string, never>
    >;
  };
  Site?: {
    activeLocations?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    address?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    airbnbCalendar?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    airbnbDefaultCheckIn?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    airbnbDefaultCheckOut?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    calendar?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      SiteCalendarArgs
    >;
    calendarBounds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    defaultSource?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    deployment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    homhero?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    integrationAttributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    jobs?: GraphCacheUpdateResolver<Maybe<WithTypename<Site>>, SiteJobsArgs>;
    licensor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    state?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
    updatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Site>>,
      Record<string, never>
    >;
  };
  Sku?: {
    _name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    code?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    itemId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    itemType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
    variant?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Sku>>,
      Record<string, never>
    >;
  };
  Space?: {
    activeSkus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    deployments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    skus?: GraphCacheUpdateResolver<Maybe<WithTypename<Space>>, SpaceSkusArgs>;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
    updatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Space>>,
      Record<string, never>
    >;
  };
  SpaceConnection?: {
    edges?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceConnection>>,
      Record<string, never>
    >;
    pageInfo?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceConnection>>,
      Record<string, never>
    >;
    totalCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceConnection>>,
      Record<string, never>
    >;
  };
  SpaceEdge?: {
    cursor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceEdge>>,
      Record<string, never>
    >;
    node?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceEdge>>,
      Record<string, never>
    >;
  };
  SpaceSku?: {
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    capacity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    price?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    queuedPrice?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    queuedType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    reorderLevel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    reserved?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    sku?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    skuId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    soh?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    space?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    spaceId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
    target?: GraphCacheUpdateResolver<
      Maybe<WithTypename<SpaceSku>>,
      Record<string, never>
    >;
  };
  StockAdjustment?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockAdjustment>>,
      Record<string, never>
    >;
  };
  StockTransfer?: {
    deployment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    destination?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    destinationString?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    fsp?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    items?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    processQueuedChanges?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    rule?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    selectStock?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    source?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    sourceString?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    space?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransfer>>,
      Record<string, never>
    >;
  };
  StockTransferItem?: {
    dispatched?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    packed?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    pick?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    picked?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    putAway?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    received?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    skuId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    spaceId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
    stockTransferId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StockTransferItem>>,
      Record<string, never>
    >;
  };
  Stocktake?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Stocktake>>,
      Record<string, never>
    >;
    include?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Stocktake>>,
      Record<string, never>
    >;
    itemStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Stocktake>>,
      Record<string, never>
    >;
    items?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Stocktake>>,
      Record<string, never>
    >;
    spaces?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Stocktake>>,
      Record<string, never>
    >;
  };
  StocktakeItem?: {
    adjustment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StocktakeItem>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StocktakeItem>>,
      Record<string, never>
    >;
    newOnHand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StocktakeItem>>,
      Record<string, never>
    >;
    startOnHand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<StocktakeItem>>,
      Record<string, never>
    >;
  };
  Tag?: {
    category?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tag>>,
      Record<string, never>
    >;
    colour?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tag>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tag>>,
      Record<string, never>
    >;
    entityType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tag>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tag>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Tag>>,
      Record<string, never>
    >;
  };
  TagConnection?: {
    edges?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TagConnection>>,
      Record<string, never>
    >;
    nodes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TagConnection>>,
      Record<string, never>
    >;
    pageInfo?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TagConnection>>,
      Record<string, never>
    >;
    totalCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TagConnection>>,
      Record<string, never>
    >;
  };
  TagEdge?: {
    cursor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TagEdge>>,
      Record<string, never>
    >;
    node?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TagEdge>>,
      Record<string, never>
    >;
  };
  Task?: {
    assets?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    attachments?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    attribute?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    config?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    job?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    purchase?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    required?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    stockTransfer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    stocktake?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    tags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    transfer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
    updatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Task>>,
      Record<string, never>
    >;
  };
  Thread?: {
    count?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Thread>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Thread>>,
      Record<string, never>
    >;
    lastAuthor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Thread>>,
      Record<string, never>
    >;
    lastMessage?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Thread>>,
      Record<string, never>
    >;
    lastUpdate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Thread>>,
      Record<string, never>
    >;
    title?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Thread>>,
      Record<string, never>
    >;
  };
  TransferTask?: {
    available?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TransferTask>>,
      Record<string, never>
    >;
    moved?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TransferTask>>,
      Record<string, never>
    >;
    stockOnHand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<TransferTask>>,
      Record<string, never>
    >;
  };
  Upload?: {
    baseUrl?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Upload>>,
      Record<string, never>
    >;
    ext?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Upload>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Upload>>,
      Record<string, never>
    >;
    originalFilename?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Upload>>,
      Record<string, never>
    >;
    presignedUrl?: GraphCacheUpdateResolver<
      Maybe<WithTypename<Upload>>,
      Record<string, never>
    >;
  };
  User?: {
    attributes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    email?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    image?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    images?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    isOwner?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    permissions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    roles?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<User>>,
      Record<string, never>
    >;
  };
  VariantType?: {
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<VariantType>>,
      Record<string, never>
    >;
    values?: GraphCacheUpdateResolver<
      Maybe<WithTypename<VariantType>>,
      Record<string, never>
    >;
  };
  VariantValue?: {
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<VariantValue>>,
      Record<string, never>
    >;
    value?: GraphCacheUpdateResolver<
      Maybe<WithTypename<VariantValue>>,
      Record<string, never>
    >;
  };
};

export type GraphCacheConfig = Parameters<typeof cacheExchange>[0] & {
  updates?: GraphCacheUpdaters;
  keys?: GraphCacheKeysConfig;
  optimistic?: GraphCacheOptimisticUpdaters;
  resolvers?: GraphCacheResolvers;
};
