import { SVGProps } from 'react';

const SvgAssets = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='25' height='25' fill='none' />
    <path
      d='M18.125 5.4375H6.875C6.55859 5.4375 6.3125 5.71875 6.3125 6V19.5C6.3125 19.8164 6.55859 20.0625 6.875 20.0625H18.125C18.4062 20.0625 18.6875 19.8164 18.6875 19.5V6C18.6875 5.71875 18.4062 5.4375 18.125 5.4375ZM6.875 3.75H18.125C19.3555 3.75 20.375 4.76953 20.375 6V19.5C20.375 20.7656 19.3555 21.75 18.125 21.75H6.875C5.60938 21.75 4.625 20.7656 4.625 19.5V6C4.625 4.76953 5.60938 3.75 6.875 3.75ZM9.125 7.40625C9.125 7.89844 8.73828 8.25 8.28125 8.25C7.78906 8.25 7.4375 7.89844 7.4375 7.40625C7.4375 6.94922 7.78906 6.5625 8.28125 6.5625C8.73828 6.5625 9.125 6.94922 9.125 7.40625ZM11.0938 8.25C10.6016 8.25 10.25 7.89844 10.25 7.40625C10.25 6.94922 10.6016 6.5625 11.0938 6.5625C11.5508 6.5625 11.9375 6.94922 11.9375 7.40625C11.9375 7.89844 11.5508 8.25 11.0938 8.25ZM12.5 17.25C14.3633 17.25 15.875 15.7383 15.875 13.875C15.875 12.0117 14.3633 10.5 12.5 10.5C10.918 10.5 9.58203 11.5898 9.23047 13.0312H11.0938C11.5508 13.0312 11.9375 13.418 11.9375 13.875C11.9375 14.3672 11.5508 14.7188 11.0938 14.7188H9.23047C9.58203 16.1953 10.918 17.25 12.5 17.25ZM12.5 18.9375C10.6719 18.9375 9.01953 17.9883 8.10547 16.4062C7.19141 14.8594 7.19141 12.9258 8.10547 11.3438C9.01953 9.79688 10.6719 8.8125 12.5 8.8125C14.293 8.8125 15.9453 9.79688 16.8594 11.3438C17.7734 12.9258 17.7734 14.8594 16.8594 16.4062C15.9453 17.9883 14.293 18.9375 12.5 18.9375Z'
      fill='currentColor'
    />
  </svg>
);

export default SvgAssets;
