const DB_NAME = 'gfactor';
const DB_STORE_NAME = 'upload';

export function openDb(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB_NAME, 2);

    request.onerror = function () {
      reject(new Error('Could not open database'));
    };

    request.onupgradeneeded = function () {
      this.result.createObjectStore(DB_STORE_NAME);
    };

    request.onsuccess = function () {
      resolve(this.result);
    };
  });
}

export function addFile(
  db: IDBDatabase,
  file: File,
  id: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const trx = db.transaction(DB_STORE_NAME, 'readwrite');
    const store = trx.objectStore(DB_STORE_NAME);
    const request = store.add({ blob: file }, id);

    request.onerror = function () {
      reject(this.error);
    };

    request.onsuccess = function () {
      resolve(id);
    };

    resolve(id);
  });
}

export function getFileAsObjectUrl(
  db: IDBDatabase,
  filename: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const trx = db.transaction(DB_STORE_NAME, 'readonly');
    const store = trx.objectStore(DB_STORE_NAME);
    const request = store.get(filename);

    request.onerror = function () {
      reject(this.error);
    };

    request.onsuccess = function () {
      const value = request.result;
      if (value && value.blob && value.blob instanceof File) {
        const file = value.blob as File;
        const objUrl = window.URL.createObjectURL(file);
        resolve(objUrl);
      } else {
        reject(new Error('Invalid file'));
      }
    };
  });
}
