import LinkifyIt from 'linkify-it';
import { ReactNode } from 'react';
import tlds from 'tlds';

type Props = {
  children: ReactNode;
};

const _linkify = LinkifyIt().tlds(tlds);

function split(str: string) {
  const matches = _linkify.match(str);

  if (matches) {
    const parts: (string | LinkifyIt.Match)[] = [];
    let last = 0;

    matches.forEach((match) => {
      if (last < match.index) {
        parts.push(str.substring(last, match.index));
      }

      parts.push(match);

      last = match.lastIndex;
    });

    if (last < str.length) {
      parts.push(str.substring(last));
    }

    return parts;
  }

  return [str];
}

function linkify(str: string) {
  const parts = split(str);

  return parts.map((part, key) => {
    if (typeof part === 'string') {
      return part;
    }

    return (
      <a
        className='cursor-pointer text-blue'
        key={key}
        href={part.url}
        target='_blank'
        rel='noreferrer'
      >
        {part.text}
      </a>
    );
  });
}

export function Linkify({ children }: Props) {
  return <>{typeof children === 'string' ? linkify(children) : null}</>;
}
