import { SVGProps } from 'react';

const SvgAttributes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='25' height='25' fill='none' />
    <path
      d='M20.2812 6.375C19.9688 6.0625 19.5 6.0625 19.2188 6.375L18.5625 7.03125L20 8.46875L20.6562 7.78125C20.9375 7.5 20.9375 7.03125 20.6562 6.71875L20.2812 6.375ZM13.7188 11.9062C13.625 12.0312 13.5625 12.1562 13.5312 12.2812L13.2188 13.8438L14.75 13.5312C14.9062 13.5 15.0312 13.4062 15.125 13.3125L18.9375 9.5L17.5 8.09375L13.7188 11.9062ZM18.1562 5.3125C19.0312 4.4375 20.4375 4.40625 21.3438 5.3125L21.7188 5.6875C22.5938 6.5625 22.5938 7.96875 21.7188 8.84375L16.1875 14.375C15.875 14.6875 15.5 14.9062 15.0625 15L12.375 15.5312C12.1562 15.5938 11.875 15.5312 11.7188 15.3438C11.5312 15.1562 11.4375 14.9062 11.5 14.6562L12.0625 11.9688C12.1562 11.5625 12.375 11.1562 12.6562 10.875L18.1562 5.3125ZM4.75 8.5H10.75C11.1562 8.5 11.5 8.84375 11.5 9.25C11.5 9.6875 11.1562 10 10.75 10H4.75C4.3125 10 4 10.3438 4 10.75V18.25C4 18.6875 4.3125 19 4.75 19H18.25C18.6562 19 19 18.6875 19 18.25V14.25C19 13.8438 19.3125 13.5 19.75 13.5C20.1562 13.5 20.5 13.8438 20.5 14.25V18.25C20.5 19.5 19.4688 20.5 18.25 20.5H4.75C3.5 20.5 2.5 19.5 2.5 18.25V10.75C2.5 9.53125 3.5 8.5 4.75 8.5ZM6.5 15.5C5.9375 15.5 5.5 15.0625 5.5 14.5C5.5 13.9688 5.9375 13.5 6.5 13.5C7.03125 13.5 7.5 13.9688 7.5 14.5C7.5 15.0625 7.03125 15.5 6.5 15.5ZM10.5 14.5C10.5 15.0625 10.0312 15.5 9.5 15.5C8.9375 15.5 8.5 15.0625 8.5 14.5C8.5 13.9688 8.9375 13.5 9.5 13.5C10.0312 13.5 10.5 13.9688 10.5 14.5Z'
      fill='currentColor'
    />
  </svg>
);

export default SvgAttributes;
