import {
  faExternalLink,
  faFile,
  faFilePdf,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FragmentType, getFragmentData } from '~/gql';
import { Attachment } from '~/gql/graphql';
import { AttachmentFields } from './upload/AttachmentFields';

type Props = {
  url: string;
  originalFilename: string;
};

export function FileLink({ url, originalFilename }: Props) {
  return (
    <a
      className='group mb-2 flex items-center hover:underline'
      href={url}
      target='_blank'
      rel='noreferrer'
    >
      <span className='mr-2 text-xl'>
        {originalFilename.endsWith('.pdf') ? (
          <FontAwesomeIcon icon={faFilePdf} />
        ) : (
          <FontAwesomeIcon icon={faFile} />
        )}
      </span>
      <span className='group-hover:text-blue'>{originalFilename}</span>
      <span className='ml-1 hidden text-blue group-hover:block'>
        <FontAwesomeIcon icon={faExternalLink} />
      </span>
    </a>
  );
}

/**
 * For convenience, create a list of FileLinks from attachments
 */
export function Files({ attachments }: { attachments: Attachment[] }) {
  return (
    <>
      {attachments
        .filter(({ kind }) => kind === 'file')
        .map(({ id, originalFilename, url }) => (
          <FileLink key={id} url={url} originalFilename={originalFilename} />
        ))}
    </>
  );
}

export function FilesF(props: {
  attachments: FragmentType<typeof AttachmentFields>[];
}) {
  const attachments = getFragmentData(AttachmentFields, props.attachments);

  return <Files attachments={[...attachments]} />;
}
