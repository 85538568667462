import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lightgallery.css';

// import plugins if you need
import classNames from 'classnames';
import thumbnail from 'lightgallery/plugins/thumbnail';
import zoom from 'lightgallery/plugins/zoom';
import { useMemo } from 'react';

type GalleryProps = {
  images?: string | string[] | null;
  size?: 'fit';
};

export function Gallery({ images, size }: GalleryProps) {
  const urls = useMemo(() => {
    return typeof images === 'string' ? [images] : images;
  }, [images]);

  if (!urls?.length) {
    return null;
  }

  return (
    <LightGallery
      elementClassNames={size === 'fit' ? 'h-full w-full' : ''}
      speed={500}
      plugins={[thumbnail, zoom]}
      licenseKey={import.meta.env.VITE_LIGHTGALLERY_LICENSE_KEY}
    >
      {urls.map((url, i) => {
        return (
          <a
            key={i}
            href={url}
            className={classNames(
              size === 'fit' && 'h-full w-full',
              'mb-5 block overflow-hidden rounded'
            )}
          >
            {/* TODO replace img src with url to thumbnail */}
            <img
              alt=''
              src={url}
              className={size === 'fit' ? 'h-full w-full object-cover' : ''}
              onError={(event) => {
                console.log('failed to display img', url);
                event.currentTarget.closest('a')?.classList.add('hidden');
              }}
            />
          </a>
        );
      })}
    </LightGallery>
  );
}
