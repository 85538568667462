import { formatInTimeZone } from 'date-fns-tz';
import { enAU } from 'date-fns/locale';

type DateField =
  | 'scheduledStart'
  | 'scheduledEnd'
  | 'completedAt'
  | 'createdAt';

type Job = {
  timeZone: string;
  scheduleStart?: any;
  scheduleEnd?: any;
  completedAt?: any;
  createdAt?: any;
  scheduledStartTime?: any;
  scheduledEndTime?: any;
};

// Full date
const DATE_FIELD_MAP = {
  scheduledStart: 'scheduleStart',
  scheduledEnd: 'scheduleEnd',
  completedAt: 'completedAt',
  createdAt: 'createdAt',
} as const;

// local time
const TIME_FIELD_MAP = {
  scheduledStart: 'scheduledStartTime',
  scheduledEnd: 'scheduledEndTime',
  completedAt: null,
  createdAt: null,
} as const;

export function formatLocalDate(job: Job, field: DateField, pattern?: string) {
  const date = DATE_FIELD_MAP[field];

  if (!job[date]) {
    return null;
  }

  const time = TIME_FIELD_MAP[field];
  const d = new Date(job[date]);
  const tz = job.timeZone;
  const options = { locale: enAU };

  if (pattern) {
    return formatInTimeZone(d, tz, pattern, options);
  }

  if (time === null || job[time]) {
    return formatInTimeZone(d, tz, 'PP p', options);
  }

  return formatInTimeZone(d, tz, 'PP', options);
}

export function formatLocalTime(job: Job, field: DateField) {
  const date = DATE_FIELD_MAP[field];

  if (!job[date]) {
    return null;
  }

  const time = TIME_FIELD_MAP[field];
  const d = new Date(job[date]);
  const tz = job.timeZone;
  const options = { locale: enAU };

  if (time !== null && !job[time]) {
    return null;
  }

  return formatInTimeZone(d, tz, 'p', options);
}
