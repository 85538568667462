import { Dialog } from '~/components/ui/Dialog';
import { useRef, useState } from 'react';

type ConfirmActionDialogProps = {
  title: string;
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  text: React.ReactNode;
  placeholder?: string;
  confirmButton?: string;
  onClick: (arg2: string | undefined) => void;
  required?: boolean;
};

export function ConfirmActionDialog({
  title,
  isOpen,
  setIsOpen,
  onClick,
  text,
  placeholder = 'Add optional message...',
  confirmButton = 'confirm',
  required,
}: ConfirmActionDialogProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [content, setContent] = useState<string>('');

  return (
    <Dialog title={title} show={isOpen} onClose={() => setIsOpen(false)}>
      <div className='flex flex-col flex-wrap gap-3 px-5'>
        <p className='px-2 pt-3'>{text}</p>
        <textarea
          ref={inputRef}
          className='input focus-visible:border-brand border-grey-20 mt-1 w-full resize-none rounded-md border px-3 py-2.5 focus-visible:outline-none'
          placeholder={placeholder}
          onChange={(e) => setContent(e.target.value)}
          autoFocus
        ></textarea>
        <div className='flex gap-3 self-end pb-5 pt-1'>
          <button
            className='hover:text-brand text-brand-dark py-2 px-4 text-sm font-medium uppercase transition-all duration-500'
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            type='button'
            className='bg-brand border-brand disabled:hover:bg-brand hover:bg-brand-dark rounded-md border py-2 px-5 text-sm font-medium uppercase text-white transition-all duration-200 hover:transition-all hover:duration-500 disabled:opacity-60'
            onClick={() => {
              onClick(inputRef.current?.value);
            }}
            disabled={required && content === '' ? true : false}
          >
            {confirmButton}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
